<template>
  <div class="partners__item effect-unit slide" :data-delay="index + 2">
    <div class="partners__pic">
      <Picture :image="item.image" loading="lazy" :width="347" />
      <div v-if="item.is_over" class="partners__label">Акция уже прошла</div>
      <div v-else class="partners__label partners__label_color_green">
        Действующая акция
      </div>
    </div>
    <div class="partners__body">
      <div class="partners__group">
        <div class="partners__title" v-html="item.name"></div>
        <div class="partners__text" v-html="item.description"></div>
      </div>
      <div v-if="formatDate(item.active_from)" class="partners__date">
        <span
          >{{ formatDate(item.active_from)
          }}{{
            item.active_till ? `-${formatDate(item.active_till)}` : ""
          }}</span
        >
      </div>
      <div
        v-if="item.popup_header || item.popup_description"
        class="partners__button"
      >
        <Button
          class="button_color_orange"
          :class="`button_more_partner_${index + 1}`"
          msg="Подробнее"
          @click="setModalShow({ name: 'partners', data: item })"
        />
      </div>
      <div v-else-if="item.url" class="partners__button">
        <Button
          tag="a"
          class="button_color_orange"
          :class="`button_more_partner_${index + 1}`"
          :link="item.url"
          msg="Сайт проекта"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import Button from "@/components/Button.vue";
import Picture from "@/components/Picture.vue";

export default {
  props: {
    index: Number,
    item: Object
  },
  components: {
    Button,
    Picture
  },
  name: "PartnersItem",
  methods: {
    ...mapMutations(["setModalShow"]),
    formatDate(date = []) {
      const arr = date.split("-");

      if (arr.length !== 3) return "";

      return [arr[2]].concat(arr[1], arr[0]).join(".");
    }
  }
};
</script>

<style scoped lang="scss">
.partners {
  position: relative;
  padding-top: 20px;
  @include md {
    padding-top: 0px;
  }
  &__item {
    background: #ffffff;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
    border-radius: 23px;
    text-align: center;
    color: #333;
    margin: 0 15px;
    max-width: calc(83vw - 20px);
  }
  &__body {
    padding: 15px;
    height: 265px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include md {
      padding: 20px 25px 36px;
      height: 355px;
    }
  }
  &__pic {
    position: relative;
    // height: 190px;
    background: #f6f5f4;
    border-radius: 23px 23px 0px 0px;
    @include md {
      // height: 270px;
      height: auto;
    }
  }
  &__label {
    position: absolute;
    bottom: 0;
    left: -9px;
    font-weight: 900;
    font-size: 14px;
    background-color: #fff;
    padding: 0 25px;
    height: 33px;
    display: flex;
    align-items: center;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.16);
    border-radius: 6px 6px 6px 0px;
    &_color_green {
      color: #00923a;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 9px;
      height: 46px;
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='46' viewBox='0 0 9 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h9v45.5L0 33V0Z' fill='url(%23a)'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='4' y1='23' x2='4' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23939090'/%3E%3Cstop offset='1' stop-color='%23E0DFDF'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
      opacity: 0.4;
      content: "";
      z-index: -1;
      border-radius: 6px 0 0 0;
    }
  }
  &__group {
    position: relative;
    height: 190px;
    overflow: hidden;
    @include md {
      // height: auto;
    }
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 26px;
      pointer-events: none;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.8) 40%,
        rgba(255, 255, 255, 0) 100%
      );
      content: "";
    }
  }
  &__title {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }
  &__text {
    font-size: 14px;
    line-height: 148%;
    margin-top: 12px;
  }
  &__date {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 20px;
    span {
      background-image: url("data:image/svg+xml,%3Csvg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 3.0001h-12c-2.2091 0-4 1.7909-4 4v10c0 2.2092 1.7909 4 4 4h12c2.2091 0 4-1.7908 4-4v-10c0-2.2091-1.7909-4-4-4ZM7.5 1v4M15.5 1v4M1.5 9.0001h20' stroke='%2300923A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      font-weight: 900;
      font-size: 14px;
      padding-left: 31px;
      padding-right: 31px;
      line-height: 22px;
    }
  }
  &__button {
    text-align: center;
    position: relative;
  }
  &_type_archive {
    margin: 0;
    max-width: 100%;
  }
}
</style>

<style lang="scss">
.partners {
  &__pic {
    img {
      display: block;
      border-radius: 23px 23px 0px 0px;
      width: 100%;
      height: auto;
    }
  }
}
</style>
