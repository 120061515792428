<template>
  <transition name="modal">
    <Modal v-if="modalShow === 'video'">
      <template v-slot:body>
        <div class="modal-video">
          <iframe
            v-if="modalVideo"
            width="480"
            height="300"
            :src="
              `https://www.youtube.com/embed/${modalVideo}?autoplay=1&loop=1&modestbranding=1&playsinline=1`
            "
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <button class="modal-video-prev" @click="onPrev"></button>
        <button class="modal-video-next" @click="onNext"></button>
      </template>
    </Modal>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal
  },
  computed: {
    ...mapState(["modalShow", "modalVideo", "video", "videoActivePosition"])
  },
  data() {
    return {
      videos: []
    };
  },
  methods: {
    ...mapMutations(["setModalVideo", "setVideoActivePosition"]),
    changeVideo(direction) {
      if (this.videos.length === 0) {
        this.video.forEach(column => {
          column.forEach(item => {
            this.videos.push(item);
          });
        });
      }

      let newVideo;

      this.videos.forEach((video, index) => {
        if (video.pos === this.videoActivePosition) {
          let newPosition;

          if (direction === "next") {
            if (index === this.videos.length - 1) {
              newPosition = 0;
            } else {
              newPosition = index + 1;
            }
          } else {
            if (index === 0) {
              newPosition = this.videos.length - 1;
            } else {
              newPosition = index - 1;
            }
          }

          newVideo = this.videos[newPosition];
        }
      });

      this.setModalVideo(newVideo.youtube_id);
      this.setVideoActivePosition(newVideo.pos);
    },
    onPrev() {
      this.changeVideo("prev");
    },
    onNext() {
      this.changeVideo("next");
    }
  }
};
</script>
