<template>
  <div
    class="landing-section landing-section_color_milk landing-section_full_height effect-anchor"
  >
    <template v-if="screenSize === 'xs'" />
    <Pattern
      v-else-if="screenSize === 'md'"
      fill="#EBE2CF"
      offsetY="1"
      rows="10"
      columns="3"
    />
    <Pattern
      v-else
      v-show="safariHook"
      fill="#EBE2CF"
      offsetY="1"
      rows="6"
      columns="3"
    />
    <div class="landing-section__head">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="h2 landing-section__title effect-unit" data-delay="1">
              Видео
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="landing-section__body">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="videos">
              <button
                type="button"
                class="swiper-nav swiper-nav-prev effect-unit scale"
                aria-label="Предыдущий"
                data-delay="5"
              ></button>
              <button
                type="button"
                class="swiper-nav swiper-nav-next effect-unit scale"
                aria-label="Следующий"
                data-delay="5"
              ></button>
              <swiper
                v-if="videoStatus === 'success' && show"
                :slides-per-view="2"
                :pagination="{ clickable: true }"
                :breakpoints="breakpoints"
                :navigation="{
                  nextEl: '.videos .swiper-nav-next',
                  prevEl: '.videos .swiper-nav-prev'
                }"
                :loop="
                  screenSize === 'xs' || screenSize === 'sm' ? false : true
                "
                @swiper="onSwiper"
              >
                <swiper-slide
                  v-for="(videos, index) in video"
                  :key="index"
                  :virtualIndex="index"
                >
                  <div
                    v-for="(video, i) in videos"
                    :key="i"
                    class="videos__item"
                  >
                    <div class="videos__pic effect-unit scale" data-delay="2">
                      <img
                        :src="
                          `https://img.youtube.com/vi/${video.youtube_id}/hqdefault.jpg`
                        "
                        :alt="video.name"
                        loading="lazy"
                      />
                      <button
                        type="button"
                        @click="showModal(video.youtube_id, video.pos)"
                        class="videos__play"
                        :class="
                          `video_${index + 1} video_${index + 1}_${i +
                            1} video_common_index_${(index + 1) * 2 +
                            (i % 2 ? 1 : 0) -
                            1}`
                        "
                        aria-label="Воспроизвести"
                        :id="`${video.pos === 8 ? 'video_for_community' : ''}`"
                      ></button>
                    </div>
                    <div
                      class="videos__name effect-unit up"
                      data-delay="4"
                      v-html="video.name"
                    ></div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { mediaUrl } from "@/utils";

import SwiperCore, { Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import Pattern from "@/components/Pattern.vue";

SwiperCore.use([Virtual, Navigation, Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    Pattern
  },
  name: "Videos",
  data() {
    return {
      mediaUrl,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
          loop: false
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
          loop: true
        }
      },
      show: true,
      safariHook: false
    };
  },
  watch: {
    screenSize() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  computed: {
    ...mapState(["video", "videoStatus", "screenSize"])
  },
  methods: {
    ...mapMutations([
      "setModalShow",
      "setModalVideo",
      "setVideoActivePosition"
    ]),
    onSwiper() {
      // пересчитываем высоту
      window.dispatchEvent(new Event("resize"));

      /**
       * В сафари, скорее всего из-за изменение высоты блока при инициализации слайдера,
       * паттерн не перерисовывается и уходит за границу окна, появляется горизонтальный скролл
       */
      this.safariHook = true;
    },
    showModal(id, pos) {
      this.setModalVideo(id);
      this.setModalShow("video");
      this.setVideoActivePosition(pos);
    }
  }
};
</script>

<style scoped lang="scss">
.videos {
  position: relative;
  margin-top: 10px;
  @include md {
    margin-top: -30px;
  }
  .swiper {
    &-nav {
      margin-top: -85px;
    }
  }
  &__item {
    margin: 15px;
    width: calc(100vw - 30px);
    &:nth-child(2) {
      margin-top: 45px;
    }
    @include md {
      width: 340px;
    }
    @include lg {
      width: 445px;
    }
  }
  &__pic {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
    border-radius: 23px;
    height: 0;
    padding-bottom: 56.25%;
    background-color: #fff;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
    border-radius: 23px;
    position: relative;
    overflow: hidden;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000000;
      opacity: 0.3;
      content: "";
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
  &__play {
    @include reset-button;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &:hover {
      &::before {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 64px;
      height: 64px;
      background: #ffffff;
      opacity: 0.43;
      border-radius: 50%;
      content: "";
      transform: translate(-50%, -50%);
      transition: 0.25s;
    }
    &::after {
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      border-style: solid;
      border-width: 14px 0 14px 21px;
      border-color: transparent transparent transparent #fff;
      content: "";
      transform: translate(-50%, -50%);
      margin-left: 3px;
    }
  }
  &__name {
    margin-top: 21px;
    font-weight: 900;
    font-size: 18px;
    line-height: 149%;
    height: 52px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.videos {
  .swiper-wrapper {
    align-items: stretch;
    // padding: 0 7.5px;
  }
  // .swiper-container {
  // }
  .swiper-slide {
    width: auto;
    &:last-of-type {
      padding-right: 15px;
    }
  }
}
</style>
