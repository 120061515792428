<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-6 col-md-5 offset-md-1 col-lg-4 offset-lg-1">
        <button
          v-if="prev"
          @click="setModalShow(prev.id)"
          type="button"
          :class="prev.class"
          aria-label="Предыдущий"
          class="modal-nav modal-nav_type_prev"
        >
          <i></i>
          <span v-html="prev.text"></span>
        </button>
      </div>
      <div class="col-6 col-md-5 col-lg-4 offset-lg-2 content-right">
        <button
          v-if="next"
          @click="setModalShow(next.id)"
          type="button"
          :class="next.class"
          aria-label="Следующий"
          class="modal-nav modal-nav_type_next"
        >
          <span v-html="next.text"></span>
          <i></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Modal",
  props: {
    prev: Object,
    next: Object
  },
  methods: {
    ...mapMutations(["setModalShow"])
  }
};
</script>

<style scoped lang="scss">
.modal {
  &-nav {
    @include reset-button;
    display: flex;
    align-items: center;
    margin: 25px 0;
    @include md {
      margin: 50px 0;
    }
    i {
      border-radius: 55px;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='24' viewBox='0 0 14 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 12L2.8 0 0 3l8.4 9L0 21l2.8 3L14 12z' fill='%2300923A'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 55% 50%;
      background-color: #fff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
      transition: 0.15s;
      flex-shrink: 0;
      width: 35px;
      height: 35px;
      background-size: 8px;
      @include md {
        width: 55px;
        height: 55px;
        background-size: 14px;
      }
    }
    span {
      text-align: left;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.05em;
      padding: 0 10px;
      // display: none;
      font-size: 12px;
      @include md {
        padding: 0 15px;
        display: block;
        font-size: 16px;
      }
    }
    &_type {
      &_prev {
        i {
          transform: rotate(180deg);
          box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.25);
        }
        &:hover {
          i {
            box-shadow: 0px -4px 18px rgba(0, 0, 0, 0.25);
          }
        }
        &:active {
          i {
            background-color: darken(#fff, 2%);
            box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.25);
          }
        }
      }
      &_next {
        span {
          text-align: right;
        }
        &:hover {
          i {
            box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
          }
        }
        &:active {
          i {
            background-color: darken(#fff, 2%);
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}
</style>
