<template>
  <div
    class="landing-section landing-section_color_light-green landing-section_theme_white effect-anchor"
  >
    <Pattern
      v-if="screenSize !== 'xs'"
      direction="horizontal"
      position="bottom"
      rows="2"
      columns="10"
    />
    <div class="landing-section__head">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="h2 landing-section__title effect-unit" data-delay="1">
              Награды
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="landing-section__body">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="awards effect-unit" data-delay="2">
              <button
                type="button"
                class="swiper-nav swiper-nav_theme_white swiper-nav-prev effect-unit scale"
                data-delay="5"
                aria-label="Предыдущий"
              ></button>
              <button
                type="button"
                class="swiper-nav swiper-nav_theme_white swiper-nav-next effect-unit scale"
                data-delay="5"
                aria-label="Следующий"
              ></button>
              <swiper
                v-if="awardStatus === 'success' && show"
                class="swiper-container_theme_white"
                :navigation="{
                  nextEl: '.awards .swiper-nav-next',
                  prevEl: '.awards .swiper-nav-prev'
                }"
                :pagination="{ clickable: true }"
                :breakpoints="breakpoints"
                :loop="true"
                @swiper="onSwiper"
              >
                <swiper-slide
                  v-for="(item, index) in award"
                  :key="index"
                  :virtualIndex="index"
                >
                  <div
                    class="awards__item effect-unit slide"
                    :data-delay="index + 2"
                  >
                    <div class="awards__pic">
                      <Picture
                        :image="item.image"
                        :width="158"
                        loading="lazy"
                        :alt="item.name"
                      />
                    </div>
                    <div class="awards__title" v-html="item.name"></div>
                    <div class="awards__text" v-html="item.description"></div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mediaUrl } from "@/utils";

import SwiperCore, { Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import Picture from "@/components/Picture.vue";
import Pattern from "@/components/Pattern.vue";

SwiperCore.use([Virtual, Navigation, Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    Picture,
    Pattern
  },
  name: "Awards",
  data() {
    return {
      mediaUrl,
      breakpoints: {
        0: {
          slidesPerView: "auto",
          spaceBetween: 0,
          loop: false
        },
        768: {
          slidesPerGroup: 2,
          slidesPerView: 2,
          spaceBetween: 0,
          loop: true
        }
      },
      show: true
    };
  },
  watch: {
    screenSize() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  computed: {
    ...mapState(["award", "awardStatus", "screenSize"])
  },
  methods: {
    onSwiper() {
      // пересчитываем высоту
      window.dispatchEvent(new Event("resize"));
    }
  }
};
</script>

<style scoped lang="scss">
.awards {
  position: relative;
  color: #333;
  padding-top: 20px;
  @include md {
    padding-top: 0px;
  }
  .swiper {
    &-nav {
      margin-top: 6px;
    }
  }
  &__item {
    text-align: center;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 25px;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    // max-width: 240px;
    width: calc(83vw + 10px);
    @include md {
      width: auto;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 48px;
    }
    &::before {
      position: absolute;
      background: #ffffff;
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
      border-radius: 23px;
      content: "";
      width: calc(100% - 30px);
      // width: calc(100% - 15px);
      // @include md {
      //   width: calc(100% - 30px);
      // }
      height: calc(100% - 18vw);
      top: 18vw;
      left: 15px;
      z-index: -1;
      @include md {
        top: 124px;
        height: calc(100% - 124px);
      }
    }
  }
  &__pic {
    background: #ffffff;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
    width: 36vw;
    height: 36vw;
    border-radius: 248px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include md {
      width: 248px;
      height: 248px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 18px;
    line-height: 146%;
    margin-top: 18px;
    white-space: pre-line;
    @include md {
      margin-top: 28px;
      font-size: 24px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 150%;
    margin-top: 12px;
    white-space: pre-line;
    @include md {
      font-size: 16px;
    }
  }
}
</style>

<style lang="scss">
.awards {
  &__pic {
    picture {
      width: 100%;
      img {
        width: calc(100% - 11vw);
        height: calc(100% - 11vw);
        object-fit: contain;
        @include md {
          width: calc(100% - 90px);
          height: calc(100% - 90px);
        }
      }
    }
  }
  .swiper-wrapper {
    align-items: stretch;
    // padding: 0 7.5px;
  }
  .swiper-slide {
    height: auto;
    width: auto;
    // &:last-of-type {
    // padding-right: 15px;
    // }
  }
}
</style>
