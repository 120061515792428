<template>
  <div class="modal-wrapper">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <button
            @click="close"
            type="button"
            class="modal-close modal-close_type_sticky"
            aria-label="Закрыть"
          ></button>
          <div class="modal-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 col-md-8 offset-md-2">
                  <slot name="head"></slot>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="foot"></slot>
          </div>
        </div>
      </div>
      <div @click="close" class="modal-backdrop"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Modal",
  props: {
    msg: String
  },
  data() {
    return {};
  },
  created() {
    document.addEventListener("keyup", this.onClose);
  },
  unmounted() {
    document.removeEventListener("keyup", this.onClose);
  },
  methods: {
    ...mapMutations(["setModalShow", "setModalVideo"]),
    close() {
      this.setModalShow(false);
      this.setModalVideo(null);
    },
    onClose(event) {
      if (event.keyCode === 27) {
        this.close();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  // pointer-events: none;
  &-close {
    @include reset-button;
    position: absolute;
    width: 45px;
    height: 50px;
    z-index: 10;
    opacity: 0.8;
    transition: 0.15s;
    top: -57px;
    right: -10px;
    @media (min-width: 992px) {
      top: -10px;
      right: -57px;
      &_type_sticky {
        position: sticky;
        margin-left: 100%;
        width: 60px;
        height: 70px;
        margin-bottom: -70px;
      }
      &::before {
        width: 36px;
        height: 6px;
      }
      &::after {
        height: 36px;
        width: 6px;
      }
    }
    @include lg {
      &_type_sticky {
        width: 80px;
      }
    }
    &:hover {
      transform: scale(1.1);
      opacity: 1;
    }
    &::before {
      position: absolute;
      width: 30px;
      height: 4px;
      background-color: #fff;
      content: "";
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      position: absolute;
      height: 30px;
      width: 4px;
      background-color: #fff;
      content: "";
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 60px 15px;
  // pointer-events: none;
  transition: transform 0.3s ease-out;
  z-index: 2000;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 30px);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 120px);
}

@include md {
  .modal-dialog {
    margin: 60px;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 120px);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 120px);
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 23px;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #333;
  opacity: 0.4;
  transition: opacity 0.15s linear;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  // justify-content: flex-end;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 530px) {
  .modal-dialog {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 120px);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@include md {
  .modal-lg,
  .modal-xl {
    max-width: calc(100% - 30px);
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    margin: 60px;
  }
}

// @media (min-width: 992px) {
//   .modal-lg,
//   .modal-xl {
//     max-width: 800px;
//   }
// }

@media (min-width: 1230px) {
  .modal-dialog {
    margin-left: auto;
    margin-right: auto;
  }
  .modal-xl {
    max-width: 1110px;
  }
}

.modal-enter-from .modal-backdrop,
.modal-leave-to .modal-backdrop {
  opacity: 0;
}

.modal-enter-from .modal-dialog,
.modal-leave-to .modal-dialog {
  transform: translate(0, -50px);
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease;
}
</style>
