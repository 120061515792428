<template>
  <div class="home effect-anchor">
    <div class="home__inner">
      <div class="home__content">
        <div class="home__caption effect-unit" data-delay="2">
          Познакомьтесь <br />с инициативами «Пятёрочки»:
        </div>
        <h2 class="home__title effect-unit" data-delay="3">
          Ответственное<br />потребление,<br />социальная поддержка<br />и
          забота о природе
        </h2>
        <div class="home__pic-mobile effect-unit scale" data-delay="1">
          <picture v-if="screenSize === 'xs'">
            <source
              type="image/webp"
              :srcset="
                `${require('@/assets/img/pic/home/3.webp')} 1x,
                ${require('@/assets/img/pic/home/3@2x.webp')} 2x`
              "
            />
            <img
              :src="require('@/assets/img/pic/home/3.png')"
              :srcset="
                `${require('@/assets/img/pic/home/3.png')} 1x,
              ${require('@/assets/img/pic/home/3@2x.png')} 2x`
              "
              alt=""
            />
          </picture>
        </div>
        <div class="home__buttons">
          <button
            type="button"
            class="home__button effect-unit"
            data-delay="4"
            @click="onNext"
          >
            <span class="home__button-text">узнать больше</span>
            <span class="home__button-icon"></span>
          </button>
        </div>
      </div>
      <div class="home__pic effect-unit scale" data-delay="1">
        <picture
          v-if="
            screenSize === 'md' || screenSize === 'lg' || screenSize === 'xl'
          "
        >
          <source
            type="image/webp"
            :srcset="
              `${require('@/assets/img/pic/home/1.webp')} 1x,
                ${require('@/assets/img/pic/home/1@2x.webp')} 2x`
            "
          />
          <img
            loading="lazy"
            :src="require('@/assets/img/pic/home/1.png')"
            :srcset="
              `${require('@/assets/img/pic/home/1.png')} 1x,
              ${require('@/assets/img/pic/home/1@2x.png')} 2x`
            "
            width="736"
            height="896"
            alt=""
          />
        </picture>
      </div>
    </div>
    <template v-if="screenSize === 'xs'" />
    <Pattern
      v-else-if="screenSize === 'md'"
      class="pattern_type_multiply"
      fill="#98C21F"
      rows="8"
      columns="3"
    />
    <Pattern
      v-else
      class="pattern_type_multiply"
      fill="#98C21F"
      rows="6"
      columns="3"
    />
  </div>
</template>

<script>
import Pattern from "@/components/Pattern.vue";

export default {
  name: "Home",
  components: {
    Pattern
  },
  methods: {
    onNext() {
      const el = document.getElementById("nav_1");

      if (el) {
        el.click();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.home {
  // height: 100vh;
  // min-height: 100vh;
  background: radial-gradient(circle, #a4c92b 0%, #a4c92b 25%, #85b71c 100%);
  position: relative;
  overflow: hidden;
  @include lg {
    min-height: 100vh;
  }
  &::before {
    position: absolute;
    background-repeat: no-repeat;
    background-image: url(../assets/img/pic/home/2.png);
    width: 800px;
    height: 601px;

    @include md {
      content: "";
      width: 600px;
      background-size: 1200px auto;
    }
    @include lg {
      width: 800px;
      background-size: inherit;
    }
  }
  &__inner {
    @include md {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 40px;
      padding-bottom: 40px;
      min-height: calc(100vh - 80px);
    }
    @include lg {
      // padding-top: 75px;
      // padding-bottom: 75px;
      // min-height: calc(100vh - 150px);
    }
  }
  &__content {
    color: #fff;
    padding-top: 80px;
    position: relative;
    z-index: 2;
    padding-left: 15px;
    padding-right: 15px;
    @include md {
      padding-top: 75px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__caption {
    line-height: 1.2;
    font-size: 5vw;
    position: relative;
    z-index: 1;
    @include md {
      font-size: 16px;
    }
    @include lg {
      font-size: 20px;
    }
    @include xl {
      font-size: 24px;
    }
  }
  &__title {
    font-weight: 900;
    line-height: 1.2;
    margin: 0;
    font-size: 7.2vw;
    margin-top: 8px;
    position: relative;
    z-index: 1;
    @include md {
      font-size: 36px;
      margin-top: 8px;
    }
    @include lg {
      font-size: 48px;
      margin-top: 12px;
    }
    @include xl {
      margin-top: 17px;
      font-size: 60px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    padding-bottom: 35px;
    margin-top: -20vw;
    position: relative;
    z-index: 1;
    @include md {
      display: block;
      margin-top: 40px;
      padding-bottom: 0px;
    }
    @include lg {
      margin-top: 40px;
    }
    @include xl {
      margin-top: 80px;
    }
  }
  &__button {
    @include reset-button;
    display: flex;
    align-items: center;
    @include gap(16px);
    &-text {
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
    }
    &-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='49' viewBox='0 0 48 49' fill='none'%3E%3Ccircle cx='24' cy='24.5' r='23.5' stroke='%23fff'/%3E%3Cpath d='M31.602 26.5586l-7.3 6.6917-7.3-6.6917M24.3008 33.25V15' stroke='%23fff' stroke-linecap='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      width: 48px;
      height: 49px;
    }
  }
  &__pic {
    margin-left: -90px;

    position: relative;
    z-index: 1;
    &-mobile {
      position: relative;
      &::before {
        position: absolute;
        top: -42vw;
        left: -15px;
        width: 100vw;
        height: 127vw;
        background-repeat: no-repeat;
        background-image: url(../assets/img/pic/home/4.png);
        background-position: 100% 0;
        background-size: contain;
        content: "";
      }
      width: 100%;
      height: 0;
      padding-bottom: calc(99.14772727% * 1.21);
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @include md {
        display: none;
      }
    }
    display: none;
    @include md {
      margin-right: -100px;
      margin-bottom: -130px;
      display: block;
    }
    @include lg {
      margin-right: -140px;
      margin-bottom: -90px;
    }
    @include xl {
      margin-right: -140px;
    }
    &::before {
      position: absolute;
      top: -75px;
      left: -90px;
      background-repeat: no-repeat;
      background-image: url(../assets/img/pic/home/2.png);
      background-position: 100% 0;
      width: 800px;
      height: 601px;
      @include md {
        content: "";
        width: 600px;
        background-size: 1200px auto;
      }
      @include lg {
        content: "";
        width: 800px;
        background-size: inherit;
      }
    }
    img {
      height: auto;
      @include md {
        width: 460px;
      }
      @include lg {
        width: 620px;
      }
      @include xl {
        width: 736px;
      }
    }
  }
}
</style>
