<template>
  <transition name="modal">
    <Modal v-if="modalShow === 'health_1'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Проект &laquo;Свежесть&raquo;
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col col-md-7 offset-md-1">
                <h3 class="h3 h3_color_green mt-0" style="margin-right: -5px;">
                  Свежесть продуктов &mdash;
                </h3>
                <h4 class="h4 mb-0">
                  определяющий фактор для клиента при выборе магазина.
                  Мы&nbsp;хотим, чтобы покупатели были довольны, поэтому
                  контролируем движение товаров от&nbsp;поставщика
                  до&nbsp;полки.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_light-green">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-10 offset-md-1">
                <div class="modal-card__grid">
                  <div class="modal-card">
                    <div class="modal-card__icon modal-card__icon_1"></div>
                    <div class="modal-card__text">
                      Обучение персонала и&nbsp;мотивационная программа
                    </div>
                  </div>
                  <div class="modal-card">
                    <div class="modal-card__icon modal-card__icon_2"></div>
                    <div class="modal-card__text">
                      Сотрудники убирают с&nbsp;полок мятые и&nbsp;подпорченные
                      плоды три раза в&nbsp;день
                    </div>
                  </div>
                  <div class="modal-card">
                    <div class="modal-card__icon modal-card__icon_3"></div>
                    <div class="modal-card__text">
                      Новые алгоритмы автозаказа и&nbsp;пополнения
                      распределительных центров
                    </div>
                  </div>
                  <div class="modal-card">
                    <div class="modal-card__icon modal-card__icon_4"></div>
                    <div class="modal-card__text">
                      Улучшенные стандарты контроля свежести не&nbsp;только
                      на&nbsp;складах, но&nbsp;и&nbsp;в&nbsp;супермаркетах
                    </div>
                  </div>
                  <div class="modal-card">
                    <div class="modal-card__icon modal-card__icon_5"></div>
                    <div class="modal-card__text">
                      Анализ обратной связи от&nbsp;покупателей о&nbsp;чистоте
                      торговых точек и&nbsp;отзывчивости персонала
                    </div>
                  </div>
                  <div class="modal-card">
                    <div class="modal-card__icon modal-card__icon_6"></div>
                    <div class="modal-card__text">
                      +14&nbsp;пунктов восприятия свежести у&nbsp;покупателей
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :next="{
            text: `Проект &laquo;Полезное&nbsp;питание&raquo;`,
            class: `button_nav_health_2 button_next_health_2`,
            id: `health_2`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'health_2'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Проект &laquo;Полезное питание&raquo;
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col col-md-7 offset-md-1">
                <h3 class="h3 h3_color_green m-0">
                  130 вариантов полезного питания
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_light-green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-8 offset-md-2">
                <ul
                  class="modal-feature modal-feature_theme_white modal-feature_icon_big"
                >
                  <li class="icon-white-7">
                    <h4>
                      Полезное меню на&nbsp;весь день. И&nbsp;для диабетиков
                      тоже
                    </h4>
                  </li>
                  <li class="icon-white-8">
                    <h4>
                      Полезные крупы, травяные чаи, отруби, зерновые хлопья
                      на&nbsp;завтрак
                    </h4>
                  </li>
                  <li class="icon-white-9">
                    <h4>
                      Орехи, сухофрукты, батончики, мёд для перекуса
                    </h4>
                  </li>
                  <li class="icon-white-10">
                    <h4>
                      Зелень, овощи, масла для приготовления пищи
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-9 offset-md-1">
                <h3 class="h3 h3_color_green mt-0 mb-0">
                  Ищите яркие флажки «Полезное питание»!
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/health/1.webp')} 1x,
                ${require('@/assets/img/pic/modal/health/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/health/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/health/1.png')} 1x,
              ${require('@/assets/img/pic/modal/health/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <h3 class="h3 h3_color_green mt-0" style="margin-right: -10px;">
                  1/3 ассортимента &mdash;
                </h3>
                <h4 class="h4 mb-0">
                  продукция собственных торговых марок &laquo;Пятёрочки&raquo;:
                  Mixbar, Global Village, &laquo;Красная цена&raquo;.
                </h4>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Проект &laquo;Свежесть&raquo;`,
            class: `button_nav_health_1 button_prev_health_1`,
            id: `health_1`
          }"
        />
      </template>
    </Modal>
  </transition>
</template>

<script>
import { mapState } from "vuex";

// import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import ModalFooter from "@/components/ModalFooter.vue";

export default {
  components: {
    // Button,
    Modal,
    ModalFooter
  },
  computed: {
    ...mapState(["modalShow"])
  }
};
</script>
