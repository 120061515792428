<template>
  <transition name="modal">
    <Modal v-if="modalShow === 'ecology_1'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          &laquo;Зелёный маршрут&raquo;
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col col-md-6 offset-md-1">
                <h3 class="h3 h3_color_green mt-0">
                  Разделяй с&nbsp;нами любовь к&nbsp;природе!
                </h3>
                <h4 class="h4 mb-0">
                  Если правильно сортировать и&nbsp;утилизировать вторсырьё,
                  то&nbsp;из него сделают новые вещи, свалки уменьшатся,
                  а&nbsp;воздух станет чище.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/1/1.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology/1/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology/1/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/1/1.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology/1/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <h3 class="h3">
                  Уроки, инструкции и&nbsp;тесты
                </h3>
                <h4 class="h4">
                  <a
                    class="button_more_eco_1_4"
                    href="https://eco.5ka.ru/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >На&nbsp;эколендинге</a
                  >
                  учат правильно подготавливать упаковку от&nbsp;продуктов для
                  вторичной переработки.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <h3 class="h3 h3_color_green">
                  Интерактивная карта
                </h3>
                <h4 class="h4">
                  Пункты приёма пластика, макулатуры, стекла, батареек
                  и&nbsp;других материалов отмечены на&nbsp;интерактивной карте.
                </h4>
              </div>
              <div class="col-12 col-md-5">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/1/2.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology/1/2@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology/1/2.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/1/2.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology/1/2@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/1/3.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology/1/3@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology/1/3.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/1/3.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology/1/3@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <h3 class="h3 h3_color_green">
                  Видеоролики
                </h3>
                <h4 class="h4">
                  Рассказывают, как утилизировать мусор без вреда
                  для&nbsp;окружающей среды.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green  modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <h3 class="h3 mt-0">
                  Узнайте больше на&nbsp;нашем эколендинге
                </h3>
                <div class="modal-button">
                  <Button
                    tag="a"
                    class="button_type_modal button_color_orange button_more_eco_1_5"
                    link="https://eco.5ka.ru/"
                    msg="посетить эколендинг"
                  />
                </div>
              </div>
              <div class="col-12 col-md-5">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/1/4.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology/1/4@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology/1/4.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/1/4.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology/1/4@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :next="{
            text: `Электронные чеки`,
            class: `button_nav_eco_1_6 button_next_eco_1_6`,
            id: `ecology_2`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'ecology_2'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Электронные чеки
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-4 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/2/1.webp')} 1x, ${require('@/assets/img/pic/modal/ecology/2/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology/2/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/2/1.png')} 1x, ${require('@/assets/img/pic/modal/ecology/2/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="h4 modal-text">
                  <a
                    >Кассовые чеки обычно печатаются на&nbsp;термобумаге,
                    которую нельзя переработать.</a
                  >
                  <br />
                  Теперь чеки можно получать по&nbsp;электронной почте
                  без&nbsp;вреда для&nbsp;природы.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="h3 mt-0">
                  Как подписаться на&nbsp;чеки?
                </div>
                <div class="modal-list">
                  <ol>
                    <li>
                      Нажмите на&nbsp;главной странице мобильного приложения
                      &laquo;Пятёрочка&raquo; кнопку &laquo;Получать электронные
                      чеки&raquo;.
                    </li>
                    <li>
                      Укажите свой адрес электронной почты в&nbsp;открывшемся
                      профиле.
                    </li>
                    <li>
                      Покажите кассиру &laquo;Выручай-карту&raquo;, когда
                      оплачиваете покупки.
                    </li>
                    <li>
                      Получите чек на&nbsp;адрес электронной почты, который вы
                      указали.
                    </li>
                  </ol>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/2/2.webp')} 1x,
                          ${require('@/assets/img/pic/modal/ecology/2/2@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology/2/2.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/2/2.png')} 1x,
                          ${require('@/assets/img/pic/modal/ecology/2/2@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-item">
                  <h3 class="h3">
                    <a>484&nbsp;000<br />покупателей</a>
                  </h3>
                  <h4 class="h4">
                    &laquo;Пятёрочки&raquo; уже выбрали электронные чеки
                  </h4>
                </div>
              </div>
              <div class="col-12 col-md-4 offset-md-1">
                <div class="modal-item">
                  <h3 class="h3">
                    <a>более 6&nbsp;млн<br />чеков</a>
                  </h3>
                  <h4 class="h4">
                    удалось&nbsp;НЕ распечатать
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `«Зелёный маршрут»`,
            class: `button_nav_eco_1_1 button_prev_eco_1_1`,
            id: `ecology_1`
          }"
          :next="{
            text: `Пункты приема<br>пустых упаковок`,
            class: `button_nav_eco_1_7 button_next_eco_1_7`,
            id: `ecology_3`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'ecology_3'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Пункты приёма<br />вторсырья в&nbsp;&laquo;Пятёрочке&raquo;
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section modal-section_nth_first">
          <div class="container-fluid">
            <div class="row">
              <div class="col col-md-8 offset-md-1">
                <h4 class="h4">
                  <strong
                    >Одна бутылка из-под напитка = половина мусорной
                    корзины.</strong
                  >
                  Из-за объёмных упаковок мы&nbsp;выносим мусор чаще, свалки
                  наполняются быстрее. Микрочастицы пластика наносят
                  непоправимый вред природе и&nbsp;людям, отравляют почву
                  и&nbsp;воду.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <h3 class="h3 mt-0">
                  Сдавать пластик на&nbsp;переработку так&nbsp;же просто, как
                  сходить за&nbsp;покупками.
                </h3>
                <h4 class="h4 mb-0">
                  В&nbsp;некоторых наших магазинах установлены
                  фандоматы&nbsp;&mdash; аппараты для сбора пластика
                  и&nbsp;алюминия. А&nbsp;также контейнеры для сбора
                  использованных батареек.
                </h4>
              </div>
              <div class="col-12 col-md-5">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/3/2.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology/3/2@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology/3/2.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/3/2.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology/3/2@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/3/1.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology/3/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology/3/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/3/1.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology/3/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <ul class="modal-feature">
                  <li class="icon-1">
                    Фандоматы принимают пластиковые бутылки даже вместе с
                    крышками и&nbsp;наклейками
                  </li>
                  <li class="icon-3">
                    Некоторые фандоматы принимают флаконы от&nbsp;бытовой химии
                  </li>
                  <li class="icon-5">
                    Пункты приёма вторсырья есть в&nbsp;13&nbsp;магазинах Москвы
                    и&nbsp;МО, Липецка, Челябинска, и&nbsp;Белгородской области
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-10 offset-md-1">
                <ul class="modal-feature modal-feature_column_2">
                  <li class="icon-6">
                    Для использованных батареек&nbsp;&mdash; специальные
                    контейнеры за&nbsp;кассами
                  </li>
                  <li class="icon-2">
                    Металлическое сырьё от&nbsp;батареек используется повторно
                    в&nbsp;промышленности
                  </li>
                  <li class="icon-4">
                    Марганец применяется для окраски тротуарной плитки
                    и&nbsp;кирпичей
                  </li>
                  <li class="icon-5">
                    Контейнеры для батареек есть в&nbsp;41&nbsp;магазинах
                    в&nbsp;Челябинске и&nbsp;50&nbsp;магазинах в&nbsp;Казани
                    и&nbsp;Самаре
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green  modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 offset-md-1">
                <h3 class="h3 mt-0">
                  Скоро фандоматов станет больше!
                </h3>
                <h4 class="h4 mb-0">
                  Мы&nbsp;надеемся, что раздельный сбор мусора станет новой
                  хорошей привычкой покупателей.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-7 offset-md-1">
                <h3 class="h3 mt-0">
                  Адреса фандоматов и&nbsp;пунктов приёма батареек
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-10 offset-md-1">
                <div class="modal-map">
                  <div class="modal-map__head">
                    <button
                      v-for="(item, index) in tabs"
                      :key="index"
                      class="modal-map__tab"
                      :class="{
                        'modal-map__tab_state_active': index === tabsActive
                      }"
                      @click="tabsActive = index"
                    >
                      {{ item.name }}
                    </button>
                  </div>
                  <div class="modal-map__body">
                    <iframe
                      :src="tabs[tabsActive].link"
                      width="560"
                      height="400"
                      frameborder="0"
                      allowfullscreen="true"
                      style="position:relative;"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Электронные чеки`,
            class: `button_nav_eco_1_6 button_prev_eco_1_6`,
            id: `ecology_2`
          }"
          :next="{
            text: `Сбор тары<br>на&nbsp;мероприятиях`,
            class: `button_nav_eco_1_8 button_next_eco_1_8`,
            id: `ecology_4`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'ecology_4'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Сбор тары на&nbsp;мероприятиях
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-4 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/4/1.webp')} 1x, ${require('@/assets/img/pic/modal/ecology/4/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology/4/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/4/1.png')} 1x, ${require('@/assets/img/pic/modal/ecology/4/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-5 offset-md-1">
                <div class="h4 h4_color_green modal-text">
                  Посетители крупных мероприятий обычно оставляют много мусора,
                  который клининговые службы собирают в&nbsp;общие контейнеры.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <h3 class="h3 mt-0">
                  Наша помощь природе &mdash;
                </h3>
                <h4 class="h4 mb-0">
                  установить на&nbsp;этих мероприятиях мобильные аппараты для
                  приёма пластиковых бутылок и&nbsp;алюминиевых банок.
                </h4>
              </div>
              <div class="col-12 col-md-5">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/4/2.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology/4/2@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology/4/2.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/4/2.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology/4/2@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-8 offset-md-2">
                <ul class="modal-feature modal-feature_icon_big">
                  <li class="icon-7">
                    <span>
                      <h3>6&nbsp;200 пустых бутылок</h3>
                      собрали зрители гонок<br />&laquo;Формулы&nbsp;1&raquo;
                      в&nbsp;Сочи
                    </span>
                  </li>
                  <li class="icon-8">
                    <span>
                      <h3>
                        5&nbsp;700 пластиковых и&nbsp;алюминиевых ёмкостей
                      </h3>
                      собрали участники &laquo;Стереолето&raquo;
                      и&nbsp;&laquo;Московский марафон&raquo;
                    </span>
                  </li>
                  <li class="icon-9">
                    <span>
                      <h3>За&nbsp;каждую упаковку</h3>
                      зрители получали автографы гонщиков, скидки
                      на&nbsp;доставку и&nbsp;дополнительные баллы на&nbsp;карту
                      лояльности
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green  modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-9 offset-md-1">
                <h3 class="h3 mt-0 mb-0">
                  Мы&nbsp;планируем устанавливать фандоматы на&nbsp;других
                  крупных событиях и&nbsp;мероприятиях.
                </h3>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Пункты приёма вторсырья в&nbsp;&laquo;Пятёрочке&raquo;`,
            class: `button_nav_eco_1_7 button_prev_eco_1_7`,
            id: `ecology_3`
          }"
          :next="{
            text: `Многоразовые экосумки для продуктов`,
            class: `button_nav_eco_1_9 button_next_eco_1_9`,
            id: `ecology_5`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'ecology_5'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Многоразовые экосумки для продуктов
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 offset-md-1">
                <div class="h4 h4_color_green modal-text m-0">
                  <p class="mt-0">
                    Многоразовые сумки-шопперы для продуктов моднее
                    и&nbsp;экологичнее, чем пакет с&nbsp;пакетами на&nbsp;вашей
                    кухне.
                  </p>
                  <p class="mb-0">
                    А&nbsp;для взвешивания овощей и&nbsp;фруктов тоже есть
                    маленькие многоразовые нейлоновые мешочки.
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/5/1.webp')} 1x, ${require('@/assets/img/pic/modal/ecology/5/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology/5/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology/5/1.png')} 1x, ${require('@/assets/img/pic/modal/ecology/5/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 offset-md-3">
                <ul
                  class="modal-feature modal-feature_theme_white modal-feature_icon_big modal-feature_align_center"
                >
                  <li class="icon-white-1">
                    <h4>Практичные и&nbsp;износостойкие материалы</h4>
                  </li>
                  <li class="icon-white-2">
                    <h4>Можно регулярно стирать</h4>
                  </li>
                  <li class="icon-white-3">
                    <h4>Экотренд XXI&nbsp;века</h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <h2 class="h3 align-center">
                  Уже продано
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4 offset-md-1">
                <div class="modal-item">
                  <h3 class="h3 h3_color_green">
                    3&nbsp;миллиона
                  </h3>
                  <h4 class="h4">
                    больших многоразовых сумок
                  </h4>
                </div>
              </div>
              <div class="col-12 col-md-4 offset-md-2">
                <div class="modal-item">
                  <h3 class="h3 h3_color_green">
                    210 тысяч
                  </h3>
                  <h4 class="h4">
                    маленьких мешочков для взвешивания овощей и&nbsp;фруктов
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Сбор тары на&nbsp;мероприятиях`,
            class: `button_nav_eco_1_8 button_prev_eco_1_8`,
            id: `ecology_4`
          }"
        />
      </template>
    </Modal>
  </transition>
</template>

<script>
import { mapState } from "vuex";

import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import ModalFooter from "@/components/ModalFooter.vue";

export default {
  components: {
    Button,
    Modal,
    ModalFooter
  },
  computed: {
    ...mapState(["modalShow"])
  },
  data() {
    return {
      tabsActive: 0,
      tabs: [
        {
          name: "Фандоматы",
          link: "https://yandex.ru/map-widget/v1/-/CCUUAPXAoC"
        },
        {
          name: "Батарейки",
          link: "https://yandex.ru/map-widget/v1/-/CCUEmVQETC"
        }
      ]
    };
  }
};
</script>
