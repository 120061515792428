<template>
  <transition name="modal">
    <Modal v-if="modalShow === 'community_1'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Островки безопасности
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 offset-md-1">
                <h3 class="h3 h3_color_green">В каждом магазине «Пятёрочка»</h3>
                <h4 class="h4 modal-text">
                  есть специальная зона &laquo;Островок безопасности&raquo;,
                  в&nbsp;которой установлен заметный ориентир&nbsp;&mdash;
                  оранжевый знак в&nbsp;виде геолокационного пина.
                </h4>
              </div>
              <div class="col-12 col-md-4">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/1/1.webp')} 1x, ${require('@/assets/img/pic/modal/community/1/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/1/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/1/1.png')} 1x, ${require('@/assets/img/pic/modal/community/1/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-7 offset-md-1">
                <div class="h3 mt-0">
                  Если вы&nbsp;потерялись или нашли дезориентированного
                  человека:
                </div>
              </div>
              <div class="col-12 col-md-6 offset-md-1">
                <div class="modal-list mt-0 mb-0">
                  <ol>
                    <li>
                      Зайдите в&nbsp;любой универсам торговой сети
                      и&nbsp;объясните сотруднику, что произошло.
                    </li>
                    <li>
                      Дождитесь помощи в&nbsp;зоне &laquo;Островка
                      безопасности&raquo;.
                    </li>
                    <li>
                      Сотрудники магазина прошли специальное обучение
                      и&nbsp;знают, как помочь потерявшемуся человеку вернуться
                      домой.
                    </li>
                    <li>
                      Расскажите про &laquo;Островок безопасности&raquo; родным
                      и&nbsp;близким, чтобы они знали, что делать
                      в&nbsp;экстренной ситуации.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-8 offset-md-1">
                <h3 class="h3 h3_color_green m-0">
                  Наша общая цель&nbsp;&mdash; помочь потерявшимся взрослым
                  и&nbsp;детям вернуться домой.
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-8 offset-md-2">
                <ul class="modal-feature modal-feature_icon_big">
                  <li class="icon-17">
                    <span>
                      <h3>Все сотрудники нашей сети</h3>
                      прошли специальный курс обучения, умеют определять
                      потерявшегося или&nbsp;дезориентированного человека
                      и&nbsp;оказывать ему помощь
                    </span>
                  </li>
                  <li class="icon-18">
                    <span>
                      <h3>>17&nbsp;000</h3>
                      &laquo;Островков безопасности&raquo;
                    </span>
                  </li>
                  <li class="icon-19">
                    <span>
                      <h3>140&nbsp;000 человек</h3>
                      теряется в&nbsp;России ежегодно по&nbsp;данным
                      &laquo;ЛизаАлерт&raquo;
                    </span>
                  </li>
                  <li class="icon-20">
                    <span>
                      <h3>>2000 семьям</h3>
                      мы&nbsp;вместе помогли найти своих близких
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <h3 class="h3 mt-0">
                  Адреса на&nbsp;карте тематического портала
                </h3>
                <div class="modal-button">
                  <Button
                    tag="a"
                    class="button_type_modal button_color_orange button_more_soc_1_1"
                    link="https://liza.5ka.ru/"
                    msg="посетить портал"
                  />
                </div>
              </div>
              <div class="col-12 col-md-5">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/1/2.webp')} 1x,
                ${require('@/assets/img/pic/modal/community/1/2@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/1/2.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/1/2.png')} 1x,
              ${require('@/assets/img/pic/modal/community/1/2@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/1/3.webp')} 1x,
                ${require('@/assets/img/pic/modal/community/1/3@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/1/3.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/1/3.png')} 1x,
              ${require('@/assets/img/pic/modal/community/1/3@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <h3 class="h3 h3_color_green mt-0">
                  &laquo;Уроки безопасности&raquo; для детей
                  и&nbsp;их&nbsp;родителей:
                </h3>
                <p class="h4 modal-text">
                  как вести себя в&nbsp;неизвестных местах с&nbsp;незнакомыми
                  людьми.
                </p>
                <div class="modal-button">
                  <Button
                    class="button_type_modal button_more_soc_1_2"
                    msg="Смотреть видео"
                    @click="onGoToSection"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :next="{
            text: `Скидки для пенсионеров и&nbsp;покупателей с&nbsp;детьми`,
            class: `button_nav_soc_2 button_next_soc_2`,
            id: `community_2`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'community_2'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Скидки для пенсионеров и&nbsp;покупателей с&nbsp;детьми
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section modal-section_nth_first">
          <div class="container-fluid">
            <div class="row">
              <div class="col col-md-6 offset-md-1">
                <h4 class="h4 mb-0">
                  <strong
                    >Мы&nbsp;придумали специальные акции для покупателей,
                    которые нуждаются в&nbsp;поддержке.</strong
                  >
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-4 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/2/1.webp')} 1x,
                        ${require('@/assets/img/pic/modal/community/2/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/2/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/2/1.png')} 1x,
                        ${require('@/assets/img/pic/modal/community/2/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <h3 class="h3 mt-0" style="margin-right: 15px">
                  Стоимость товаров<br />для пенсионеров снижена на&nbsp;10%.
                </h3>
                <h4 class="h4 mb-0">
                  Акция действует ежедневно с&nbsp;момента открытия магазина
                  и&nbsp;до&nbsp;13.00.
                </h4>
                <a
                  href="/static/docs/pensionery_06p2_22.pdf"
                  target="_blank"
                  style="color: #fff; display: block; width: max-content;"
                  ><h4 class="h4">Смотреть полные правила</h4></a
                >
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row row-reverse-xs">
              <div class="col-12 col-md-6 offset-md-1">
                <h3 class="h3 h3_color_green mt-0" style="margin-right: 15px">
                  Покупателям с&nbsp;детьми до&nbsp;13&nbsp;лет&nbsp;&mdash;
                  скидка&nbsp;10% каждую среду в&nbsp;течение всего дня.
                </h3>
                <h4 class="h4 mb-0">
                  Проект &laquo;Семейная среда&raquo; предлагает взрослым ходить
                  в&nbsp;магазин вместе с&nbsp;малышами,
                  а&nbsp;не&nbsp;оставлять их&nbsp;дома одних.
                </h4>
                <a
                  href="/static/docs/wednesday_rls.pdf"
                  target="_blank"
                  style="color: #333; display: block; width: max-content;"
                  ><h4 class="h4">Смотреть полные правила</h4></a
                >
              </div>
              <div class="col-12 col-md-4">
                <div class="modal-pic m-0">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/2/2.webp')} 1x,
                ${require('@/assets/img/pic/modal/community/2/2@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/2/2.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/2/2.png')} 1x,
              ${require('@/assets/img/pic/modal/community/2/2@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Островки безопасности`,
            class: `button_nav_soc_1 button_prev_soc_1`,
            id: `community_1`
          }"
          :next="{
            text: `Корзина доброты`,
            class: `button_nav_soc_3 button_next_soc_3`,
            id: `community_3`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'community_3'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Корзина доброты
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section modal-section_nth_first">
          <div class="container-fluid">
            <div class="row">
              <div class="col col-md-7 offset-md-1">
                <h4 class="h4 mb-0">
                  <strong
                    >Бывает так, что многодетные семьи, ветераны, пенсионеры,
                    люди с&nbsp;инвалидностью не&nbsp;могут купить даже базовые
                    продукты и&nbsp;медикаменты.</strong
                  >
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <h4 class="h4 mt-0">
                  Чтобы их&nbsp;поддержать &laquo;Пятёрочка&raquo;,
                  X5&nbsp;Retail Group и&nbsp;Фонд продовольствия
                  &laquo;Русь&raquo; в&nbsp;2015 году запустили совместный
                  социальный проект продовольственной помощи &laquo;Корзина
                  доброты&raquo;.
                </h4>
                <div class="modal-button">
                  <Button
                    tag="a"
                    class="button_type_modal button_more_soc_3_1"
                    link="https://korzina.5ka.ru"
                    msg="Сайт проекта"
                  />
                </div>
              </div>
              <div class="col-12 col-md-4 offset-md-1">
                <div class="modal-pic m-0">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/3/1.webp')} 1x,
                ${require('@/assets/img/pic/modal/community/3/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/3/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/3/1.png')} 1x,
              ${require('@/assets/img/pic/modal/community/3/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/3/2.webp')} 1x,
                ${require('@/assets/img/pic/modal/community/3/2@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/3/2.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/3/2.png')} 1x,
              ${require('@/assets/img/pic/modal/community/3/2@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <h3 class="h3 h3_color_green mt-0 mb-0">
                  146&nbsp;000 посетителей
                </h3>
                <p class="h4 modal-text mt-8">
                  купили продукты и&nbsp;отправили нуждающимся
                </p>
                <h3 class="h3 h3_color_green mt-36 mb-0">62&nbsp;000 семей</h3>
                <p class="h4 modal-text mt-8 mb-0">
                  получили &laquo;Корзины доброты&raquo; через волонтеров
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <h3 class="h3 h3_color_green mt-0 mb-0">12 000 килограмм</h3>
                <p class="h4 modal-text mt-8">
                  корма собрано для бездомных животных
                </p>
                <h3 class="h3 h3_color_green mt-36 mb-0">8 000 питомцев</h3>
                <p class="h4 modal-text mt-8">сыты</p>
                <h3 class="h3 h3_color_green mt-36 mb-0">18 приютов</h3>
                <p class="h4 modal-text mt-8 mb-0">получат помощь</p>
              </div>
              <div class="col-12 col-md-5">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/3/3.webp')} 1x,
                ${require('@/assets/img/pic/modal/community/3/3@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/3/3.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/3/3.png')} 1x,
              ${require('@/assets/img/pic/modal/community/3/3@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-pic m-0">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/3/4.webp')} 1x,
                          ${require('@/assets/img/pic/modal/community/3/4@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/3/4.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/3/4.png')} 1x,
                          ${require('@/assets/img/pic/modal/community/3/4@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <div class="h3 mt-0">Как принять участие в&nbsp;проекте?</div>
                <div class="modal-list">
                  <ol>
                    <li>
                      <span
                        >Узнайте даты проведения акций по&nbsp;сбору помощи
                        и&nbsp;адреса пунктов сбора на&nbsp;официальном сайте
                        проекта
                        <a
                          href="https://korzina.5ka.ru"
                          class="button_more_soc_3_2_1"
                          target="_blank"
                          rel="noopener noreferrer"
                          >корзинадоброты.рф</a
                        >. Купите продукты и&nbsp;передайте их&nbsp;волонтёрам
                        прямо в&nbsp;торговом зале
                        &laquo;Пятёрочки&raquo;.</span
                      >
                    </li>
                    <li>
                      <span
                        >Для участия онлайн выберите и&nbsp;оплатите продуктовый
                        набор или корма для животных
                        <a
                          href="https://korzina.5ka.ru"
                          class="button_more_soc_3_2_2"
                          target="_blank"
                          rel="noopener noreferrer"
                          >на&nbsp;специальном лендинге проекта</a
                        >.</span
                      >
                    </li>
                    <li>
                      Станьте волонтёром Фонда продовольствия
                      &laquo;Русь&raquo;. Помогайте собирать, сортировать,
                      перевозить продукты и&nbsp;народные обеды.
                    </li>
                    <li style="width: 95%">
                      Окажите проекту творческую поддержку.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-6 offset-md-1">
                <h3 class="h3 h3_color_green m-0">
                  Состав продуктового набора:
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="modal-slider">
                  <button
                    type="button"
                    class="swiper-nav swiper-nav-prev"
                    aria-label="Предыдущий"
                  ></button>
                  <button
                    type="button"
                    class="swiper-nav swiper-nav-next"
                    aria-label="Следующий"
                  ></button>
                  <swiper
                    v-if="show"
                    :navigation="{
                      nextEl: '.modal-slider .swiper-nav-next',
                      prevEl: '.modal-slider .swiper-nav-prev'
                    }"
                    :pagination="{ clickable: true }"
                    :breakpoints="breakpoints"
                  >
                    <swiper-slide class="modal-slider__slide">
                      <div class="modal-slider__item">
                        <div
                          class="modal-slider__icon modal-slider__icon_1"
                        ></div>
                        <div class="modal-slider__text">Макароны</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="modal-slider__slide">
                      <div class="modal-slider__item">
                        <div
                          class="modal-slider__icon modal-slider__icon_2"
                        ></div>
                        <div class="modal-slider__text">
                          Крупы (гречка или овсянка)
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="modal-slider__slide">
                      <div class="modal-slider__item">
                        <div
                          class="modal-slider__icon modal-slider__icon_3"
                        ></div>
                        <div class="modal-slider__text">
                          Ультрапастеризован-ное молоко
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="modal-slider__slide">
                      <div class="modal-slider__item">
                        <div
                          class="modal-slider__icon modal-slider__icon_4"
                        ></div>
                        <div class="modal-slider__text">Растительное масло</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="modal-slider__slide">
                      <div class="modal-slider__item">
                        <div
                          class="modal-slider__icon modal-slider__icon_5"
                        ></div>
                        <div class="modal-slider__text">Консервы</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="modal-slider__slide">
                      <div class="modal-slider__item">
                        <div
                          class="modal-slider__icon modal-slider__icon_6"
                        ></div>
                        <div class="modal-slider__text">Чай</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="modal-slider__slide">
                      <div class="modal-slider__item">
                        <div
                          class="modal-slider__icon modal-slider__icon_7"
                        ></div>
                        <div class="modal-slider__text">Сахар</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="modal-slider__slide">
                      <div class="modal-slider__item">
                        <div
                          class="modal-slider__icon modal-slider__icon_8"
                        ></div>
                        <div class="modal-slider__text">Сухофрукты</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="modal-slider__slide">
                      <div class="modal-slider__item">
                        <div
                          class="modal-slider__icon modal-slider__icon_9"
                        ></div>
                        <div class="modal-slider__text">
                          Сладости (пряники или печенье)
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <h3 class="h3 mt-0">Примите участие в&nbsp;проекте!</h3>
                <div class="modal-button">
                  <Button
                    tag="a"
                    class="button_type_modal button_color_orange button_more_soc_3_2"
                    link="https://korzina.5ka.ru"
                    msg="сайт проекта"
                  />
                </div>
              </div>
              <div class="col-12 col-md-5">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/3/5.webp')} 1x,
                ${require('@/assets/img/pic/modal/community/3/5@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/3/5.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/3/5.png')} 1x,
              ${require('@/assets/img/pic/modal/community/3/5@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Скидки для пенсионеров и&nbsp;покупателей с&nbsp;детьми`,
            class: `button_nav_soc_2 button_prev_soc_2`,
            id: `community_2`
          }"
          :next="{
            text: `Конфетка доброты`,
            class: `button_nav_soc_4 button_next_soc_4`,
            id: `community_4`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'community_4'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Конфетка доброты
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section modal-section_nth_first">
          <div class="container-fluid">
            <div class="row">
              <div class="col col-md-7 offset-md-1">
                <h4 class="h4 mb-0">
                  <strong
                    >Федеральная благотворительная программа &laquo;Конфетка
                    доброты&raquo; направлена на&nbsp;поддержку больных
                    детей.</strong
                  >
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-pic m-0">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/4/1.webp')} 1x,
                ${require('@/assets/img/pic/modal/community/4/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/4/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/4/1.png')} 1x,
              ${require('@/assets/img/pic/modal/community/4/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <h4 class="h4 mb-0">«КОНФЕТКА ДОБРОТЫ» стоит</h4>
                <h3 class="h3 h3_color_green mt-0">15 рублей</h3>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-6 offset-md-1">
                <h3 class="h3 h3_color_green mt-0" style="margin-right: -10px">
                  5&nbsp;рублей с&nbsp;продажи каждого леденца
                </h3>
                <h4 class="h4 mb-0">
                  мы&nbsp;перечисляем на&nbsp;операции подопечным
                  благотворительного Фонда &laquo;Линия Жизни&raquo;.
                </h4>
              </div>
              <div class="col-12 col-md-4">
                <div class="modal-pic m-0">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/4/2.webp')} 1x,
                ${require('@/assets/img/pic/modal/community/4/2@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/4/2.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/4/2.png')} 1x,
              ${require('@/assets/img/pic/modal/community/4/2@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-item">
                  <h3 class="h3">8&nbsp;200&nbsp;000&nbsp;₽</h3>
                  <h4 class="h4">собрано в&nbsp;2020 году</h4>
                </div>
              </div>
              <div class="col-12 col-md-4 offset-md-1">
                <div class="modal-item">
                  <h3 class="h3">45&nbsp;детей</h3>
                  <h4 class="h4">получили помощь</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-8 offset-md-1">
                <h3 class="h3 h3_color_green m-0">
                  Акция &laquo;Конфетка Доброты&raquo; вошла в&nbsp;ТОП-30
                  лучших социальных проектов России.
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-pic m-0">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/4/3.webp')} 1x,
                ${require('@/assets/img/pic/modal/community/4/3@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/4/3.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/4/3.png')} 1x,
              ${require('@/assets/img/pic/modal/community/4/3@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <h3 class="h4">
                  Вместо вредных для здоровья искусственных красителей леденец
                  содержит сок чёрной моркови и&nbsp;бета-каротин.
                </h3>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Корзина доброты`,
            class: `button_nav_soc_3 button_prev_soc_3`,
            id: `community_3`
          }"
          :next="{
            text: `Городская среда`,
            class: `button_nav_soc_5 button_next_soc_5`,
            id: `community_5`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'community_5'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Городская среда
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-6 offset-md-1">
                <h3 class="h3 h3_color_green mt-0">
                  Мы&nbsp;хотим повысить качество жизни людей,
                </h3>
                <h4 class="h4 mb-0">
                  поэтому построили спортивные объекты в&nbsp;Липецке.
                </h4>
              </div>
              <div class="col-12 col-md-4">
                <div class="modal-pic m-0">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/5/1.webp')} 1x,
                ${require('@/assets/img/pic/modal/community/5/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/community/5/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/community/5/1.png')} 1x,
              ${require('@/assets/img/pic/modal/community/5/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-9 offset-md-2">
                <ul
                  class="modal-feature modal-feature_icon_big modal-feature_align_center modal-feature_text_center_xs"
                >
                  <li class="icon-21">
                    <h4 class="color-inherit">
                      Средняя площадь&nbsp;&mdash; 50&nbsp;кв.&nbsp;м
                    </h4>
                  </li>
                  <li class="icon-22">
                    <h4 class="color-inherit">
                      На&nbsp;площадках есть брусья, тренажёры-шагоходы,
                      турники, скамьи для отжиманий и&nbsp;упражнений
                      на&nbsp;пресс
                    </h4>
                  </li>
                  <li class="icon-23">
                    <h4 class="color-inherit">
                      В&nbsp;строительстве использованы качественные материалы,
                      например, мягкое резиновое покрытие, которое может
                      защитить от&nbsp;травм детей и&nbsp;взрослых
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-7 offset-md-1">
                <h4 class="h4 mt-0">
                  Площадки построены на&nbsp;Соколе, Тракторном, Сырском
                  руднике, в&nbsp;&laquo;Звёздном&raquo;, 11-м и&nbsp;24-м
                  микрорайонах.
                </h4>
              </div>
              <div class="col-12 col-md-10 offset-md-1">
                <h3 class="h3 mt-0">
                  Проверьте, есть&nbsp;ли спортивные объекты рядом с&nbsp;вами?
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-10 offset-md-1">
                <h3 class="h3 h3_color_green">
                  Адреса новых площадок в&nbsp;Липецке:
                </h3>
                <div class="modal-map">
                  <div class="modal-map__body">
                    <iframe
                      src="https://yandex.ru/map-widget/v1/-/CCUUnIgAcC"
                      width="560"
                      height="400"
                      frameborder="0"
                      allowfullscreen="true"
                      style="position: relative"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Конфетка доброты`,
            class: `button_nav_soc_4 button_prev_soc_4`,
            id: `community_4`
          }"
          :next="{
            text: `Новые места для отдыха`,
            class: `button_nav_soc_6 button_next_soc_6`,
            id: `community_6`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'community_6'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Новые места для отдыха
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-8 offset-md-1">
                <h3 class="h3 h3_color_green mt-0">
                  Мы&nbsp;стремимся сделать все, чтобы людям было приятно
                  приходить к&nbsp;нам за&nbsp;покупками,
                </h3>
                <h4 class="h4 mb-0">
                  поэтому благоустроили новые зоны отдыха на&nbsp;месте пустых
                  и&nbsp;неухоженных пространств в Белгороде.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-10 offset-md-1">
                <h3 class="h3 h3_color_green mt-0">Было установлено</h3>
                <ul
                  class="modal-feature modal-feature_icon_middle modal-feature_align_center modal-feature_column_2 modal-feature_text_center_xs"
                >
                  <li class="icon-24">
                    <h4 class="color-inherit">10&nbsp;урн</h4>
                  </li>
                  <li class="icon-25">
                    <h4 class="color-inherit">
                      12&nbsp;энергоэффективных фонарей
                    </h4>
                  </li>
                  <li class="icon-26">
                    <h4 class="color-inherit">11&nbsp;скамеек</h4>
                  </li>
                  <li class="icon-27">
                    <h4 class="color-inherit">63&nbsp;газона с&nbsp;цветами</h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-8 offset-md-2">
                <ul
                  class="modal-feature modal-feature_icon_big modal-feature_align_center"
                >
                  <li class="icon-white-11">
                    <h4 class="color-inherit">
                      Организованы велопарковки и&nbsp;высажены новые растения
                    </h4>
                  </li>
                  <li class="icon-white-12">
                    <h4 class="color-inherit">
                      Построена детская игровая площадка с&nbsp;различным
                      оборудованием на&nbsp;ул. 50-летия Белгородской области.
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-10 offset-md-1">
                <h3 class="h3 h3_color_green">
                  Адреса новых площадок в Белгороде:
                </h3>
                <div class="modal-map">
                  <div class="modal-map__body">
                    <iframe
                      src="https://yandex.ru/map-widget/v1/-/CCUmY0ubKC"
                      width="560"
                      height="400"
                      frameborder="0"
                      allowfullscreen="true"
                      style="position: relative"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Городская среда`,
            class: `button_nav_soc_5 button_prev_soc_5`,
            id: `community_5`
          }"
        />
      </template>
    </Modal>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import ModalFooter from "@/components/ModalFooter.vue";

import SwiperCore, { Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([Virtual, Navigation, Pagination]);

export default {
  components: {
    Button,
    Modal,
    ModalFooter,
    Swiper,
    SwiperSlide
  },
  computed: {
    ...mapState(["modalShow", "screenSize"])
  },
  data() {
    return {
      breakpoints: {
        0: {
          slidesPerView: "auto",
          spaceBetween: 15,
          slidesOffsetBefore: 16,
          slidesOffsetAfter: 16
        },
        768: {
          slidesPerView: "auto",
          spaceBetween: 30,
          slidesOffsetBefore: 80,
          slidesOffsetAfter: 80
        },
        900: {
          slidesPerView: "auto",
          spaceBetween: 30,
          slidesOffsetBefore: 90,
          slidesOffsetAfter: 90
        },
        1100: {
          slidesPerView: "auto",
          spaceBetween: 30,
          slidesOffsetBefore: 95,
          slidesOffsetAfter: 95
        },
        1200: {
          slidesPerView: "auto",
          spaceBetween: 30,
          slidesOffsetBefore: 105,
          slidesOffsetAfter: 105
        }
      },
      show: true
    };
  },
  watch: {
    screenSize() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  methods: {
    ...mapMutations(["setModalShow"]),
    onGoToSection() {
      this.setModalShow(false);

      const videoButton = document.getElementById("video_for_community");
      if (videoButton) {
        videoButton.click();
      }

      try {
        document
          .getElementsByClassName("videos")[0]
          .getElementsByClassName("swiper-pagination-bullet")[0]
          .click();
      } catch (error) {
        console.error("не удалось переключиться на первый слайд");
      }
    }
  }
};
</script>
