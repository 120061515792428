<template>
  <div
    class="landing-section landing-section_color_green landing-section_theme_white effect-anchor"
  >
    <template v-if="screenSize === 'xs'" />
    <Pattern
      v-else-if="screenSize === 'md'"
      fill="#007F2D"
      offsetY="1"
      rows="8"
      columns="3"
    />
    <Pattern v-else fill="#007F2D" offsetY="1" rows="5" columns="3" />
    <div class="landing-section__head">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="h2 landing-section__title effect-unit" data-delay="1">
              Забота о здоровье
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="landing-section__body">
      <Pattern
        class="pattern_place_picture-xs"
        v-if="screenSize === 'xs'"
        fill="#007F2D"
        direction="horizontal"
        rows="3"
        columns="6"
        offsetX="0"
        offsetY="1"
        patternTransform="rotate(90)"
      />
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <div class="landing-section__content">
              <h3 class="h3 effect-unit" data-delay="2">Проект «Свежесть»</h3>
              <p class=" effect-unit" data-delay="3">
                Свежесть&nbsp;&mdash; один из&nbsp;самых важных стандартов
                для&nbsp;&laquo;Пятёрочки&raquo;. Поэтому все
                процессы&nbsp;&mdash; от&nbsp;закупки у&nbsp;поставщика
                до&nbsp;доставки в&nbsp;магазин&nbsp;&mdash; направлены
                на&nbsp;сохранение свежести продуктов.
              </p>
              <div class="landing-section__buttons effect-unit" data-delay="4">
                <Button
                  class="button_color_white button_more_health_1"
                  msg="ПОДРОБНЕЕ"
                  @click="setModalShow('health_1')"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="landing-section__pic effect-unit scale" data-delay="1">
              <picture>
                <source
                  type="image/webp"
                  :srcset="
                    `${require('@/assets/img/pic/30.webp')} 1x,
                ${require('@/assets/img/pic/30@2x.webp')} 2x`
                  "
                />
                <img
                  :src="require('@/assets/img/pic/30.png')"
                  :srcset="
                    `${require('@/assets/img/pic/30.png')} 1x,
              ${require('@/assets/img/pic/30@2x.png')} 2x`
                  "
                  width="434"
                  height="434"
                  loading="lazy"
                  alt=""
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="landing-section landing-section_decor_dark effect-anchor">
    <Pattern
      v-if="screenSize === 'xs'"
      fill="#E6E5E4"
      direction="horizontal"
      rows="3"
      columns="6"
      offsetX="0"
      offsetY="1"
      patternTransform="rotate(180)"
    />
    <Pattern
      v-else-if="screenSize === 'md'"
      fill="#E6E5E4"
      position="left"
      offsetX="1"
      offsetY="0"
      rows="6"
      columns="3"
      patternTransform="rotate(90)"
    />
    <Pattern
      v-else
      fill="#E6E5E4"
      offsetX="0"
      offsetY="1"
      rows="4"
      position="left"
      columns="3"
      patternTransform="rotate(90)"
    />
    <div class="landing-section__body landing-section__body_offset_small">
      <div class="container">
        <div class="row row-reverse align-items-center">
          <div class="col-12 col-md-6">
            <div class="landing-section__content">
              <h3 class="h3 effect-unit" data-delay="1">
                Проект<br />«Полезное<br />питание»
              </h3>
              <p class=" effect-unit" data-delay="2">
                Популярные продукты для здорового питания теперь стоят
                на&nbsp;отдельной полке. Покупатели легко смогут их&nbsp;найти
                по&nbsp;отметке &laquo;Полезное питание&raquo;.
              </p>
              <div class="landing-section__buttons effect-unit" data-delay="3">
                <Button
                  class="button_more_health_2"
                  msg="ПОДРОБНЕЕ"
                  @click="setModalShow('health_2')"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="landing-section__pic effect-unit scale" data-delay="1">
              <picture>
                <source
                  type="image/webp"
                  :srcset="
                    `${require('@/assets/img/pic/31.webp')} 1x,
                ${require('@/assets/img/pic/31@2x.webp')} 2x`
                  "
                />
                <img
                  :src="require('@/assets/img/pic/31.png')"
                  :srcset="
                    `${require('@/assets/img/pic/31.png')} 1x,
              ${require('@/assets/img/pic/31@2x.png')} 2x`
                  "
                  width="434"
                  height="434"
                  loading="lazy"
                  alt=""
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import Pattern from "@/components/Pattern.vue";
import { mapMutations } from "vuex";

export default {
  name: "Home",
  components: {
    Button,
    Pattern
  },
  methods: {
    ...mapMutations(["setModalShow"])
  }
};
</script>

<style scoped lang="scss">
// .health {
// }
</style>
