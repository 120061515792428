<template>
  <div class="landing-section landing-section_color_grey effect-anchor">
    <Pattern
      v-if="screenSize !== 'xs'"
      fill="#E6E5E4"
      direction="horizontal"
      position="bottom"
      rows="2"
      columns="10"
    />
    <div class="landing-section__head">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="h2 landing-section__title effect-unit" data-delay="1">
              Ответственное партнёрство
            </h2>
            <div class="head-grid">
              <h4
                class="h4 landing-section__subtitle head-grid__text effect-unit"
                data-delay="2"
              >
                Мы&nbsp;рады, что наши поставщики разделяют
                социально-ответственные принципы &laquo;Пятёрочки&raquo;.
                В&nbsp;своих промоакциях дают возможность нашим гостям
                заботиться о&nbsp;природе и&nbsp;других людях.
              </h4>
              <div class="head-grid__button effect-unit" data-delay="3">
                <Button
                  tag="router-link"
                  :link="{ name: 'Partners' }"
                  msg="Архив проектов"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="landing-section__body">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="foot-button effect-unit" data-delay="3">
              <Button
                tag="router-link"
                :link="{ name: 'Partners' }"
                msg="Архив проектов"
              />
            </div>
          </div>
          <div class="col-12">
            <div
              class="partners effect-unit"
              data-delay="3"
              :class="{
                'pagination-disabled':
                  partner.length <= 3 &&
                  (screenSize == 'lg' || screenSize == 'xl')
              }"
            >
              <button
                type="button"
                class="swiper-nav swiper-nav-prev effect-unit scale"
                data-delay="5"
                aria-label="Предыдущий"
              ></button>
              <button
                type="button"
                class="swiper-nav swiper-nav-next effect-unit scale"
                data-delay="5"
                aria-label="Следующий"
              ></button>
              <swiper
                v-if="partnerStatus === 'success' && show"
                :slides-per-view="3"
                :space-between="50"
                :navigation="{
                  nextEl: '.partners .swiper-nav-next',
                  prevEl: '.partners .swiper-nav-prev'
                }"
                :pagination="{ clickable: true }"
                :loop="true"
                :breakpoints="breakpoints"
                @swiper="onSwiper"
              >
                <swiper-slide
                  v-for="(item, index) in partner"
                  :key="index"
                  :virtualIndex="index"
                >
                  <PartnerItem :item="item" :index="index" />
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { mediaUrl } from "@/utils";

import SwiperCore, { Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([Virtual, Navigation, Pagination]);

import PartnerItem from "@/components/PartnerItem.vue";
import Pattern from "@/components/Pattern.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    PartnerItem,
    Pattern,
    Button
  },
  name: "Partners",
  data() {
    return {
      mediaUrl,
      breakpoints: {
        0: {
          slidesPerView: "auto",
          spaceBetween: 0,
          loop: false
        },
        768: {
          slidesPerGroup: 2,
          slidesPerView: 2,
          spaceBetween: 0,
          loop: true
        },
        1200: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          spaceBetween: 0,
          loop: true
        }
      },
      show: true
    };
  },
  watch: {
    screenSize() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  computed: {
    ...mapState(["partner", "partnerStatus", "screenSize"])
  },
  methods: {
    ...mapMutations(["setModalShow"]),
    onSwiper() {
      // пересчитываем высоту
      window.dispatchEvent(new Event("resize"));
    }
  }
};
</script>

<style scoped lang="scss">
.partners {
  position: relative;
  padding-top: 20px;
  @include md {
    padding-top: 0px;
  }
}

.head-grid {
  @include lg {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 60px;
  }
  &__text {
    padding-right: 50px;
  }
  &__button {
    display: none;
    @include md {
      display: block;
      margin-top: 36px;
    }
    .button {
      @include lg {
        width: 100%;
      }
    }
  }
}

.foot-button {
  @include md {
    display: none;
  }
}
</style>

<style lang="scss">
.partners {
  .swiper-wrapper {
    align-items: stretch;
    // padding: 0 7.5px;
  }
  .swiper-slide {
    height: auto;
    width: auto;
    &:last-of-type {
      padding-right: 0px;
    }
  }
}
.pagination-disabled {
  .swiper-nav {
    display: none;
  }
  .swiper-pagination {
    display: none;
  }
}
</style>
