<template>
  <div class="landing">
    <div id="home" class="landing__item">
      <Home />
    </div>
    <div id="ecology" class="landing__item">
      <Ecology />
    </div>
    <div id="community" class="landing__item">
      <Community />
    </div>
    <div id="health" class="landing__item">
      <Health />
    </div>
    <div id="safety" class="landing__item">
      <Safety />
    </div>
    <div id="partners" class="landing__item">
      <Partners />
    </div>
    <div id="awards" class="landing__item">
      <Awards />
    </div>
    <div id="video" class="landing__item">
      <Videos />
    </div>
  </div>
</template>

<script>
import Home from "@/components/Home.vue";
import Ecology from "@/components/Ecology.vue";
import Community from "@/components/Community.vue";
import Health from "@/components/Health.vue";
import Safety from "@/components/Safety.vue";
import Partners from "@/components/Partners.vue";
import Awards from "@/components/Awards.vue";
import Videos from "@/components/Videos.vue";
export default {
  name: "Landing",
  components: {
    Home,
    Ecology,
    Community,
    Health,
    Safety,
    Partners,
    Awards,
    Videos
  },
  data() {
    return {
      nodes: [],
      offsets: []
    };
  },
  mounted() {
    setTimeout(this.init, 0);
    setTimeout(this.onResize, 500);
  },
  methods: {
    init() {
      this.update();
      this.onScroll();
      window.addEventListener("scroll", this.onScroll, false);
      window.addEventListener("resize", this.onResize, false);
      window.addEventListener("effect", this.onResize, false);
    },
    update() {
      this.nodes = [];
      this.offsets = [];
      document.getElementsByClassName("effect-anchor").forEach(e => {
        if (!e.classList.contains("init")) {
          const top = e.getBoundingClientRect().top + window.pageYOffset;

          this.nodes.push(e);
          this.offsets.push(Math.floor(top));
        }
      });
    },
    onResize() {
      this.update();
      this.onScroll();
    },
    onScroll() {
      // переключаем на 1/2 экране
      const offsetTop = window.innerHeight / 2;
      const scrollY = window.scrollY + offsetTop;

      this.offsets.forEach((e, i) => {
        if (scrollY >= e) {
          this.nodes[i].classList.add("init");
          this.nodes.splice(i, 1);
          this.offsets.splice(i, 1);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.landing {
  // overflow: hidden;
  @media (min-width: 1024px) {
    padding-left: 272px;
    @include sidebarMini {
      padding-left: 100px;
    }
  }
  // &__item {
  // min-height: 100vh;
  // }
  &-section {
    position: relative;
    &_color {
      &_light-green {
        background-color: #98c21f;
      }
      &_green {
        background-color: #00923a;
      }
      &_milk {
        background-color: #fbf3dd;
      }
      &_blue {
        background-color: #86bee9;
      }
      &_orange {
        background-color: #ffad26;
      }
      &_grey {
        background-color: #f6f5f4;
      }
    }
    &_theme {
      &_white {
        color: #fff;
      }
    }
    &_full {
      &_height {
        min-height: 67vh;
      }
    }
    &_decor {
      &_white {
        position: relative;
        z-index: 1;
        // &::after {
        //   z-index: -1;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 45vw;
        //   background-image: url(../assets/img/pattern.png);
        //   background-repeat: repeat;
        //   background-size: 70vw auto;
        //   background-position: 50% 0;
        //   content: "";

        //   @include md {
        //     background-size: 43vw auto;
        //     top: 0;
        //     left: auto;
        //     right: 0;
        //     width: 27vw;
        //     height: 100%;
        //     background-position: 0 0;
        //   }
        // }
      }
      &_dark {
        position: relative;
        z-index: 1;
        // &::after {
        //   z-index: -1;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 45vw;
        //   background-image: url(../assets/img/pattern-dark.png);
        //   background-repeat: repeat;
        //   background-size: 70vw auto;
        //   background-position: 50% 0;
        //   opacity: 0.5;
        //   content: "";

        //   @include md {
        //     background-size: 43vw auto;
        //     top: 0;
        //     left: auto;
        //     right: 0;
        //     width: 27vw;
        //     height: 100%;
        //     background-position: 0 0;
        //   }
        // }
      }
    }
    &:nth-of-type(1) {
      &.landing-section_decor_white,
      &.landing-section_decor_dark {
        &::after {
          top: 154px;
          @include md {
            top: 0;
          }
        }
      }
    }
    // разворачиваем нечетные блоки
    &:nth-of-type(even) {
      .landing-section {
        &__body {
          & > .container {
            & > .row {
              flex-direction: row-reverse;
            }
          }
        }
      }
      &.landing-section_decor_white,
      &.landing-section_decor_dark {
        &::after {
          @include md {
            left: 0;
          }
        }
      }
    }
    &__head {
      padding-top: 54px;
      position: relative;
      z-index: 1;
      &_type {
        &_narrow {
          padding-top: 0px;
          @include md {
            margin-bottom: -70px;
          }
          @include lg {
            margin-bottom: -100px;
          }
        }
      }
    }
    &__body {
      padding-bottom: 48px;
      @include md {
        // padding: 54px 0;
        padding: 84px 0;
        &_offset_small {
          padding: 39px 0;
        }
      }
      > .container > .row {
        & > *:nth-child(1) {
          order: 1;
          @include md {
            order: initial;
          }
        }
      }
    }
    &__title {
      margin: 0 !important;
    }
    &__subtitle {
      margin-top: 16px;
      margin-bottom: 40px;
      font-size: 16px !important;
      font-weight: normal !important;
      @include md {
        font-size: 24px !important;
        margin-top: 36px;
        margin-bottom: 0;
        font-weight: 700 !important;
      }
      &_place_head {
        @include md {
          margin-top: 36px;
          margin-bottom: 54px;
        }
      }
    }
    &__pic {
      // width: 542px;
      // height: 542px;
      height: 0;
      width: 80%;
      padding-bottom: calc(100% * 0.8);
      position: relative;
      @include xs {
        width: 80%;
        margin: 0 auto;
        display: block;
      }
      @include md {
        width: 100%;
        padding-bottom: calc(100% * 1);
        margin: 0 auto;
        display: block;
      }
      @include xl {
        width: initial;
        margin: 0;
        &.mini {
          height: 445px;
          padding-bottom: 0;
        }
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 0 0;
      }
    }
    &__content {
      position: relative;
      h2 {
        font-weight: 900;
        font-size: 28px;
        line-height: 43px;
        margin: 24px 0;
        @include md {
          font-size: 36px;
        }
        @include lg {
          font-size: 36px;
        }
        @include xl {
          font-size: 36px;
        }
      }
      h3 {
        margin-top: 0;
        margin-bottom: 16px;
        @include md {
          margin-top: 24px;
          margin-bottom: 24px;
        }
      }
      p {
        font-size: 16px;
        line-height: 160%;
        margin: 16px 0;
        @include md {
          font-size: 16px;
          margin: 24px 0;
        }
        @include lg {
          font-size: 18px;
        }
        @include xl {
          font-size: 18px;
        }
      }
      &-group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        strong {
          font-size: 30px;
          line-height: 1;
          font-weight: 900;
          @include lg {
            font-size: 40px;
          }
          span {
            font-size: 58px;
            @include lg {
              font-size: 64px;
            }
          }
        }
        p {
          margin: 0;
        }
      }
    }
    &__buttons {
      display: flex;
      // gap: 20px;
      flex-wrap: wrap;
      margin-top: 10px;
      margin-bottom: 0px;
      margin-left: -10px;
      margin-right: -10px;
      & > * {
        margin: 10px;
        width: 100%;
      }
      @include md {
        margin-top: 38px;
        margin-bottom: 14px;
        & > * {
          width: auto;
        }
      }
      @include lg {
        margin-top: 33px;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 9px;
        & > * {
          margin: 15px;
        }
      }
    }
  }
}
</style>
