<template>
  <div class="ecology">
    <transition name="ani-ecology-transition">
      <div
        v-if="!view"
        class="ecology-transition ecology-transition_type_main"
      ></div>
      <div
        v-else-if="view === `join`"
        class="ecology-transition ecology-transition_type_join"
      ></div>
      <div
        v-else-if="view === `5ka`"
        class="ecology-transition ecology-transition_type_5ka"
      ></div>
    </transition>
    <transition name="ani-ecology-section" mode="out-in">
      <div
        v-if="screenSize === 'xs' && !view"
        v-show="!view"
        class="ecology-breed-mobile effect-anchor"
      >
        <button
          type="button"
          class="ecology-breed-mobile__nav ecology-breed-mobile__nav-prev"
          aria-label="Предыдущий"
        ></button>
        <button
          type="button"
          class="ecology-breed-mobile__nav ecology-breed-mobile__nav-next"
          aria-label="Следующий"
        ></button>
        <swiper
          class="swiper-container_theme_white"
          :navigation="{
            nextEl: '.ecology .ecology-breed-mobile__nav-next',
            prevEl: '.ecology .ecology-breed-mobile__nav-prev'
          }"
          :loop="false"
          slides-per-view="auto"
          :space-between="0"
          @swiper="onSwiper"
        >
          <swiper-slide>
            <div class="ecology-breed__item ecology-breed__item_num_1">
              <Pattern
                offsetX="0"
                offsetY="0"
                fill="#007F2D"
                direction="horizontal"
                rows="3"
                columns="4"
              />
              <div class="ecology-breed__body">
                <div
                  class="ecology-breed__pic effect-unit scale"
                  data-delay="1"
                >
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/ecology-breed-1.webp')} 1x,
                ${require('@/assets/img/pic/ecology-breed-1@2x.webp')} 2x`
                      "
                    />
                    <img
                      loading="lazy"
                      :src="require('@/assets/img/pic/ecology-breed-1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/ecology-breed-1.png')} 1x,
              ${require('@/assets/img/pic/ecology-breed-1@2x.png')} 2x`
                      "
                      width="400"
                      height="400"
                      alt=""
                    />
                  </picture>
                </div>
                <div
                  class="h3 ecology-breed__title effect-unit up"
                  data-delay="2"
                >
                  Ответственный<br />подход к&nbsp;будущему<br />планеты
                  и&nbsp;общества
                </div>
                <div
                  class="ecology-breed__buttons effect-unit up"
                  data-delay="3"
                >
                  <Button
                    @click="goTo(`join`)"
                    class="button_color_white button_more_eco_1"
                    msg="ПОДРОБНЕЕ"
                  />
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="ecology-breed__item ecology-breed__item_num_2">
              <Pattern
                offsetX="0"
                offsetY="1"
                direction="horizontal"
                rows="3"
                columns="4"
                patternTransform="rotate(90)"
              />
              <div class="ecology-breed__body">
                <div
                  class="ecology-breed__pic effect-unit scale"
                  data-delay="1"
                >
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/ecology-breed-2.webp')} 1x,
                ${require('@/assets/img/pic/ecology-breed-2@2x.webp')} 2x`
                      "
                    />
                    <img
                      loading="lazy"
                      :src="require('@/assets/img/pic/ecology-breed-2.png')"
                      :srcset="
                        `${require('@/assets/img/pic/ecology-breed-2.png')} 1x,
              ${require('@/assets/img/pic/ecology-breed-2@2x.png')} 2x`
                      "
                      width="400"
                      height="400"
                      alt=""
                    />
                  </picture>
                </div>
                <div
                  class="h3 ecology-breed__title effect-unit up"
                  data-delay="2"
                >
                  Инициативы &laquo;Пятёрочки&raquo;<br />в&nbsp;области
                  сохранения<br />окружающей среды
                </div>
                <div
                  class="ecology-breed__buttons effect-unit up"
                  data-delay="3"
                >
                  <Button
                    @click="goTo(`5ka`)"
                    class="button_color_white button_more_eco_2"
                    msg="ПОДРОБНЕЕ"
                  />
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div v-else-if="!view" class="ecology-breed effect-anchor">
        <Pattern
          class="pattern_type_multiply"
          fill="#98C21F"
          direction="horizontal"
          rows="2"
          columns="10"
        />
        <div class="ecology-breed__item ecology-breed__item_num_1">
          <div class="ecology-breed__body">
            <div class="ecology-breed__pic effect-unit scale" data-delay="1">
              <picture>
                <source
                  type="image/webp"
                  :srcset="
                    `${require('@/assets/img/pic/ecology-breed-1.webp')} 1x,
              ${require('@/assets/img/pic/ecology-breed-1@2x.webp')} 2x`
                  "
                />
                <img
                  loading="lazy"
                  :src="require('@/assets/img/pic/ecology-breed-1.png')"
                  :srcset="
                    `${require('@/assets/img/pic/ecology-breed-1.png')} 1x,
            ${require('@/assets/img/pic/ecology-breed-1@2x.png')} 2x`
                  "
                  width="400"
                  height="400"
                  alt=""
                />
              </picture>
            </div>
            <div class="h3 ecology-breed__title effect-unit up" data-delay="2">
              Ответственный<br />подход к&nbsp;будущему<br />планеты
              и&nbsp;общества
            </div>
            <div class="ecology-breed__buttons effect-unit up" data-delay="3">
              <Button
                @click="goTo(`join`)"
                class="button_color_white button_more_eco_1"
                msg="ПОДРОБНЕЕ"
              />
            </div>
          </div>
        </div>
        <div class="ecology-breed__item ecology-breed__item_num_2">
          <div class="ecology-breed__body">
            <div class="ecology-breed__pic effect-unit scale" data-delay="1">
              <picture>
                <source
                  type="image/webp"
                  :srcset="
                    `${require('@/assets/img/pic/ecology-breed-2.webp')} 1x,
              ${require('@/assets/img/pic/ecology-breed-2@2x.webp')} 2x`
                  "
                />
                <img
                  loading="lazy"
                  :src="require('@/assets/img/pic/ecology-breed-2.png')"
                  :srcset="
                    `${require('@/assets/img/pic/ecology-breed-2.png')} 1x,
            ${require('@/assets/img/pic/ecology-breed-2@2x.png')} 2x`
                  "
                  width="400"
                  height="400"
                  alt=""
                />
              </picture>
            </div>
            <div class="h3 ecology-breed__title effect-unit up" data-delay="2">
              Инициативы &laquo;Пятёрочки&raquo;<br />в&nbsp;области
              сохранения<br />окружающей среды
            </div>
            <div class="ecology-breed__buttons effect-unit up" data-delay="3">
              <Button
                @click="goTo(`5ka`)"
                class="button_color_white button_more_eco_2"
                msg="ПОДРОБНЕЕ"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="view === `join`" class="ecology-join">
        <div class="ecology-sidebar">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-5">
                <button
                  type="button"
                  @click="goTo(null)"
                  class="ecology-sidebar__button ecology-sidebar__button_type_back"
                >
                  <i><span></span></i>
                  <span>Назад</span>
                </button>
              </div>
              <div class="col-7 ecology-sidebar__col-end">
                <button
                  type="button"
                  @click="goTo(`5ka`)"
                  class="ecology-sidebar__button button_more_eco_1_3"
                >
                  <span
                    >Инициативы &laquo;Пятёрочки&raquo; в&nbsp;области
                    сохранения окружающей среды</span
                  >
                  <i class="revert"><span></span></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="ecology-join__inner">
          <div
            class="landing-section landing-section_color_green landing-section_theme_white landing-section_decor_white effect-anchor"
          >
            <Pattern
              v-if="screenSize === 'xs'"
              fill="#007F2D"
              direction="horizontal"
              rows="3"
              columns="6"
              offsetX="0"
              offsetY="1"
            />
            <Pattern
              v-else-if="screenSize === 'md'"
              fill="#007F2D"
              rows="7"
              columns="3"
              offsetX="0"
              offsetY="1"
              patternTransform="rotate(0)"
            />
            <Pattern
              v-else
              fill="#007F2D"
              offsetX="0"
              offsetY="1"
              rows="4"
              columns="3"
            />
            <div
              class="landing-section__head landing-section__head_type_narrow"
            >
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div class="landing-section__content">
                      <p>
                        Ответственный подход<br />
                        к&nbsp;будущему планеты и&nbsp;общества
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="landing-section__body">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="landing-section__content">
                      <h3 class="h3 effect-unit" data-delay="1">
                        &laquo;Зелёный маршрут&raquo;
                      </h3>
                      <p class="effect-unit" data-delay="2">
                        Проект учит сортировать вторсырьё и&nbsp;показывает
                        ближайшие пункты приёма пластика, макулатуры, стекла
                        и&nbsp;батареек.
                      </p>
                      <div class="landing-section__buttons">
                        <div class="effect-unit" data-delay="3">
                          <Button
                            class="button_color_white button_more_eco_1_1"
                            msg="ПОДРОБНЕЕ"
                            @click="setModalShow('ecology_1')"
                          />
                        </div>
                        <div class="effect-unit" data-delay="4">
                          <Button
                            tag="a"
                            class="button_color_orange button_more_eco_1_2"
                            link="https://eco.5ka.ru/"
                            msg="САЙТ ПРОЕКТА"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      class="landing-section__pic effect-unit scale"
                      data-delay="1"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/1/1.webp')} 1x,
                ${require('@/assets/img/pic/ecology/1/1@2x.webp')} 2x`
                          "
                        />
                        <img
                          loading="lazy"
                          :src="require('@/assets/img/pic/ecology/1/1.png')"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/1/1.png')} 1x,
              ${require('@/assets/img/pic/ecology/1/1@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="ecology_echeck" class="landing-section effect-anchor">
            <Pattern
              v-if="screenSize === 'xs'"
              fill="#E6E5E4"
              direction="horizontal"
              rows="3"
              columns="6"
              offsetX="1"
              offsetY="1"
              patternTransform="rotate(90)"
            />
            <div class="landing-section__body">
              <div class="container">
                <div class="row row-reverse align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="landing-section__content">
                      <h3 class="h3 effect-unit" data-delay="1">
                        Электронные чеки
                      </h3>
                      <p class="effect-unit" data-delay="2">
                        Более 80&nbsp;000 покупателей &laquo;Пятёрочки&raquo;
                        получают электронные чеки на&nbsp;почту. Узнайте, как
                        получать отчёт о&nbsp;покупках на&nbsp;электронную
                        почту.
                      </p>
                      <div
                        class="landing-section__buttons effect-unit"
                        data-delay="3"
                      >
                        <Button
                          msg="ПОДРОБНЕЕ"
                          class="button_more_eco_1_6"
                          @click="setModalShow('ecology_2')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      class="landing-section__pic effect-unit scale"
                      data-delay="1"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/1/2.webp')} 1x,
                ${require('@/assets/img/pic/ecology/1/2@2x.webp')} 2x`
                          "
                        />
                        <img
                          loading="lazy"
                          :src="require('@/assets/img/pic/ecology/1/2.png')"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/1/2.png')} 1x,
              ${require('@/assets/img/pic/ecology/1/2@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="landing-section landing-section_color_light-green landing-section_theme_white landing-section_decor_white effect-anchor"
          >
            <Pattern
              v-if="screenSize === 'xs'"
              direction="horizontal"
              rows="3"
              columns="6"
              offsetX="1"
              offsetY="1"
              patternTransform="rotate(270)"
            />
            <Pattern
              v-else-if="screenSize === 'md'"
              rows="7"
              columns="3"
              offsetX="1"
              offsetY="1"
              patternTransform="rotate(0)"
            />
            <Pattern
              v-else
              offsetX="1"
              offsetY="1"
              patternTransform="rotate(270)"
              rows="4"
              columns="3"
            />
            <div class="landing-section__body">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="landing-section__content">
                      <h3 class="h3 effect-unit" data-delay="1">
                        Пункты приёма вторсырья в&nbsp;&laquo;Пятёрочке&raquo;
                      </h3>
                      <p class=" effect-unit" data-delay="2">
                        15&nbsp;тонн пэт-бутылок и&nbsp;алюминиевых банок,
                        а&nbsp;также 550 килограмм батареек отправили
                        на&nbsp;утилизацию наши гости в&nbsp;2020&nbsp;году.
                        Проверьте, есть&nbsp;ли пункты приёма вторсырья рядом
                        с&nbsp;вами?
                      </p>
                      <div
                        class="landing-section__buttons effect-unit"
                        data-delay="3"
                      >
                        <Button
                          class="button_color_white button_more_eco_1_7"
                          msg="ПОДРОБНЕЕ"
                          @click="setModalShow('ecology_3')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      class="landing-section__pic effect-unit scale"
                      data-delay="1"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/1/3.webp')} 1x,
                ${require('@/assets/img/pic/ecology/1/3@2x.webp')} 2x`
                          "
                        />
                        <img
                          loading="lazy"
                          :src="require('@/assets/img/pic/ecology/1/3.png')"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/1/3.png')} 1x,
              ${require('@/assets/img/pic/ecology/1/3@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="landing-section effect-anchor">
            <Pattern
              v-if="screenSize === 'xs'"
              fill="#E6E5E4"
              direction="horizontal"
              rows="3"
              columns="6"
              offsetX="0"
              offsetY="1"
              patternTransform="rotate(90)"
            />
            <div class="landing-section__body">
              <div class="container">
                <div class="row row-reverse align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="landing-section__content">
                      <h3 class="h3 effect-unit" data-delay="1">
                        Сбор тары на&nbsp;мероприятиях
                      </h3>
                      <p class=" effect-unit" data-delay="2">
                        В&nbsp;2020 году мы&nbsp;установили фандоматы для
                        раздельного сбора мусора на&nbsp;гонках
                        &laquo;Формулы&nbsp;1&raquo; и&nbsp;крупных городских
                        фестивалях. Гости площадок собрали 12&nbsp;000 пустых
                        бутылок и&nbsp;банок.
                      </p>
                      <div
                        class="landing-section__buttons effect-unit"
                        data-delay="3"
                      >
                        <Button
                          class="button_more_eco_1_8"
                          msg="ПОДРОБНЕЕ"
                          @click="setModalShow('ecology_4')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      class="landing-section__pic effect-unit scale"
                      data-delay="1"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/1/4.webp')} 1x,
                ${require('@/assets/img/pic/ecology/1/4@2x.webp')} 2x`
                          "
                        />
                        <img
                          loading="lazy"
                          :src="require('@/assets/img/pic/ecology/1/4.png')"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/1/4.png')} 1x,
              ${require('@/assets/img/pic/ecology/1/4@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="landing-section landing-section_color_blue landing-section_theme_white landing-section_decor_white effect-anchor"
          >
            <Pattern
              v-if="screenSize === 'xs'"
              fill="#73AFE3"
              direction="horizontal"
              rows="3"
              columns="6"
              offsetX="1"
              offsetY="1"
              patternTransform="rotate(90)"
            />
            <Pattern
              v-else-if="screenSize === 'md'"
              fill="#73AFE3"
              rows="7"
              columns="3"
              offsetX="0"
              offsetY="1"
              patternTransform="rotate(90)"
            />
            <Pattern
              v-else
              fill="#73AFE3"
              offsetX="0"
              offsetY="0"
              rows="4"
              columns="3"
            />
            <div class="landing-section__body">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="landing-section__content">
                      <h3 class="h3 effect-unit" data-delay="1">
                        Многоразовые экосумки для продуктов
                      </h3>
                      <p class=" effect-unit" data-delay="2">
                        Нашим покупателям нравятся многоразовые сумки-шопперы
                        и&nbsp;нейлоновые экомешочки для овощей и&nbsp;фруктов.
                        Узнайте, почему ходить за&nbsp;продуктами с&nbsp;такими
                        сумками не&nbsp;только модно, но&nbsp;и&nbsp;выгодно!
                      </p>
                      <div
                        class="landing-section__buttons effect-unit"
                        data-delay="3"
                      >
                        <Button
                          class="button_color_white button_more_eco_1_9"
                          msg="ПОДРОБНЕЕ"
                          @click="setModalShow('ecology_5')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      class="landing-section__pic effect-unit scale"
                      data-delay="1"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/1/5.webp')} 1x,
                ${require('@/assets/img/pic/ecology/1/5@2x.webp')} 2x`
                          "
                        />
                        <img
                          loading="lazy"
                          :src="require('@/assets/img/pic/ecology/1/5.png')"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/1/5.png')} 1x,
              ${require('@/assets/img/pic/ecology/1/5@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="view === `5ka`" class="ecology-5ka">
        <div class="ecology-sidebar">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-5">
                <button
                  type="button"
                  @click="goTo(null)"
                  class="ecology-sidebar__button ecology-sidebar__button_type_back"
                >
                  <i><span></span></i>
                  <span>Назад</span>
                </button>
              </div>
              <div class="col-7 ecology-sidebar__col-end">
                <button
                  type="button"
                  @click="goTo(`join`)"
                  class="ecology-sidebar__button button_more_eco_2_2"
                >
                  <span
                    >Ответственный подход<br />к&nbsp;будущему планеты
                    и&nbsp;общества</span
                  >
                  <i class="revert"><span></span></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="ecology-5ka__inner">
          <div
            class="landing-section landing-section_color_light-green landing-section_theme_white landing-section_decor_white effect-anchor"
          >
            <Pattern
              v-if="screenSize === 'xs'"
              direction="horizontal"
              rows="3"
              columns="6"
              offsetX="1"
              offsetY="1"
              patternTransform="rotate(180)"
            />
            <Pattern
              v-else-if="screenSize === 'md'"
              rows="7"
              columns="3"
              offsetX="0"
              offsetY="1"
              patternTransform="rotate(180)"
            />
            <Pattern v-else offsetX="0" offsetY="1" rows="4" columns="3" />
            <div
              class="landing-section__head landing-section__head_type_narrow"
            >
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div class="landing-section__content">
                      <p>
                        Инициативы &laquo;Пятёрочки&raquo; в&nbsp;области<br />
                        сохранения окружающей среды
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="landing-section__body">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="landing-section__content">
                      <h3 class="h3 effect-unit" data-delay="1">
                        Пищевые отходы
                      </h3>
                      <p class="effect-unit" data-delay="2">
                        У&nbsp;нас на&nbsp;полках&nbsp;&mdash; только самые
                        свежие продукты. Остальные перерабатываются в&nbsp;корма
                        для скота. Так мы&nbsp;заботимся о&nbsp;природе
                        и&nbsp;поддерживаем сельское хозяйство.
                      </p>
                      <div
                        class="landing-section__buttons effect-unit"
                        data-delay="3"
                      >
                        <Button
                          class="button_color_white button_more_eco_2_1"
                          msg="ПОДРОБНЕЕ"
                          @click="setModalShow('ecology_env_1')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      class="landing-section__pic effect-unit scale"
                      data-delay="1"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/2/1.webp')} 1x,
                ${require('@/assets/img/pic/ecology/2/1@2x.webp')} 2x`
                          "
                        />
                        <img
                          loading="lazy"
                          :src="require('@/assets/img/pic/ecology/2/1.png')"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/2/1.png')} 1x,
              ${require('@/assets/img/pic/ecology/2/1@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="landing-section effect-anchor">
            <Pattern
              v-if="screenSize === 'xs'"
              fill="#E6E5E4"
              direction="horizontal"
              rows="3"
              columns="6"
              offsetX="1"
              offsetY="1"
              patternTransform="rotate(90)"
            />
            <div class="landing-section__body">
              <div class="container">
                <div class="row row-reverse align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="landing-section__content">
                      <h3 class="h3 effect-unit" data-delay="1">
                        Взаимодействие с&nbsp;поставщиками и&nbsp;устойчивая
                        упаковка
                      </h3>
                      <p class=" effect-unit" data-delay="2">
                        Мы&nbsp;помогаем заботиться о&nbsp;природе
                        не&nbsp;только клиентам, но&nbsp;и&nbsp;поставщикам.
                        Поэтому совместно с&nbsp;экологами разработали для них
                        серию рекомендаций.
                      </p>
                      <div
                        class="landing-section__buttons effect-unit"
                        data-delay="3"
                      >
                        <Button
                          class="button_more_eco_2_3"
                          msg="ПОДРОБНЕЕ"
                          @click="setModalShow('ecology_env_2')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      class="landing-section__pic effect-unit scale"
                      data-delay="1"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/2/2.webp')} 1x,
                ${require('@/assets/img/pic/ecology/2/2@2x.webp')} 2x`
                          "
                        />
                        <img
                          loading="lazy"
                          :src="require('@/assets/img/pic/ecology/2/2.png')"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/2/2.png')} 1x,
              ${require('@/assets/img/pic/ecology/2/2@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="landing-section landing-section_color_green landing-section_theme_white landing-section_decor_white effect-anchor"
          >
            <Pattern
              v-if="screenSize === 'xs'"
              fill="#007F2D"
              direction="horizontal"
              rows="3"
              columns="6"
              patternTransform="rotate(270)"
            />
            <Pattern
              v-else-if="screenSize === 'md'"
              fill="#007F2D"
              rows="7"
              columns="3"
              offsetX="0"
              offsetY="1"
              patternTransform="rotate(270)"
            />
            <Pattern
              v-else
              fill="#007F2D"
              offsetX="1"
              offsetY="1"
              patternTransform="rotate(270)"
              rows="4"
              columns="3"
            />
            <div class="landing-section__body">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="landing-section__content">
                      <h3 class="h3 effect-unit" data-delay="1">
                        Многоразовая упаковка
                      </h3>
                      <p class=" effect-unit" data-delay="2">
                        Теперь для транспортировки товаров
                        из&nbsp;распределительного центра в&nbsp;магазины
                        используется многоразовая тара.
                      </p>
                      <div
                        class="landing-section__buttons effect-unit"
                        data-delay="3"
                      >
                        <Button
                          class="button_color_white button_more_eco_2_5"
                          msg="ПОДРОБНЕЕ"
                          @click="setModalShow('ecology_env_3')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      class="landing-section__pic effect-unit scale"
                      data-delay="1"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/2/3.webp')} 1x,
                ${require('@/assets/img/pic/ecology/2/3@2x.webp')} 2x`
                          "
                        />
                        <img
                          loading="lazy"
                          :src="require('@/assets/img/pic/ecology/2/3.png')"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/2/3.png')} 1x,
              ${require('@/assets/img/pic/ecology/2/3@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="landing-section effect-anchor">
            <Pattern
              v-if="screenSize === 'xs'"
              fill="#E6E5E4"
              direction="horizontal"
              rows="3"
              columns="6"
              offsetX="0"
              offsetY="0"
              patternTransform="rotate(90)"
            />
            <div class="landing-section__body">
              <div class="container">
                <div class="row row-reverse align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="landing-section__content">
                      <h3 class="h3 effect-unit" data-delay="1">
                        Пакеты и&nbsp;корзины из&nbsp;вторсырья
                      </h3>
                      <p class=" effect-unit" data-delay="2">
                        Наши новые пакеты на&nbsp;35% состоят
                        из&nbsp;переработанной упаковочной пленки, которую
                        собрали в&nbsp;распределительных центрах
                        &laquo;Пятёрочки&raquo;.<br />Покупательские корзины
                        во&nbsp;всех обновленных &laquo;Пятёрочках&raquo;
                        изготовлены из&nbsp;переработанного пластика.
                      </p>
                      <div
                        class="landing-section__buttons effect-unit"
                        data-delay="3"
                      >
                        <Button
                          class="button_more_eco_2_6"
                          msg="ПОДРОБНЕЕ"
                          @click="setModalShow('ecology_env_4')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      class="landing-section__pic effect-unit scale"
                      data-delay="1"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/2/4.webp')} 1x,
                ${require('@/assets/img/pic/ecology/2/4@2x.webp')} 2x`
                          "
                        />
                        <img
                          loading="lazy"
                          :src="require('@/assets/img/pic/ecology/2/4.png')"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/2/4.png')} 1x,
              ${require('@/assets/img/pic/ecology/2/4@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="landing-section landing-section_color_blue landing-section_theme_white landing-section_decor_white effect-anchor"
          >
            <Pattern
              v-if="screenSize === 'xs'"
              fill="#73AFE3"
              direction="horizontal"
              rows="3"
              columns="6"
              offsetX="0"
              offsetY="1"
              patternTransform="rotate(90)"
            />
            <Pattern
              v-else-if="screenSize === 'md'"
              fill="#73AFE3"
              rows="7"
              columns="3"
              offsetX="1"
              offsetY="1"
              patternTransform="rotate(180)"
            />
            <Pattern
              v-else
              fill="#73AFE3"
              offsetX="0"
              offsetY="0"
              rows="4"
              columns="3"
            />
            <div class="landing-section__body">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="landing-section__content">
                      <h3 class="h3 effect-unit" data-delay="1">
                        Умный магазин
                      </h3>
                      <p class=" effect-unit" data-delay="2">
                        Новая технология автономно контролирует работу
                        холодильного оборудования, освещения, тепловых завес,
                        вентиляции и&nbsp;кондиционеров. Экономия электричества
                        сохраняет не&nbsp;только деньги,
                        но&nbsp;и&nbsp;окружающую среду.
                      </p>
                      <div
                        class="landing-section__buttons effect-unit"
                        data-delay="3"
                      >
                        <Button
                          class="button_color_white button_more_eco_2_7"
                          msg="ПОДРОБНЕЕ"
                          @click="setModalShow('ecology_env_5')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      class="landing-section__pic effect-unit scale"
                      data-delay="1"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/2/5.webp')} 1x,
                ${require('@/assets/img/pic/ecology/2/5@2x.webp')} 2x`
                          "
                        />
                        <img
                          loading="lazy"
                          :src="require('@/assets/img/pic/ecology/2/5.png')"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/2/5.png')} 1x,
              ${require('@/assets/img/pic/ecology/2/5@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="landing-section effect-anchor">
            <div class="landing-section__body">
              <div class="container">
                <div class="row row-reverse align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="landing-section__content">
                      <h3 class="h3 effect-unit" data-delay="1">
                        Парниковые газы
                      </h3>
                      <p class=" effect-unit" data-delay="2">
                        Наша цель&nbsp;&mdash; на&nbsp;30% снизить выбросы
                        парниковых газов к&nbsp;2030&nbsp;году.
                      </p>
                      <div
                        class="landing-section__buttons effect-unit"
                        data-delay="3"
                      >
                        <Button
                          class="button_more_eco_2_8"
                          msg="ПОДРОБНЕЕ"
                          @click="setModalShow('ecology_env_6')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      class="landing-section__pic effect-unit scale"
                      data-delay="1"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/2/6.webp')} 1x,
                ${require('@/assets/img/pic/ecology/2/6@2x.webp')} 2x`
                          "
                        />
                        <img
                          loading="lazy"
                          :src="require('@/assets/img/pic/ecology/2/6.png')"
                          :srcset="
                            `${require('@/assets/img/pic/ecology/2/6.png')} 1x,
              ${require('@/assets/img/pic/ecology/2/6@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([Navigation]);

import Button from "@/components/Button.vue";
import Pattern from "@/components/Pattern.vue";

export default {
  name: "Ecology",
  components: {
    Swiper,
    SwiperSlide,
    Button,
    Pattern
  },
  data() {
    return {
      view: null
    };
  },
  watch: {
    view() {
      // вызываем событие анимации
      // при переключении раздела
      window.dispatchEvent(new Event("effect"));
    }
  },
  computed: {
    ...mapState(["screenSize"])
  },
  created() {
    this.scrollTo();
  },
  methods: {
    ...mapMutations(["setModalShow"]),
    onSwiper() {
      // пересчитываем высоту
      window.dispatchEvent(new Event("resize"));
    },
    resize() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 0);
    },
    goTo(view) {
      const el = document.getElementById("ecology");

      this.view = view;

      if (el) {
        // ждем середины анимации что бы проскроллить в начало
        setTimeout(() => {
          el.scrollIntoView();

          if (this.screenSize === "xs" && view === "join") {
            window.scrollBy(0, -54);
          }

          // ждем конца анимации что бы обновить размер секций
          setTimeout(() => {
            this.resize();
          }, 500);
        }, 500);
      }
    },
    scrollTo() {
      const url = new URL(window.location.href);
      const key = url.searchParams.get("s");
      if (key === "ecology_echeck") {
        this.view = "join";
        setTimeout(() => {
          document.getElementById("ecology_echeck").scrollIntoView();
          window.dispatchEvent(new Event("scroll"));
        }, 500);
      }
    }
  }
};
</script>
<style lang="scss">
@keyframes main {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  25% {
    transform: translateY(0);
    opacity: 1;
  }
  45% {
    transform: translateY(0);
    opacity: 1;
  }
  55% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

@keyframes join {
  0% {
    transform: translateX(-100%);
  }
  45% {
    transform: translateX(0);
  }
  55% {
    transform: translateX(0);
  }
  100% {
    transform: translateY(100%);
  }
}
@keyframes pka {
  0% {
    transform: translateX(100%);
  }
  45% {
    transform: translateX(0);
  }
  55% {
    transform: translateX(0);
  }
  100% {
    transform: translateY(100%);
  }
}
</style>
<style scoped lang="scss">
.ecology-transition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 50;
  pointer-events: none;
  transform: translateX(-100%);
  &_type {
    &_main {
      background-color: #00923a;
      &::before {
        position: absolute;
        top: 0;
        left: 85vw;
        width: 15vw;
        height: 100%;
        background-color: #98c21f;
        content: "";
        @include md {
          left: 50%;
          width: 50%;
        }
      }
    }
    &_join {
      background-color: #00923a;
    }
    &_5ka {
      background-color: #98c21f;
    }
  }
}

.ani-ecology-section-enter-active,
.ani-ecology-section-leave-active {
  transition: 0.5s;
}

.ecology-transition_type_main.ani-ecology-transition-enter-active {
  animation: main 1s ease-in-out;
}

.ecology-transition_type_join.ani-ecology-transition-enter-active {
  animation: join 1s ease-in;
}

.ecology-transition_type_5ka.ani-ecology-transition-enter-active {
  animation: pka 1s ease-in;
}

.ecology {
  position: relative;
  &-breed {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    &__item {
      width: 85vw;
      background-color: #98c21f;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      text-align: center;
      box-sizing: border-box;
      padding: 0 10px;
      @include md {
        width: 50%;
        padding: 0;
      }
      &_num_1 {
        background-color: #00923a;
        // .ecology-breed__pic {
        //   margin-top: calc(2vw + 10px);
        //   margin-bottom: calc(-2vw - 10px);
        //   @include md {
        //     margin-top: 29px;
        //     margin-bottom: -29px;
        //   }
        // }
      }
    }
    &__body {
      position: relative;
      display: grid;
      gap: 38px;
      z-index: 1;
      padding-bottom: 100px;
      @include md {
        gap: 48px;
      }
    }
    &__pic {
      display: flex;
      justify-content: center;
      img {
        display: block;
        width: calc(100% - 30px);
        margin: -10px auto;
        margin-top: 0;
        height: auto;
        @include md {
          width: auto;
          margin: 0;
        }
      }
    }
    &__title {
      color: #fff;
      margin: 0;
      margin-top: -30px;
      font-size: 5vw;
      @include md {
        font-size: 26px;
        margin-top: -40px;
      }
      @media (min-width: 1024px) {
        font-size: 32px;
      }
      @include lg {
        font-size: 36px;
      }
    }
    &__buttons {
      width: 220px;
      margin: 0 auto;
      @include md {
        width: auto;
        margin: 0;
      }
    }
  }
  &-breed-mobile {
    .ecology-breed__body {
      width: 100%;
    }
    // .ecology-breed__item::before {
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 24vw;
    //   background-image: url(../assets/img/pattern.png);
    //   background-repeat: repeat-x;
    //   background-size: 70vw auto;
    //   background-position: 50% 0;
    //   content: "";
    // }
    &__nav {
      @include reset-button;
      position: absolute;
      top: 33%;
      margin-top: -42px;
      width: 84px;
      height: 84px;
      z-index: 10;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='84' height='84' viewBox='0 0 84 84' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Ccircle cx='42' cy='36' r='22' fill='%23fff'/%3E%3C/g%3E%3Cpath d='M39 28l8 8-8 8' stroke='%23333' stroke-width='2' stroke-linecap='round'/%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0' width='84' height='84' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='6'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
      transform-origin: 50% 43%;
      left: 85vw;
      margin-left: -42px;
      transition: 0.15s;
      &-prev {
        left: 15vw;
        transform: rotate(180deg);
      }
      &.swiper-button-disabled {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
  &-sidebar {
    position: sticky;
    top: 54px;
    left: 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 54px;
    font-size: 10px;
    line-height: 1.2;
    z-index: 10;
    @media (min-width: 1024px) {
      height: 85px;
      top: 0;
      font-size: 16px;
    }
    &__col-end {
      display: flex;
      justify-content: flex-end;
    }
    &__button {
      @include reset-button;
      display: flex;
      align-items: center;
      text-align: left;
      @include gap(8px);
      max-width: 170px;
      @media (min-width: 1024px) {
        @include gap(15px);
        max-width: 100%;
      }
      span {
        font-weight: 900;
        max-width: 320px;
      }
      i {
        span {
          display: block;
          background-image: url("data:image/svg+xml,%0A%3Csvg width='40' height='12' viewBox='0 0 40 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 6L10 .2265v11.547L0 6zm40 1H9V5h31v2z' fill='%23333'/%3E%3C/svg%3E");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          width: 17px;
          height: 8px;
          @media (min-width: 1024px) {
            width: 40px;
            height: 12px;
          }
        }
        &.revert {
          margin: 0 !important;
          span {
            transform: rotate(180deg);
          }
        }
      }
      &_type_back {
        span {
          font-weight: normal;
        }
      }
    }
  }
  &-join {
    position: relative;
    z-index: 10;
    .landing-section__head {
      display: none;
      @include md {
        display: block;
      }
    }
  }
  &-5ka {
    position: relative;
    z-index: 10;
    .landing-section__head {
      display: none;
      @include md {
        display: block;
      }
    }
  }
}
</style>
<style lang="scss">
.ecology {
  .swiper-container {
    margin: 0 !important;
    padding: 0 !important;
    width: 100vw !important;
  }
  .swiper-wrapper {
    align-items: stretch;
    // padding: 0 7.5px;
  }
  .swiper-slide {
    height: auto;
    width: auto;
    // &:last-of-type {
    // padding-right: 15px;
    // }
  }
}
</style>
