<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <a
            class="footer__logo button_logo_5ka_footer"
            href="https://5ka.ru"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="@/assets/img/logo-slogan.svg"
              width="255"
              height="72"
              alt="Ждем в гости каждый день"
            />
          </a>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="footer__title">Мы в соцсетях!</div>
          <div class="footer__social">
            <ul>
              <li>
                <a
                  class="vk social_vk"
                  href="https://vk.com/club19098821"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Вконтакте"
                ></a>
              </li>
              <!-- <li>
                <a
                  class="in social_instagram"
                  href="https://www.instagram.com/pyaterochka/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Инстаграм"
                ></a>
              </li> -->
              <li>
                <a
                  class="ok social_ok"
                  href="https://ok.ru/pyaterochka"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Одноклассники"
                ></a>
              </li>
              <!-- <li>
                <a
                  class="fb social_fb"
                  href="https://www.facebook.com/pyaterochka/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Фейсбук"
                ></a>
              </li> -->
              <li>
                <a
                  class="yt social_Youtube"
                  href="https://www.youtube.com/channel/UC42Djx5cN5-A9b15lDGgVPw"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Ютуб"
                ></a>
              </li>
              <li>
                <a
                  class="tk social_tiktok"
                  href="https://www.tiktok.com/@pyaterochka?_d=secCgYIASAHKAESMgowxCQ8p7r7ka1xhjZAk703J9dbc91Xp8RqvhL5di7rUxHqn9uyJIwcU5Gu8n8yTL%2FaGgA%3D&language=ru&sec_uid=MS4wLjABAAAAkwgoZjnnBE1NASE8eHcXgbX3CscCwsI-nmxa9x427LdlfKJxvKE16y3ARtQImA0c&sec_user_id=MS4wLjABAAAAkwgoZjnnBE1NASE8eHcXgbX3CscCwsI-nmxa9x427LdlfKJxvKE16y3ARtQImA0c&share_app_name=musically&share_author_id=6670822654252023814&share_link_id=412e98f2-1412-4aa8-9b76-f7e7ba4d07f9&timestamp=1614584203&u_code=d594jkd4bki5ba&user_id=6670822654252023814&utm_campaign=client_share&utm_medium=android&utm_source=more&source=h5_m"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Тикток"
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-4">
          <div class="footer__title">
            Горячая линия
            <a class="button_phone" href="tel:8-800-555-55-05"
              >8-800-555-55-05</a
            >
          </div>
          <div class="footer__text">
            &copy;&nbsp;2021&nbsp;ООО &laquo;Агроторг&raquo;. Любое
            использование контента без письменного разрешения ООО
            &laquo;Агроторг&raquo; запрещено
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer {
  background-color: #333333;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 65px;
  position: relative;
  @include md {
    padding-top: 25px;
    padding-bottom: 140px;
  }
  @media (min-width: 1024px) {
    padding-left: 272px;
    @include sidebarMini {
      padding-left: 100px;
    }
  }
  .row {
    & > * {
      margin: 20px 0;
    }
    @include sm {
      & > * {
        margin: 15px 0;
      }
    }
  }
  &__logo {
    img {
      width: 230px;
      display: block;
      @include xl {
        width: auto;
      }
    }
  }
  &__social {
    margin-top: 7px;
    ul {
      @include reset-list;
      display: flex;
      li + li {
        margin-left: 14px;
      }
      li {
        a {
          display: block;
          width: 36px;
          height: 36px;
          background-size: contain;
          background-repeat: no-repeat;
          transition: 0.15s;
          &:hover {
            opacity: 0.8;
          }
          &.vk {
            background-image: url(../assets/img/icon/vk.svg);
          }
          &.in {
            background-image: url(../assets/img/icon/in.svg);
          }
          &.ok {
            background-image: url(../assets/img/icon/ok.svg);
          }
          &.fb {
            background-image: url(../assets/img/icon/fb.svg);
          }
          &.yt {
            background-image: url(../assets/img/icon/yt.svg);
          }
          &.tk {
            background-image: url(../assets/img/icon/tk.svg);
          }
        }
      }
    }
  }
  &__title {
    font-weight: 900;
    font-size: 18px;
    line-height: 126%;
    @include xl {
      font-size: 20px;
    }
    a {
      color: #00923a;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__text {
    opacity: 0.7;
    font-size: 12px;
    line-height: 140%;
    margin-top: 7px;
  }
}
</style>
