<template>
  <div v-if="kitShow" class="modal-kit">
    <p>Экология. Ответственный подход</p>
    <div class="modal-kit__group">
      <button @click="setModalShow('ecology_1')">1</button>
      <button @click="setModalShow('ecology_2')">2</button>
      <button @click="setModalShow('ecology_3')">3</button>
      <button @click="setModalShow('ecology_4')">4</button>
      <button @click="setModalShow('ecology_5')">5</button>
    </div>
    <p>Экология. Окружающая среда</p>
    <div class="modal-kit__group">
      <button @click="setModalShow('ecology_env_1')">1</button>
      <button @click="setModalShow('ecology_env_2')">2</button>
      <button @click="setModalShow('ecology_env_3')">3</button>
      <button @click="setModalShow('ecology_env_4')">4</button>
      <button @click="setModalShow('ecology_env_5')">5</button>
      <button @click="setModalShow('ecology_env_6')">6</button>
    </div>
    <p>Забота об обществе</p>
    <div class="modal-kit__group">
      <button @click="setModalShow('community_1')">1</button>
      <button @click="setModalShow('community_2')">2</button>
      <button @click="setModalShow('community_3')">3</button>
      <button @click="setModalShow('community_4')">4</button>
      <button @click="setModalShow('community_5')">5</button>
    </div>
    <p>Забота о здоровье</p>
    <div class="modal-kit__group">
      <button @click="setModalShow('health_1')">1</button>
      <button @click="setModalShow('health_2')">2</button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      kitShow: false
    };
  },
  created() {
    const paramsString = location.search;
    const searchParams = new URLSearchParams(paramsString);

    if (searchParams.has("kit")) {
      this.kitShow = true;
    }
  },
  methods: {
    ...mapMutations(["setModalShow"])
  }
};
</script>

<style lang="scss" scoped>
.modal-kit {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  padding: 15px;
  padding-top: 0;
  padding-bottom: 15px;
  p {
    font-size: 14px;
    margin: 0;
    margin-top: 10px;
  }
  button {
    font-size: 20px;
  }
  &__group {
    display: flex;
    gap: 10px;
  }
}
</style>
