<template>
  <transition name="modal">
    <Modal v-if="modalShow === 'ecology_env_1'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Пищевые отходы
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-8 offset-md-2">
                <ul
                  class="modal-feature modal-feature_icon_big modal-feature_align_center modal-feature_text_center_xs"
                >
                  <li class="icon-10">
                    <h4 class="color-inherit">
                      На&nbsp;наших полках&nbsp;&mdash; самые свежие продукты
                    </h4>
                  </li>
                  <li class="icon-11">
                    <h4 class="color-inherit">
                      Непригодные для продажи фрукты, овощи, мучные изделия
                      и&nbsp;бакалея = корм для скота
                    </h4>
                  </li>
                  <li class="icon-12">
                    <h4 class="color-inherit">
                      2,5 тысячи тонн продуктов уже переданы на&nbsp;фермы
                      за&nbsp;тестовый период
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_light-green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-8 offset-md-1">
                <div class="h3 mt-0">
                  Чтобы испорченных продуктов стало меньше:
                </div>
              </div>
            </div>
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-list">
                  <ol>
                    <li>
                      Моделируется спрос на&nbsp;основе анализа больших данных.
                    </li>
                    <li>Делается точный прогноз<br />заказа товаров.</li>
                    <li>
                      Частота поставок соответствует потребностям покупателей.
                    </li>
                    <li>
                      Маркируются продукты с&nbsp;истекающим сроком годности.
                    </li>
                    <li>Стоимость таких продуктов снижается на&nbsp;20-40%.</li>
                  </ol>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/1/1.webp')} 1x,
                          ${require('@/assets/img/pic/modal/ecology-2/1/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology-2/1/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/1/1.png')} 1x,
                          ${require('@/assets/img/pic/modal/ecology-2/1/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :next="{
            text: `Взаимодействие с&nbsp;поставщиками и&nbsp;устойчивая упаковка`,
            class: `button_nav_eco_2_3 button_next_eco_2_3`,
            id: `ecology_env_2`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'ecology_env_2'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Взаимодействие с&nbsp;поставщиками и&nbsp;устойчивая упаковка
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-8 offset-md-2">
                <ul
                  class="modal-feature modal-feature_icon_big modal-feature_align_center modal-feature_text_center_xs"
                >
                  <li class="icon-13">
                    <h4 class="color-inherit">
                      Сведения о&nbsp;добровольных экологической
                      и&nbsp;социальной сертификациях
                    </h4>
                  </li>
                  <li class="icon-14">
                    <h4 class="color-inherit">
                      Примеры материалов для&nbsp;перерабатываемой
                      или&nbsp;многоразовой упаковки
                    </h4>
                  </li>
                  <li class="icon-15">
                    <h4 class="color-inherit">
                      Советы по&nbsp;эргономичному<br />дизайну тары
                    </h4>
                  </li>
                  <li class="icon-16">
                    <h4 class="color-inherit">
                      Детальные рекомендации по&nbsp;устойчивой упаковке
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_light-green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-8 offset-md-2">
                <h3 class="h3 m-0">
                  Мы&nbsp;верим, что партнёры будут разделять наши ценности
                  и&nbsp;следовать этим рекомендациям.
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-6 offset-md-2">
                <h3 class="h3 h3_color_green mt-0">
                  Изучите все рекомендации &laquo;Пятёрочки&raquo;
                  для&nbsp;поставщиков
                </h3>
                <div class="modal-button">
                  <Button
                    tag="a"
                    class="button_more_eco_2_4"
                    link="https://esg.x5.ru/ru/programs/suppliers/"
                    msg="Смотреть рекомендации"
                  />
                  <div class="h3"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Пищевые отходы`,
            class: `button_nav_eco_2_1 button_prev_eco_2_1`,
            id: `ecology_env_1`
          }"
          :next="{
            text: `Многоразовая упаковка`,
            class: `button_nav_eco_2_5 button_next_eco_2_5`,
            id: `ecology_env_3`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'ecology_env_3'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Многоразовая упаковка
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col col-md-9 offset-md-1">
                <h3 class="h3 h3_color_green m-0">
                  Мы&nbsp;внедрили технологию бережливого производства
                  в&nbsp;поставки
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_light-green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/3/1.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology-2/3/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology-2/3/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/3/1.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology-2/3/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <h4 class="h4">
                  Переупаковать товары нужно, чтобы продукты на&nbsp;полках были
                  самыми свежими, а&nbsp;их&nbsp;цена не&nbsp;росла из-за
                  дорогой доставки.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-4 offset-md-1">
                <h4 class="h4 h4_color_green">
                  Многоразовая тара для&nbsp;транспортировки.
                </h4>
              </div>
              <div class="col-12 col-md-5">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/3/2.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology-2/3/2@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology-2/3/2.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/3/2.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology-2/3/2@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Взаимодействие с&nbsp;поставщиками и&nbsp;устойчивая упаковка`,
            class: `button_nav_eco_2_3 button_prev_eco_2_3`,
            id: `ecology_env_2`
          }"
          :next="{
            text: `Пакеты и&nbsp;корзины из&nbsp;вторсырья`,
            class: `button_nav_eco_2_6 button_next_eco_2_6`,
            id: `ecology_env_4`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'ecology_env_4'">
      <template v-slot:head>
        <h2
          class="modal-title modal-title_type_top modal-title_type_top_color align-center"
        >
          Пакеты и&nbsp;корзины из&nbsp;вторсырья
        </h2>
      </template>
      <template v-slot:body>
        <section
          class="modal-section modal-section_color_light-green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-4 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/4/1.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology-2/4/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology-2/4/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/4/1.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology-2/4/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <h3 class="h3 mt-0">
                  Наши корзины на&nbsp;100% состоят из&nbsp;переработанного
                  пластика, а&nbsp;пакеты на&nbsp;35%.*
                </h3>
                <p class="mb-0">*в магазинах новой концепции</p>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-6 offset-md-1">
                <h3 class="h3 h3_color_green mt-0">
                  Наши партнёры по&nbsp;переработке сырья для
                  пакетов&nbsp;&mdash;
                </h3>
                <h4 class="h4 mb-0">
                  предприятие по&nbsp;рециклингу пластиковой упаковки
                  &laquo;ЭкоТехнологии&raquo; и&nbsp;производитель упаковочных
                  материалов &laquo;Тверь Пак&raquo;.
                </h4>
              </div>
              <div class="col-12 col-md-3 offset-md-1">
                <div class="modal-pic__group modal-pic__group_special_modal">
                  <div class="modal-pic">
                    <picture>
                      <source
                        type="image/webp"
                        :srcset="
                          `${require('@/assets/img/pic/modal/ecology-2/4/2.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology-2/4/2@2x.webp')} 2x`
                        "
                        alt=""
                      />
                      <img
                        :src="
                          require('@/assets/img/pic/modal/ecology-2/4/2.png')
                        "
                        :srcset="
                          `${require('@/assets/img/pic/modal/ecology-2/4/2.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology-2/4/2@2x.png')} 2x`
                        "
                        alt=""
                      />
                    </picture>
                  </div>
                  <div class="modal-pic">
                    <picture>
                      <source
                        type="image/webp"
                        :srcset="
                          `${require('@/assets/img/pic/modal/ecology-2/4/3.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology-2/4/3@2x.webp')} 2x`
                        "
                        alt=""
                      />
                      <img
                        :src="
                          require('@/assets/img/pic/modal/ecology-2/4/3.png')
                        "
                        :srcset="
                          `${require('@/assets/img/pic/modal/ecology-2/4/3.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology-2/4/3@2x.png')} 2x`
                        "
                        alt=""
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-4 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/4/4.webp')} 1x,
                          ${require('@/assets/img/pic/modal/ecology-2/4/4@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology-2/4/4.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/4/4.png')} 1x,
                          ${require('@/assets/img/pic/modal/ecology-2/4/4@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <div class="modal-list modal-list_color_green">
                  <ol>
                    <li>
                      Полиэтиленовые отходы моются и&nbsp;обрабатываются при
                      высоких температурах.
                    </li>
                    <li>
                      Затем они превращаются в&nbsp;гранулы, из&nbsp;которых
                      производятся прочные и&nbsp;качественные пакеты.
                    </li>
                    <li>
                      Такую тару можно использовать несколько раз, а&nbsp;затем
                      снова переработать.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Многоразовая упаковка`,
            class: `button_nav_eco_2_5 button_prev_eco_2_5`,
            id: `ecology_env_3`
          }"
          :next="{
            text: `Умный магазин`,
            class: `button_nav_eco_2_7 button_next_eco_2_7`,
            id: `ecology_env_5`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'ecology_env_5'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Умный магазин
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col col-md-8 offset-md-1">
                <h3 class="h3 h3_color_green mt-0">
                  10% наших магазинов работают по&nbsp;технологии &laquo;Умный
                  магазин&raquo;.
                </h3>
                <h4 class="h4 col-md-10 mb-0">
                  Это новый стандарт для существующих и&nbsp;будущих
                  &laquo;Пятёрочек&raquo;.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_light-green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-4 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/5/1.webp')} 1x,
                        ${require('@/assets/img/pic/modal/ecology-2/5/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology-2/5/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/5/1.png')} 1x, 
                        ${require('@/assets/img/pic/modal/ecology-2/5/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <h4 class="h4 mt-0">
                  Датчики, контроллеры и&nbsp;программное обеспечение объединены
                  в&nbsp;систему, которая управляет техническими процессами
                  в&nbsp;магазинах:
                </h4>
                <div class="modal-list modal-list_align_center col-md-8">
                  <ol>
                    <li>Автоматические реклама и&nbsp;внешнее освещение.</li>
                    <li>
                      Дистанционное управление для холодильного оборудования.
                    </li>
                    <li>
                      Приборы собирают данные для анализа. Система рассчитывает
                      оптимальные тарифы, повышает эффективность и&nbsp;снижает
                      риск аварий.
                    </li>
                    <li>Светодиодное освещение.</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row row-reverse-xs align-items-center">
              <div class="col-12 col-md-4 offset-md-1">
                <h3 class="h3 h3_color_inherit">
                  Следующий этап:
                </h3>
                <p class="modal-text">
                  Тепловая завеса автоматически подстраивается под изменения
                  температуры воздуха в&nbsp;помещении и&nbsp;на&nbsp;улице.
                </p>
              </div>
              <div class="col-12 col-md-5 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/5/2.webp')} 1x,
                ${require('@/assets/img/pic/modal/ecology-2/5/2@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology-2/5/2.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/5/2.png')} 1x,
              ${require('@/assets/img/pic/modal/ecology-2/5/2@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <h2 class="h3 align-center mt-0">
                  Мы расходуем
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4 offset-md-1">
                <div class="modal-item">
                  <h3 class="h3 h3_color_green">
                    на&nbsp;4% меньше
                  </h3>
                  <h4 class="h4 mb-0">
                    электричества, чем в&nbsp;2018
                  </h4>
                </div>
              </div>
              <div class="col-12 col-md-4 offset-md-2">
                <div class="modal-item">
                  <h3 class="h3 h3_color_green">
                    на&nbsp;8% меньше
                  </h3>
                  <h4 class="h4 mb-0">
                    меньше, чем в&nbsp;2016
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_light-green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-9 offset-md-1">
                <h3 class="h3 m-0">
                  Сокращение потребления электроэнергии приведёт к&nbsp;снижению
                  выбросов CO2 в&nbsp;атмосферу.
                </h3>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Пакеты и&nbsp;корзины из&nbsp;вторсырья`,
            class: `button_nav_eco_2_6 button_prev_eco_2_6`,
            id: `ecology_env_4`
          }"
          :next="{
            text: `Парниковые газы`,
            class: `button_nav_eco_2_8 button_next_eco_2_8`,
            id: `ecology_env_6`
          }"
        />
      </template>
    </Modal>
  </transition>
  <transition name="modal">
    <Modal v-if="modalShow === 'ecology_env_6'">
      <template v-slot:head>
        <h2 class="modal-title modal-title_type_top align-center">
          Парниковые газы
        </h2>
      </template>
      <template v-slot:body>
        <section class="modal-section">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 offset-md-1">
                <div class="h4 h4_color_green modal-text m-0">
                  &laquo;Пятёрочка&raquo; присоединилась к&nbsp;международной
                  инициативе SBTi, которая направлена на&nbsp;защиту
                  от&nbsp;последствий изменения климата.
                </div>
              </div>
              <div class="col-12 col-md-3 offset-md-1">
                <div class="modal-pic">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/6/1.webp')} 1x, ${require('@/assets/img/pic/modal/ecology-2/6/1@2x.webp')} 2x`
                      "
                      alt=""
                    />
                    <img
                      :src="require('@/assets/img/pic/modal/ecology-2/6/1.png')"
                      :srcset="
                        `${require('@/assets/img/pic/modal/ecology-2/6/1.png')} 1x, ${require('@/assets/img/pic/modal/ecology-2/6/1@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="modal-section modal-section_color_light-green modal-section_theme_white"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 offset-md-4">
                <h3 class="h3 mt-0">Наши цели:</h3>
              </div>
              <div class="col-12 col-md-8 offset-md-2">
                <ul
                  class="modal-feature modal-feature_theme_white modal-feature_icon_big"
                >
                  <li class="icon-white-4">
                    <h4>
                      Декарбонизация и&nbsp;переход к&nbsp;низкоуглеродной
                      экономике
                    </h4>
                  </li>
                  <li class="icon-white-5">
                    <h4>Достижение нулевых углеродных выбросов</h4>
                  </li>
                  <li class="icon-white-6">
                    <h4>
                      Сдерживание роста температуры в&nbsp;пределах 1,5 градусов
                      по&nbsp;Цельсию по&nbsp;сравнению с&nbsp;доиндустриальным
                      уровнем
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section class="modal-section modal-section_color_grey">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-10 offset-md-1">
                <h2 class="h3 modal-item__title mt-0">
                  Инициатива Science Based Targets initiative (SBTi)
                </h2>
                <h4 class="h4 modal-item__title_h4">
                  1000 компаний по&nbsp;всему миру:
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-10 offset-md-1">
                <div class="modal-card__grid">
                  <div class="modal-card">
                    <div class="modal-card__pic">
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/modal/ecology-2/6/2.webp')} 1x, ${require('@/assets/img/pic/modal/ecology-2/6/2@2x.webp')} 2x`
                          "
                          alt=""
                        />
                        <img
                          :src="
                            require('@/assets/img/pic/modal/ecology-2/6/2.png')
                          "
                          :srcset="
                            `${require('@/assets/img/pic/modal/ecology-2/6/2.png')} 1x, ${require('@/assets/img/pic/modal/ecology-2/6/2@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                    <div class="modal-card__text">
                      Институт мировых ресурсов (WRI)
                    </div>
                  </div>
                  <div class="modal-card">
                    <div class="modal-card__pic">
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/modal/ecology-2/6/3.webp')} 1x, ${require('@/assets/img/pic/modal/ecology-2/6/3@2x.webp')} 2x`
                          "
                          alt=""
                        />
                        <img
                          :src="
                            require('@/assets/img/pic/modal/ecology-2/6/3.png')
                          "
                          :srcset="
                            `${require('@/assets/img/pic/modal/ecology-2/6/3.png')} 1x, ${require('@/assets/img/pic/modal/ecology-2/6/3@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                    <div class="modal-card__text">
                      Всемирный фонд дикой природы (WWF)
                    </div>
                  </div>
                  <div class="modal-card">
                    <div class="modal-card__pic">
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/modal/ecology-2/6/4.webp')} 1x, ${require('@/assets/img/pic/modal/ecology-2/6/4@2x.webp')} 2x`
                          "
                          alt=""
                        />
                        <img
                          :src="
                            require('@/assets/img/pic/modal/ecology-2/6/4.png')
                          "
                          :srcset="
                            `${require('@/assets/img/pic/modal/ecology-2/6/4.png')} 1x, ${require('@/assets/img/pic/modal/ecology-2/6/4@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                    <div class="modal-card__text">
                      Проект по&nbsp;снижению уровня выбросов углерода (CDP)
                    </div>
                  </div>
                  <div class="modal-card">
                    <div class="modal-card__pic">
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/modal/ecology-2/6/5.webp')} 1x, ${require('@/assets/img/pic/modal/ecology-2/6/5@2x.webp')} 2x`
                          "
                          alt=""
                        />
                        <img
                          :src="
                            require('@/assets/img/pic/modal/ecology-2/6/5.png')
                          "
                          :srcset="
                            `${require('@/assets/img/pic/modal/ecology-2/6/5.png')} 1x, ${require('@/assets/img/pic/modal/ecology-2/6/5@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                    <div class="modal-card__text">
                      Глобальный договор Организации Объединенных Наций (ООН)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:foot>
        <ModalFooter
          :prev="{
            text: `Умный магазин`,
            class: `button_nav_eco_2_7 button_prev_eco_2_7`,
            id: `ecology_env_5`
          }"
        />
      </template>
    </Modal>
  </transition>
</template>

<script>
import { mapState } from "vuex";

import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import ModalFooter from "@/components/ModalFooter.vue";

export default {
  components: {
    Button,
    Modal,
    ModalFooter
  },
  computed: {
    ...mapState(["modalShow"])
  }
};
</script>
