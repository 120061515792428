// брейкпоинты,
export const breakpoints = {
  sm: 0,
  md: 768,
  lg: 1200,
  xl: 1440
};

export const mediaUrl = `${process.env.VUE_APP_BASE_API}${
  process.env.VUE_APP_MEDIA_DIR ? process.env.VUE_APP_MEDIA_DIR : "/media"
}/`;

export const imageEndpoint = `${process.env.VUE_APP_BASE_API}/api/public/image/`;

export function validateStatus(status) {
  return status >= 200 && status < 300;
}

export function watchScreenResize(store) {
  function onResize() {
    const width = window.innerWidth;

    if (width >= breakpoints.xl) {
      store.commit("setScreenSize", "xl");
    } else if (width >= breakpoints.lg && width < breakpoints.xl) {
      store.commit("setScreenSize", "lg");
    } else if (width >= breakpoints.md && width < breakpoints.lg) {
      store.commit("setScreenSize", "md");
    } else {
      store.commit("setScreenSize", "xs");
    }
  }

  window.addEventListener("resize", onResize, false);
}
