import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import "./registerServiceWorker";
import store from "./store";
import { watchScreenResize } from "./utils";
import "focus-visible";
import mixin from "./mixins/device";
import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill!
smoothscroll.polyfill();

watchScreenResize(store);

createApp(App)
  .use(store)
  .use(router)
  .use(Vue => {
    // Assign a unique id to each component
    let uuid = 0;
    Vue.mixin({
      beforeCreate: function() {
        this.uuid = uuid.toString();
        uuid += 1;
      }
    });
  })
  .mixin(mixin)
  .mount("#app");
