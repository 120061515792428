<template>
  <picture>
    <source
      type="image/webp"
      :srcset="`${imgSrc('webp')} 1x, ${imgSrc('webp', 2)} 2x`"
    />
    <img
      :src="imgSrc()"
      :srcset="`${imgSrc()} 1x, ${imgSrc(null, 2)} 2x`"
      :loading="loading"
      :alt="alt"
    />
  </picture>
</template>

<script>
import { imageEndpoint } from "@/utils";

export default {
  name: "Picture",
  props: {
    image: String,
    extension: {
      type: String
    },
    width: Number,
    height: Number,
    loading: String,
    alt: {
      type: String,
      default: ""
    }
  },
  methods: {
    extractUUID(url) {
      return /((\w{4,12}-?)){5}/.exec(url)[0];
    },
    imgSrc(extension, scale = 1) {
      return `${imageEndpoint}${this.extractUUID(this.image)}${
        this.width ? "?width=" + this.width * scale : "?"
      }${this.height ? "&height=" + this.height * scale : ""}${
        extension
          ? "&image_ext=." + extension
          : this.extension
          ? "&image_ext=." + this.extension
          : ""
      }`;
    }
  }
};
</script>
