<template>
  <div
    class="landing-section landing-section_color_orange landing-section_theme_white effect-anchor"
  >
    <template v-if="screenSize === 'xs'" />
    <Pattern
      v-else
      fill="#FF9C1C"
      direction="horizontal"
      position="bottom"
      rows="2"
      columns="10"
    />
    <div class="landing-section__head">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="h2 landing-section__title effect-unit" data-delay="1">
              Забота об обществе
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="landing-section__body">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="community effect-unit" data-delay="2">
              <button
                type="button"
                class="swiper-nav swiper-nav_theme_white swiper-nav-prev effect-unit scale"
                data-delay="5"
                aria-label="Предыдущий"
              ></button>
              <button
                type="button"
                class="swiper-nav swiper-nav_theme_white swiper-nav-next effect-unit scale"
                data-delay="5"
                aria-label="Следующий"
              ></button>
              <swiper
                v-if="show"
                class="swiper-container_theme_white"
                :slides-per-view="3"
                :space-between="50"
                :navigation="{
                  nextEl: '.community .swiper-nav-next',
                  prevEl: '.community .swiper-nav-prev'
                }"
                :pagination="{ clickable: true }"
                :loop="true"
                :breakpoints="breakpoints"
                @swiper="onSwiper"
              >
                <swiper-slide
                  v-for="(item, index) in slides"
                  :key="index"
                  :virtualIndex="index"
                >
                  <div
                    class="community__item effect-unit slide"
                    :data-delay="index + 2"
                  >
                    <div class="community__pic">
                      <picture>
                        <source
                          type="image/webp"
                          :srcset="
                            `${require('@/assets/img/pic/social/' +
                              item.pic +
                              '.webp')} 1x,
                            ${require('@/assets/img/pic/social/' +
                              item.pic +
                              '@2x.webp')} 2x`
                          "
                        />
                        <img
                          loading="lazy"
                          :src="
                            require('@/assets/img/pic/social/' +
                              item.pic +
                              '.png')
                          "
                          :srcset="
                            `${require('@/assets/img/pic/social/' +
                              item.pic +
                              '.png')} 1x,
                            ${require('@/assets/img/pic/social/' +
                              item.pic +
                              '@2x.png')} 2x`
                          "
                          alt=""
                        />
                      </picture>
                    </div>
                    <div class="community__body">
                      <div class="community__group">
                        <div class="community__title" v-html="item.title"></div>
                        <div class="community__text" v-html="item.text"></div>
                      </div>
                      <div class="community__button">
                        <Button
                          v-if="item.link"
                          tag="a"
                          class="button_size_small"
                          :class="`button_more_soc_${index + 1}`"
                          :link="item.link"
                          msg="ПОДРОБНЕЕ"
                        />
                        <Button
                          v-else
                          class="button_size_small"
                          :class="`button_more_soc_${index + 1}`"
                          msg="ПОДРОБНЕЕ"
                          @click="setModalShow(item.modal)"
                        />
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import SwiperCore, { Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([Virtual, Navigation, Pagination]);

import Button from "@/components/Button.vue";
import Pattern from "@/components/Pattern.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    Button,
    Pattern
  },
  name: "Community",
  data() {
    return {
      slides: [
        {
          modal: "community_1",
          pic: 1,
          title: "Островки безопасности",
          text:
            "Вместе с&nbsp;поисково-спасательным отрядом &laquo;ЛизаАлерт&raquo; мы&nbsp;помогаем тем, кто потерялся. Посмотрите, что делать, когда вам или вашим близким нужна помощь."
        },
        {
          modal: "community_2",
          pic: 2,
          title: "Скидки для пенсионеров и&nbsp;покупателей с&nbsp;детьми",
          text:
            "Мы&nbsp;заботимся о&nbsp;старшем поколении и&nbsp;верим в&nbsp;семейные ценности. В&nbsp;наших магазинах пенсионеры и&nbsp;покупатели с&nbsp;детьми получают постоянную скидку."
        },
        {
          modal: "community_3",
          // link: "https://korzina.5ka.ru",
          pic: 3,
          title: "Корзина доброты",
          text:
            "Продовольственная помощь для нуждающихся людей и&nbsp;бездомных животных. Узнайте, как сделать пожертвование или стать волонтёром."
        },
        {
          modal: "community_4",
          pic: 4,
          title: "Конфетка доброты",
          text:
            "Пять рублей с&nbsp;каждого проданного леденца &laquo;Конфетка доброты&raquo; перечисляются на&nbsp;операции подопечным Благотворительного Фонда &laquo;Линия Жизни&raquo;."
        },
        {
          modal: "community_5",
          pic: 5,
          title: "Городская среда",
          text:
            "Мы&nbsp;построили восемь спортивных площадок во&nbsp;дворах Липецка. Так &laquo;Пятёрочка&raquo; поддерживает желание покупателей вести здоровый образ жизни. Проверьте, есть&nbsp;ли ваш адрес в&nbsp;программе?"
        },
        {
          modal: "community_6",
          pic: 6,
          title: "Новые места<br>для&nbsp;отдыха",
          text:
            "Мы&nbsp;благоустроили два сквера рядом с&nbsp;магазинами &laquo;Пятёрочка&raquo; в&nbsp;Белгороде и&nbsp;организовали там зоны отдыха. Это наша забота о&nbsp;жителях и&nbsp;гостях города. Найдите адреса новых площадок на&nbsp;карте."
        }
      ],
      breakpoints: {
        0: {
          slidesPerView: "auto",
          spaceBetween: 0,
          loop: false
        },
        768: {
          slidesPerGroup: 2,
          slidesPerView: 2,
          spaceBetween: 0,
          loop: true
        },
        1200: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          spaceBetween: 0,
          loop: true
        }
      },
      show: true
    };
  },
  watch: {
    screenSize() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  computed: {
    ...mapState(["screenSize"])
  },
  methods: {
    ...mapMutations(["setModalShow"]),
    onSwiper() {
      // пересчитываем высоту
      window.dispatchEvent(new Event("resize"));
    }
  }
};
</script>

<style scoped lang="scss">
.landing-section {
  position: relative;
}
.community {
  position: relative;
  padding-top: 20px;
  @include md {
    padding-top: 0px;
  }
  &__item {
    background: #ffffff;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
    border-radius: 23px;
    text-align: center;
    color: #333;
    margin: 0 15px;
    max-width: calc(83vw - 20px);
  }
  &__body {
    padding: 15px;
    height: 280px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include md {
      padding: 20px 25px 36px;
      height: 304px;
    }
  }
  &__pic {
    height: 180px;
    background: #f6f5f4;
    border-radius: 23px 23px 0px 0px;
    @include md {
      height: 270px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &__group {
    height: 174px;
    @include md {
      height: auto;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 18px;
    line-height: 1.2;
    @include md {
      font-size: 20px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 148%;
    margin-top: 12px;
  }
  &__button {
    text-align: center;
    position: relative;
    &::before {
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 50px;
      pointer-events: none;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.8) 60%,
        rgba(255, 255, 255, 0) 100%
      );
      content: "";
    }
  }
}
</style>

<style lang="scss">
.community {
  .swiper-wrapper {
    align-items: stretch;
    // padding: 0 7.5px;
  }
  .swiper-slide {
    height: auto;
    width: auto;
    &:last-of-type {
      padding-right: 0px;
    }
  }
}
</style>
