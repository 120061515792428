<template>
  <div class="modal-container">
    <ModalKit />

    <ModalGroupEcologyFuture />
    <ModalGroupEcologyEnv />
    <ModalGroupCommunity />
    <ModalGroupHealth />
    <ModalGroupVideo />

    <transition name="modal">
      <Modal v-if="modalShow === 'partners'">
        <template v-slot:head>
          <h2
            class="modal-title modal-title_type_top align-center"
            v-html="modalData.popup_header"
          ></h2>
        </template>
        <template v-slot:body>
          <section class="modal-section">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 col-md-4 offset-md-1">
                  <div v-if="modalData.popup_preview" class="modal-pic">
                    <picture>
                      <img
                        :src="baseMediaUrl + modalData.popup_preview"
                        alt=""
                      />
                    </picture>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div
                    class="modal-wysiwyg"
                    v-html="modalData.popup_description"
                  ></div>
                </div>
              </div>
            </div>
          </section>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import ModalKit from "@/components/ModalKit.vue";
import Modal from "@/components/Modal.vue";
import ModalGroupEcologyFuture from "@/components/ModalGroupEcologyFuture.vue";
import ModalGroupEcologyEnv from "@/components/ModalGroupEcologyEnv.vue";
import ModalGroupCommunity from "@/components/ModalGroupCommunity.vue";
import ModalGroupHealth from "@/components/ModalGroupHealth.vue";
import ModalGroupVideo from "@/components/ModalGroupVideo.vue";

export default {
  name: "ModalGroup",
  components: {
    ModalKit,
    Modal,
    ModalGroupEcologyFuture,
    ModalGroupEcologyEnv,
    ModalGroupCommunity,
    ModalGroupHealth,
    ModalGroupVideo
  },
  watch: {
    modalShow(newVal) {
      if (newVal) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    }
  },
  computed: {
    ...mapState(["modalShow", "modalData"]),
    baseMediaUrl() {
      return `${process.env.VUE_APP_BASE_API}/media/`;
    }
  },
  created() {
    this.checkModalShow();
  },
  methods: {
    ...mapMutations(["setModalShow"]),
    checkModalShow() {
      const url = new URL(window.location.href);
      const key = url.searchParams.get("modalShow");

      if (key) {
        this.setModalShow(key);
      }
    }
  }
};
</script>

<style lang="scss">
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  &-wysiwyg {
    > * {
      @include md-modal {
        margin-top: 0;
      }
    }
    font-size: 24px;
    line-height: 140%;
    a {
      color: #00923a;
    }
    ul,
    ol {
      padding: 0;
      padding-left: 25px;
      li {
        margin-top: 24px;
        font-size: 18px;
        line-height: 160%;
      }
    }
    ul {
      list-style: none;
      li {
        position: relative;
        &::before {
          position: absolute;
          top: 10px;
          left: 0;
          margin-left: -25px;
          width: 10px;
          height: 10px;
          background: #00923a;
          border-radius: 10px;
          content: "";
        }
      }
    }
  }
  &-container {
    position: relative;
    z-index: 2000;
  }
  &-video {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    background-color: #000;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &-prev,
    &-next {
      @include reset-button;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='27' height='41' viewBox='0 0 27 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.0002 20.5L20.8254 41l5.2063-5.125L10.4128 20.5 26.0317 5.125 20.8254 0 .0002 20.5z' fill='%23fff'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      position: absolute;
      opacity: 0.8;
      transition: 0.15s;
      top: 100%;
      margin-top: 20px;
      width: 30px;
      height: 30px;
      background-size: auto 30px;
      @media (min-width: 992px) {
        width: 50px;
        height: 50px;
        top: 50%;
        margin: 0 10px;
        margin-top: -25px;
        background-size: 27px 41px;
      }
      &:hover {
        transform: scale(1.1);
        opacity: 1;
      }
    }
    &-prev {
      left: 0;
      @media (min-width: 992px) {
        left: auto;
        right: 100%;
      }
    }
    &-next {
      transform: rotate(180deg);
      right: 0;
      @media (min-width: 992px) {
        left: 100%;
        right: auto;
      }
      &:hover {
        transform: rotate(180deg) scale(1.1);
      }
    }
  }
  &-title {
    font-weight: 900;
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 24px;
    @include md {
      font-size: 36px;
    }
    @include md-modal {
      font-size: 52px;
    }
    &_type_top {
      margin: 0;
      padding-top: 24px;
      padding-bottom: 0px;
      margin-bottom: -20px;
      text-align: left !important;
      @include md {
        margin-bottom: 0px;
        text-align: center !important;
        padding-top: 72px;
        // padding-bottom: 72px;
        // padding-bottom: 0px;
      }
      &_color {
        margin-bottom: 0;
        padding-bottom: 24px;
        @include md {
          padding-bottom: 72px;
        }
      }
    }
  }
  &-text {
    &_type_top {
      // padding-bottom: 18px;
      @include md {
        padding-bottom: 36px;
      }
    }
    &_type_pic {
      margin-bottom: 24px;
    }
    a {
      color: #00923a;
    }
  }
  &-pic {
    @include md-modal {
      margin: -32px 0;
    }
    &__group {
      display: grid;
      gap: 40px;
      // padding: 32px 0;
      .modal-pic {
        @include md-modal {
          margin: 0;
        }
      }
      &_special_modal {
        padding: 36px 20px;
        @include md-modal {
          padding: 0;
        }
      }
    }
    img {
      width: 100%;
      display: block;
      max-width: 360px;
      margin: 0 auto;
      @include md {
        max-width: 100%;
      }
    }
  }
  &-section {
    padding: 32px 0;
    @include md {
      padding: 72px 0;
    }
    &_nth {
      &_first {
        @include md {
          padding-top: 32px;
        }
      }
    }
    &_color {
      &_light-green {
        background: radial-gradient(
            48.29% 107.2% at 0% 50%,
            rgba(255, 255, 255, 0.3) 0%,
            rgba(255, 255, 255, 0) 100%
          ),
          #98c21f;
        background-blend-mode: overlay, normal;
      }
      &_green {
        background: radial-gradient(
            51.71% 114.8% at 100% 50%,
            rgba(255, 255, 255, 0.3) 0%,
            rgba(255, 255, 255, 0) 100%
          ),
          #00923a;
        background-blend-mode: overlay, normal;
      }
      &_grey {
        background: #f6f5f4;
      }
    }
    &_theme {
      &_white {
        color: #fff;
      }
    }
  }
  &-button {
    @include md-modal {
      margin-top: 48px;
    }
  }
  &-list {
    margin-left: 64px;
    margin-top: 24px;
    margin-bottom: 24px;
    ol {
      @include reset-list;
      margin-top: 15px;
      li {
        + li {
          margin-top: 24px;
        }
        position: relative;
        counter-increment: inst;
        font-size: 16px;
        line-height: 160%;
        display: flex;
        min-height: 60px;
        align-items: center;
        a {
          color: inherit;
        }
        @include md {
          font-size: 18px;
        }
        &::before {
          position: absolute;
          margin-left: -64px;
          height: 40px;
          width: 40px;
          top: 10px;
          border-radius: 40px;
          box-sizing: border-box;
          border: 3px solid #ffffff;
          font-weight: 900;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          content: counter(inst);
          box-sizing: border-box;
        }
      }
    }
    &_align_center {
      li {
        display: flex;
        align-items: center;
        min-height: 60px;
      }
    }
    &_color_green {
      ol {
        li {
          &::before {
            border-color: #00923a;
            color: #00923a;
          }
        }
      }
    }
  }
  &-item {
    &__title {
      text-align: left !important;
      margin-bottom: 5px !important;
      &_h4 {
        text-align: left !important;
      }
      @include md {
        margin-bottom: -20px !important;
        text-align: center !important;
        &_h4 {
          text-align: center !important;
        }
      }
    }
    @include md-modal {
      // padding: 32px 0;
    }
    .h3 {
      margin-bottom: 0;
    }
    .h4 {
      margin-top: 8px;
    }
  }
  &-feature {
    list-style: none;
    padding: 0;
    display: grid;
    gap: 60px 30px;
    li {
      font-weight: 900;
      font-size: 16px;
      padding-left: 85px;
      min-height: 85px;
      @include md-modal {
        min-height: 95px;
        padding-left: 95px;
        font-size: 24px;
        line-height: 130%;
      }
      &::before {
        position: absolute;
        content: "";
        width: 75px;
        height: 75px;
        margin-left: -85px;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        @include md-modal {
          margin-left: -95px;
          width: 87px;
          height: 87px;
        }
      }
      h3 {
        font-weight: 900;
        font-size: 30px;
        line-height: 36px;
        color: #00923a;
        margin: 0;
        margin-bottom: 8px;
        @include md-modal {
          font-size: 52px;
          line-height: 63px;
        }
      }
      h4 {
        font-weight: 900;
        font-size: 18px;
        line-height: 130%;
        color: #00923a;
        margin: 0;
        @include md-modal {
          font-size: 36px;
          line-height: 43px;
        }
      }
      &.icon {
        @for $i from 1 through 27 {
          &-#{$i}::before {
            background-image: url(../assets/img/icon/feature/#{$i}.svg);
          }
        }
        @for $i from 1 through 12 {
          &-white-#{$i}::before {
            background-image: url(../assets/img/icon/feature/white/#{$i}.svg);
          }
        }
      }
    }
    &_column {
      &_2 {
        @include md-modal {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    &_icon {
      &_middle {
        li {
          display: flex;
          align-items: center;
          position: relative;
          padding-top: 0;
          padding-left: 100px;
          min-height: 85px !important;
          @include md-modal {
            min-height: 95px !important;
            padding-left: 115px;
          }
          &::before {
            top: 0;
            margin-top: 0;
            left: inherit;
            width: 85px;
            height: 85px;
            margin-left: -100px;
            @include md-modal {
              width: 95px;
              height: 95px;
              margin-left: -115px;
            }
          }
          h4 {
            word-break: break-word;
            @include md-modal {
              word-break: initial;
            }
          }
        }
      }
      &_big {
        li {
          padding-left: 0;
          padding-top: 150px;
          @include md-modal {
            display: flex;
            align-items: center;
            position: relative;
            padding-top: 0;
            padding-left: 185px;
            min-height: 160px;
          }
          &::before {
            width: 140px;
            height: 140px;
            margin-left: 0;
            margin-top: -150px;
            left: 50%;
            margin-left: -70px;
            @include md-modal {
              top: 0;
              margin-top: 0;
              left: inherit;
              width: 160px;
              height: 160px;
              margin-left: -185px;
            }
          }
        }
      }
    }
    &_theme {
      &_white {
        li {
          h3,
          h4 {
            color: inherit;
          }
        }
      }
    }
    &_align_center {
      li {
        @include md-modal {
          min-height: 160px;
          display: flex;
          align-items: center;
        }
      }
    }
    &_text_center_xs {
      text-align: center;
      @include md-modal {
        text-align: left;
      }
    }
  }
  &-card {
    background: #ffffff;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
    border-radius: 23px;
    text-align: center;
    padding: 12px 30px;
    @include md-modal {
      padding: 12px 40px;
    }
    &__grid {
      display: grid;
      gap: 20px;
      @include md {
        gap: 30px;
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &__pic {
    }
    &__icon {
      width: 144px;
      height: 144px;
      margin: auto;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      @for $i from 1 through 6 {
        &_#{$i} {
          background-image: url(../assets/img/icon/grid/#{$i}.svg);
        }
      }
    }
    &__text {
      margin-top: 3px;
      margin-bottom: 8px;
      font-weight: 900;
    }
  }
  &-map {
    &__head {
      display: flex;
    }
    &__tab {
      @include reset-button;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-bottom: none;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
      border-radius: 23px 23px 0px 0px;
      font-size: 16px;
      color: rgb(51 51 51 / 60%);
      width: calc(50% + 1px);
      max-width: 188px;
      height: 50px;
      line-height: 53px;
      display: flex;
      justify-content: center;
      margin-right: -1px;
      position: relative;
      @include md-modal {
        font-size: 18px;
        height: 63px;
        line-height: 66px;
      }
      &_state_active {
        font-weight: 900;
        color: #00923a;
        z-index: 1;
      }
    }
    &__body {
      position: relative;
      height: 0;
      z-index: 2;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
      padding-bottom: 120%;
      border-radius: 0px 0 23px 23px;
      @include md-modal {
        padding-bottom: 50%;
        border-radius: 0px 23px 23px 23px;
      }
      overflow: hidden;
      iframe {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-slider {
    position: relative;
    padding-top: 36px;
    &__slide {
      width: auto !important;
      height: auto !important;
    }
    &__item {
      background: #ffffff;
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
      border-radius: 23px;
      padding: 10px;
      text-align: center;
      width: 188px;
      height: 100%;
      box-sizing: border-box;
      @include md-modal {
        width: 254px;
      }
    }
    &__icon {
      display: block;
      width: 110px;
      height: 110px;
      margin: auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      @for $i from 1 through 9 {
        &_#{$i} {
          background-image: url(../assets/img/icon/product/#{$i}.svg);
        }
      }
      @include md-modal {
        width: 148px;
        height: 148px;
      }
    }
    &__text {
      padding: 5px 5px;
      padding-top: 9px;
      font-size: 14px;
      line-height: 160%;
      @include md-modal {
        padding: 15px 5px;
      }
    }
    .swiper-container {
      @media (min-width: 900px) {
        padding-bottom: 84px;
      }
    }
    .swiper-pagination {
      @media (min-width: 900px) {
        left: 5px;
        bottom: 8px;
        text-align: left;
      }
    }
    .swiper-pagination-bullets {
      left: 13px;
      @include md {
        left: calc(8.333333% + 10px);
      }
    }
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 3px;
      @include md {
        margin: 0 6px;
        width: 12px;
        height: 12px;
      }
      @media (min-width: 900px) {
        margin: 0 8px;
        width: 16px;
        height: 16px;
      }
    }
    .swiper-nav-prev {
      right: 30px;
      @include md {
        right: calc(8.333333% + 40px);
      }
      @media (min-width: 900px) {
        top: auto;
        bottom: 0;
        right: calc(8.333333% + 50px);
        left: auto;
      }
    }
    .swiper-nav-next {
      right: -10px;
      @include md {
        right: calc(8.333333% - 10px);
      }
      @media (min-width: 900px) {
        top: auto;
        bottom: 0;
      }
    }
  }
}

.row-reverse-xs {
  flex-direction: column-reverse;
  @include md {
    flex-direction: row;
  }
}
</style>
