<template>
  <div class="landing-section landing-section_decor_dark effect-anchor">
    <div class="landing-section__head">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="h2 landing-section__title effect-unit" data-delay="1">
              Безопасность
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8">
            <h4
              class="h4 landing-section__subtitle landing-section__subtitle_place_head effect-unit"
              data-delay="2"
            >
              Ограничения, которые принёс коронавирус, не&nbsp;изменят наше
              отношение к&nbsp;покупателям. Мы&nbsp;ежедневно заботимся
              о&nbsp;безопасности гостей и&nbsp;делаем всё, чтобы вам было
              комфортно совершать покупки.
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="landing-group">
    <div
      class="landing-section landing-section_decor_dark landing-section_color_grey effect-anchor"
    >
      <Pattern
        v-if="screenSize === 'xs'"
        fill="#E6E5E4"
        direction="horizontal"
        rows="3"
        columns="6"
        offsetX="0"
        offsetY="1"
        patternTransform="rotate(270)"
      />
      <Pattern
        v-else-if="screenSize === 'md'"
        fill="#E6E5E4"
        offsetX="1"
        offsetY="0"
        rows="6"
        columns="3"
        patternTransform="rotate(90)"
      />
      <Pattern v-else fill="#E6E5E4" offsetY="1" rows="4" columns="3" />
      <div class="landing-section__body">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-md-6">
              <div class="landing-section__content">
                <h3 class="h3 h3_color_green effect-unit" data-delay="2">
                  Контроль за&nbsp;дезинфекцией и&nbsp;безопасностью
                </h3>
                <p class="effect-unit" data-delay="3">
                  Мы&nbsp;разместили залы так, чтобы вам было проще соблюдать
                  социальную дистанцию. Мы&nbsp;верим, что наши покупатели
                  разделяют осознанный подход к&nbsp;рекомендациям. Вместе
                  мы&nbsp;справимся с&nbsp;любыми ограничениями!
                </p>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div
                class="landing-section__pic mini effect-unit scale"
                data-delay="1"
              >
                <picture>
                  <source
                    type="image/webp"
                    :srcset="
                      `${require('@/assets/img/pic/safety-1.webp')} 1x,
                ${require('@/assets/img/pic/safety-1@2x.webp')} 2x`
                    "
                  />
                  <img
                    loading="lazy"
                    :src="require('@/assets/img/pic/safety-1.png')"
                    :srcset="
                      `${require('@/assets/img/pic/safety-1.png')} 1x,
              ${require('@/assets/img/pic/safety-1@2x.png')} 2x`
                    "
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="landing-section landing-section_color_light-green landing-section_theme_white effect-anchor"
    >
      <div class="landing-section__body social">
        <div class="container">
          <div class="row row-reverse align-items-center">
            <div class="col-12 col-md-3">
              <div class="social__pic_1 effect-unit scale" data-delay="3">
                <svg width="107" height="236" viewBox="0 0 107 236" fill="none">
                  <path
                    d="M106.767 83.7826c-.08-8.1197-3.12-15.9339-8.5539-21.9888-5.4341-6.0549-12.8923-9.9381-20.987-10.9273a30.1361 30.1361 0 01-4.8266 4.3714l-1.3085.8975a24.7383 24.7383 0 01-2.9076 1.6501 32.2684 32.2684 0 01-7.2108 2.7503c-.9886.2316-2.0063.4342-3.053.579a30.9893 30.9893 0 01-4.6813.3474 33.8708 33.8708 0 01-4.5649-.3185 27.1418 27.1418 0 01-2.9076-.579 30.1125 30.1125 0 01-7.4726-2.895c-.9304-.4921-1.8609-1.0132-2.7331-1.5922l-1.3375-.9264a32.6621 32.6621 0 01-4.8267-4.3425c-8.1128 1.015-15.5751 4.9421-20.987 11.0448C2.9968 67.9561.007 75.8151 0 83.9563v59.2607c.0077 2.209.8924 4.325 2.4611 5.887 1.5688 1.562 3.6943 2.443 5.9128 2.45 2.2186-.007 4.344-.888 5.9128-2.45 1.5688-1.562 2.4535-3.678 2.4611-5.887V92.2939c0-.3839.1532-.7521.4258-1.0236a1.4575 1.4575 0 011.028-.4239c.3856 0 .7554.1525 1.028.4239.2727.2715.4258.6397.4258 1.0236V220.021c0 4.246 1.6941 8.318 4.7095 11.32 3.0154 3.003 7.1052 4.689 11.3696 4.689s8.3542-1.686 11.3696-4.689c3.0154-3.002 4.7095-7.074 4.7095-11.32v-75.125c.0719-.325.2517-.615.5103-.826.2585-.21.5807-.327.9144-.332.3856 0 .7554.152 1.028.424.2727.271.4258.64.4258 1.023v74.836c0 4.246 1.6941 8.318 4.7095 11.32 3.0154 3.003 7.1052 4.689 11.3696 4.689s8.3542-1.686 11.3696-4.689c3.0154-3.002 4.7095-7.074 4.7095-11.32V92.2939c0-.3839.1531-.7521.4258-1.0236a1.4571 1.4571 0 011.028-.4239c.3856 0 .7553.1525 1.028.4239.2726.2715.4258.6397.4258 1.0236v50.9521c0 2.229.8874 4.368 2.4679 5.947 1.5805 1.579 3.7252 2.47 5.9641 2.477h.2908c2.2363-.015 4.3773-.908 5.9553-2.486 1.579-1.577 2.469-3.711 2.477-5.938V85.6354a7.8902 7.8902 0 00-.146-1.8528z"
                    fill="#fff"
                  />
                  <path
                    d="M39.6892 55.2389a29.528 29.528 0 0013.6221 3.3285 29.5281 29.5281 0 0013.6222-3.3285 28.4644 28.4644 0 006.513-4.6031c4.3059-4.0176 7.299-9.2314 8.5908-14.9644a29.1781 29.1781 0 00-1.3472-17.1841c-2.1695-5.464-5.9388-10.1523-10.8186-13.456C64.9917 1.7275 59.2269-.0391 53.3259-.0391c-5.9011 0-11.6658 1.7666-16.5456 5.0704-4.8798 3.3037-8.6492 7.992-10.8187 13.456a29.1781 29.1781 0 00-1.3471 17.1841c1.2917 5.733 4.2849 10.9468 8.5907 14.9644a28.3197 28.3197 0 006.484 4.6031z"
                    fill="#fff"
                  />
                </svg>
              </div>
            </div>
            <div class="col-12 col-md-7">
              <div class="landing-section__content social__content">
                <div
                  class="landing-section__pic mini social__pic_4 effect-unit scale"
                  data-delay="1"
                >
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        `${require('@/assets/img/pic/15.webp')} 1x,
                ${require('@/assets/img/pic/15@2x.webp')} 2x`
                      "
                    />
                    <img
                      loading="lazy"
                      :src="require('@/assets/img/pic/15.png')"
                      :srcset="
                        `${require('@/assets/img/pic/15.png')} 1x,
              ${require('@/assets/img/pic/15@2x.png')} 2x`
                      "
                      alt=""
                    />
                  </picture>
                </div>
                <h3 class="h3 effect-unit" data-delay="1">
                  Социальная дистанция
                </h3>
                <div class="social__pic_2 effect-unit scale" data-delay="3">
                  <svg width="643" height="52" viewBox="0 0 643 52" fill="none">
                    <path
                      d="M1.5251 23.5251c-1.3668 1.3669-1.3668 3.5829 0 4.9498L23.799 50.7487c1.3668 1.3669 3.5829 1.3669 4.9497 0 1.3669-1.3668 1.3669-3.5829 0-4.9497L8.9497 26l19.799-19.799c1.3669-1.3668 1.3669-3.5829 0-4.9497-1.3668-1.3669-3.5829-1.3669-4.9497 0L1.5251 23.525zm639.9499 4.9497c1.367-1.3668 1.367-3.5829 0-4.9497L619.201 1.2512c-1.367-1.3668-3.583-1.3668-4.95 0s-1.367 3.583 0 4.9498l19.799 19.7989-19.799 19.799c-1.367 1.3669-1.367 3.5829 0 4.9498 1.367 1.3668 3.583 1.3668 4.95 0l22.274-22.2739zM4 29.5h5.9531v-7H4v7zm13.8906 0h11.9063v-7H17.8906v7zm19.8438 0h11.9062v-7H37.7344v7zm19.8437 0h11.9063v-7H57.5781v7zm19.8438 0h11.9062v-7H77.4219v7zm19.8437 0h11.9064v-7H97.2656v7zm19.8434 0h11.907v-7h-11.907v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0h11.906v-7h-11.906v7zm19.843 0h11.907v-7h-11.907v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0h11.906v-7h-11.906v7zm19.843 0h11.907v-7h-11.907v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0h11.906v-7h-11.906v7zm19.843 0h11.907v-7h-11.907v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0h11.906v-7h-11.906v7zm19.843 0h11.907v-7h-11.907v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0h11.906v-7h-11.906v7zm19.843 0h11.907v-7h-11.907v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0l11.906-.0001v-7l-11.906.0001v7zm19.843-.0001h11.907v-7h-11.907v7zm19.844 0h11.906v-7h-11.906v7zm19.844 0H639v-7h-5.953v7z"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <p class="effect-unit" data-delay="2">
                  Мы&nbsp;разместили залы так, чтобы вам было проще соблюдать
                  социальную дистанцию. Мы&nbsp;верим, что наши покупатели
                  разделяют осознанный подход к&nbsp;рекомендациям. Вместе
                  мы&nbsp;справимся с&nbsp;любыми ограничениями!
                </p>
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div class="social__pic_3 effect-unit scale" data-delay="3">
                <svg width="107" height="236" viewBox="0 0 107 236" fill="none">
                  <path
                    d="M106.767 83.7826c-.08-8.1197-3.12-15.9339-8.5539-21.9888-5.4341-6.0549-12.8923-9.9381-20.987-10.9273a30.1361 30.1361 0 01-4.8266 4.3714l-1.3085.8975a24.7383 24.7383 0 01-2.9076 1.6501 32.2684 32.2684 0 01-7.2108 2.7503c-.9886.2316-2.0063.4342-3.053.579a30.9893 30.9893 0 01-4.6813.3474 33.8708 33.8708 0 01-4.5649-.3185 27.1418 27.1418 0 01-2.9076-.579 30.1125 30.1125 0 01-7.4726-2.895c-.9304-.4921-1.8609-1.0132-2.7331-1.5922l-1.3375-.9264a32.6621 32.6621 0 01-4.8267-4.3425c-8.1128 1.015-15.5751 4.9421-20.987 11.0448C2.9968 67.9561.007 75.8151 0 83.9563v59.2607c.0077 2.209.8924 4.325 2.4611 5.887 1.5688 1.562 3.6943 2.443 5.9128 2.45 2.2186-.007 4.344-.888 5.9128-2.45 1.5688-1.562 2.4535-3.678 2.4611-5.887V92.2939c0-.3839.1532-.7521.4258-1.0236a1.4575 1.4575 0 011.028-.4239c.3856 0 .7554.1525 1.028.4239.2727.2715.4258.6397.4258 1.0236V220.021c0 4.246 1.6941 8.318 4.7095 11.32 3.0154 3.003 7.1052 4.689 11.3696 4.689s8.3542-1.686 11.3696-4.689c3.0154-3.002 4.7095-7.074 4.7095-11.32v-75.125c.0719-.325.2517-.615.5103-.826.2585-.21.5807-.327.9144-.332.3856 0 .7554.152 1.028.424.2727.271.4258.64.4258 1.023v74.836c0 4.246 1.6941 8.318 4.7095 11.32 3.0154 3.003 7.1052 4.689 11.3696 4.689s8.3542-1.686 11.3696-4.689c3.0154-3.002 4.7095-7.074 4.7095-11.32V92.2939c0-.3839.1531-.7521.4258-1.0236a1.4571 1.4571 0 011.028-.4239c.3856 0 .7553.1525 1.028.4239.2726.2715.4258.6397.4258 1.0236v50.9521c0 2.229.8874 4.368 2.4679 5.947 1.5805 1.579 3.7252 2.47 5.9641 2.477h.2908c2.2363-.015 4.3773-.908 5.9553-2.486 1.579-1.577 2.469-3.711 2.477-5.938V85.6354a7.8902 7.8902 0 00-.146-1.8528z"
                    fill="#fff"
                  />
                  <path
                    d="M39.6892 55.2389a29.528 29.528 0 0013.6221 3.3285 29.5281 29.5281 0 0013.6222-3.3285 28.4644 28.4644 0 006.513-4.6031c4.3059-4.0176 7.299-9.2314 8.5908-14.9644a29.1781 29.1781 0 00-1.3472-17.1841c-2.1695-5.464-5.9388-10.1523-10.8186-13.456C64.9917 1.7275 59.2269-.0391 53.3259-.0391c-5.9011 0-11.6658 1.7666-16.5456 5.0704-4.8798 3.3037-8.6492 7.992-10.8187 13.456a29.1781 29.1781 0 00-1.3471 17.1841c1.2917 5.733 4.2849 10.9468 8.5907 14.9644a28.3197 28.3197 0 006.484 4.6031z"
                    fill="#fff"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="landing-section landing-section_decor_dark landing-section_color_grey effect-anchor"
    >
      <Pattern
        v-if="screenSize === 'xs'"
        fill="#E6E5E4"
        direction="horizontal"
        rows="3"
        columns="6"
        offsetX="1"
        offsetY="0"
        patternTransform="rotate(270)"
      />
      <Pattern
        v-else-if="screenSize === 'md'"
        fill="#E6E5E4"
        offsetX="0"
        offsetY="1"
        rows="6"
        columns="3"
        patternTransform="rotate(270)"
      />
      <Pattern
        v-else
        fill="#E6E5E4"
        offsetX="0"
        offsetY="1"
        patternTransform="rotate(270)"
        rows="4"
        columns="3"
      />
      <div class="landing-section__body">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-md-6">
              <div class="landing-section__content">
                <h3 class="h3 h3_color_green effect-unit" data-delay="1">
                  Утренние часы для&nbsp;пенсионеров
                </h3>
                <p class="effect-unit" data-delay="2">
                  Здоровье старшего поколения требует особого внимания. Поэтому
                  мы&nbsp;рекомендуем клиентам пожилого возраста посещать
                  магазины в&nbsp;утреннее время в&nbsp;будние дни, когда
                  покупателей в&nbsp;залах не&nbsp;так много. С&nbsp;момента
                  открытия магазинов и&nbsp;до&nbsp;13.00 для пенсионеров
                  действует постоянная скидка.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div
                class="landing-section__pic mini effect-unit scale"
                data-delay="1"
              >
                <picture>
                  <source
                    type="image/webp"
                    :srcset="
                      `${require('@/assets/img/pic/delivery-1.webp')} 1x,
                ${require('@/assets/img/pic/delivery-1@2x.webp')} 2x`
                    "
                  />
                  <img
                    loading="lazy"
                    :src="require('@/assets/img/pic/delivery-1.png')"
                    :srcset="
                      `${require('@/assets/img/pic/delivery-1.png')} 1x,
              ${require('@/assets/img/pic/delivery-1@2x.png')} 2x`
                    "
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="landing-section landing-section_color_green landing-section_theme_white effect-anchor"
    >
      <div class="landing-section__body">
        <div class="container">
          <div class="row row-reverse align-items-center">
            <div class="col-12 col-md-6">
              <div class="landing-section__content">
                <h3 class="h3 effect-unit" data-delay="1">
                  &laquo;Корзина доброты&raquo;
                </h3>
                <p class="effect-unit" data-delay="2">
                  Наши покупатели помогают людям и&nbsp;животным, которые
                  нуждаются в&nbsp;социальной защите. Вы&nbsp;можете оплатить
                  и&nbsp;передать через волонтеров товары первой необходимости
                  для тех, кто нуждается в&nbsp;них, а&nbsp;корма для
                  животных&nbsp;&mdash; в&nbsp;приюты. Помощь нужна особенно
                  сейчас, когда многие люди попали в&nbsp;сложную ситуацию из-за
                  распространения вируса.
                </p>
                <div class="effect-unit" data-delay="3">
                  <Button
                    tag="a"
                    class="button_color_orange button_site_safety_1"
                    link="https://korzina.5ka.ru"
                    msg="Сайт проекта"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div
                class="landing-section__pic mini effect-unit scale"
                data-delay="1"
              >
                <picture>
                  <source
                    type="image/webp"
                    :srcset="
                      `${require('@/assets/img/pic/safety-2.webp')} 1x,
                ${require('@/assets/img/pic/safety-2@2x.webp')} 2x`
                    "
                  />
                  <img
                    loading="lazy"
                    :src="require('@/assets/img/pic/safety-2.png')"
                    :srcset="
                      `${require('@/assets/img/pic/safety-2.png')} 1x,
              ${require('@/assets/img/pic/safety-2@2x.png')} 2x`
                    "
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="landing-section landing-section_decor_dark effect-anchor">
      <Pattern
        v-if="screenSize === 'xs'"
        fill="#E6E5E4"
        direction="horizontal"
        rows="3"
        columns="6"
        offsetX="0"
        offsetY="1"
        patternTransform="rotate(180)"
      />
      <Pattern
        v-else-if="screenSize === 'md'"
        fill="#E6E5E4"
        offsetX="0"
        offsetY="0"
        rows="6"
        columns="3"
        patternTransform="rotate(180)"
      />
      <Pattern
        v-else
        fill="#E6E5E4"
        offsetX="0"
        offsetY="0"
        patternTransform="rotate(180)"
        rows="4"
        columns="3"
      />
      <div class="landing-section__body">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-md-6">
              <div class="landing-section__content">
                <h3 class="h3 h3_color_green effect-unit" data-delay="1">
                  Помощь медработникам
                </h3>
                <p class="effect-unit" data-delay="2">
                  Те, кто ежедневно спасает тысячи жизней, тоже нуждаются
                  в&nbsp;заботе! Через фонд &laquo;Русь&raquo; мы&nbsp;передаём
                  продуктовые наборы врачам, которые лечат больных
                  коронавирусом. Чай, кофе, сахар и&nbsp;кондитерские изделия
                  помогают медицинским работникам восстанавливать силы
                  и&nbsp;день за&nbsp;днём возвращаться к&nbsp;важной работе!
                </p>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div
                class="landing-section__pic mini effect-unit scale"
                data-delay="1"
              >
                <picture>
                  <source
                    type="image/webp"
                    :srcset="
                      `${require('@/assets/img/pic/delivery-2.webp')} 1x,
                ${require('@/assets/img/pic/delivery-2@2x.webp')} 2x`
                    "
                  />
                  <img
                    loading="lazy"
                    :src="require('@/assets/img/pic/delivery-2.png')"
                    :srcset="
                      `${require('@/assets/img/pic/delivery-2.png')} 1x,
              ${require('@/assets/img/pic/delivery-2@2x.png')} 2x`
                    "
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="landing-section landing-section_color_orange landing-section_theme_white effect-anchor"
    >
      <div class="landing-section__body">
        <div class="container">
          <div class="row row-reverse align-items-center">
            <div class="col-12 col-md-6">
              <div class="landing-section__content">
                <h3 class="h3 effect-unit" data-delay="1">
                  Узнайте больше<br />на сайте проекта
                </h3>
                <div class="effect-unit" data-delay="2">
                  <Button
                    tag="a"
                    class="button_color_white button_site_safety_2"
                    link="https://lp.5ka.ru/zabota/"
                    msg="Сайт проекта"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div
                class="landing-section__pic mini effect-unit scale"
                data-delay="1"
              >
                <picture>
                  <source
                    type="image/webp"
                    :srcset="
                      `${require('@/assets/img/pic/safety-3.webp')} 1x,
                ${require('@/assets/img/pic/safety-3@2x.webp')} 2x`
                    "
                  />
                  <img
                    loading="lazy"
                    :src="require('@/assets/img/pic/safety-3.png')"
                    :srcset="
                      `${require('@/assets/img/pic/safety-3.png')} 1x,
              ${require('@/assets/img/pic/safety-3@2x.png')} 2x`
                    "
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import Button from "@/components/Button.vue";
import Pattern from "@/components/Pattern.vue";

export default {
  name: "Safety",
  components: {
    Button,
    Pattern
  },
  methods: {
    ...mapMutations(["setModalShow"])
  }
};
</script>

<style scoped lang="scss">
.social {
  // padding: 100px 0;
  @include lg {
    padding: 100px 0;
  }
  &__content {
    padding: 0;
    @include md {
      padding: 0 30px;
    }
    @include lg {
      padding: 0 100px;
    }
  }
  &__pic {
    &_1 {
      margin-top: -50px;
      display: none;
      @include md {
        display: block;
      }
      svg {
        width: 90px;
        @include lg {
          width: auto;
        }
      }
    }
    &_2 {
      margin: -15px -30px;
      text-align: center;
      display: none;
      svg {
        display: block;
      }
      @include lg {
        margin: 0px -100px;
      }
      @include md {
        display: block;
        svg {
          width: 100%;
        }
      }
    }
    &_3 {
      text-align: right;
      margin-top: -50px;
      display: none;
      @include md {
        display: block;
      }
      svg {
        width: 90px;
        @include lg {
          width: auto;
        }
      }
    }
    &_4 {
      margin-top: 10px;
      @include md {
        display: none;
      }
    }
  }
}
</style>
