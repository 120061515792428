import { createStore } from "vuex";
import { validateStatus } from "@/utils";

const API_HOST = process.env.VUE_APP_BASE_API;

export default createStore({
  state: {
    screenSize: null,

    sectionActiveIndex: null,
    modalShow: null,
    modalVideo: null,
    modalData: {},

    video: [],
    videoStatus: "loading",
    videoActivePosition: null,

    partner: [],
    partnerStatus: "loading",

    award: [],
    awardStatus: "loading"
  },
  actions: {
    async fetchVideo({ commit }) {
      try {
        const response = await fetch(`${API_HOST}/api/public/video/?sort=-pos`);
        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        const data = await response.json();
        const videosColumn = [];

        // разделяем видео по 2
        // [[video, video], [video, video]...]
        data.forEach((e, i) => {
          if (i % 2) {
            videosColumn[(i - 1) / 2][1] = e;
          } else {
            videosColumn.push([e]);
          }
        });

        commit("setVideo", videosColumn);
        commit("setVideoStatus", "success");
      } catch (error) {
        commit("setVideoStatus", "error");
      }
    },
    async fetchPartner({ commit }) {
      try {
        const response = await fetch(`${API_HOST}/api/public/parthner/`);
        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        commit("setPartner", await response.json());
        commit("setPartnerStatus", "success");
      } catch (error) {
        commit("setPartnerStatus", "error");
      }
    },
    async fetchAward({ commit }) {
      try {
        const response = await fetch(`${API_HOST}/api/public/award/?sort=-pos`);
        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        commit("setAward", await response.json());
        commit("setAwardStatus", "success");
      } catch (error) {
        commit("setAwardStatus", "error");
      }
    }
  },
  mutations: {
    setSectionActiveIndex(state, newIndex) {
      state.sectionActiveIndex = newIndex;
    },
    setModalShow(state, newState) {
      if (typeof newState === "object") {
        state.modalShow = newState.name;
        state.modalData = newState.data;
      } else {
        state.modalShow = newState;
        state.modalData = {};
      }
    },
    setModalVideo(state, videoId) {
      state.modalVideo = videoId;
    },
    setVideo(state, video) {
      state.video = video;
    },
    setVideoStatus(state, status) {
      state.videoStatus = status;
    },
    setVideoActivePosition(state, position) {
      state.videoActivePosition = position;
    },
    setPartner(state, partner) {
      state.partner = partner;
    },
    setPartnerStatus(state, status) {
      state.partnerStatus = status;
    },
    setAward(state, award) {
      state.award = award;
    },
    setAwardStatus(state, status) {
      state.awardStatus = status;
    },
    setScreenSize(state, size) {
      state.screenSize = size;
    }
  },
  modules: {}
});
