import { render } from "./Ecology.vue?vue&type=template&id=641c133a&scoped=true"
import script from "./Ecology.vue?vue&type=script&lang=js"
export * from "./Ecology.vue?vue&type=script&lang=js"

import "./Ecology.vue?vue&type=style&index=0&id=641c133a&lang=scss"
import "./Ecology.vue?vue&type=style&index=1&id=641c133a&scoped=true&lang=scss"
import "./Ecology.vue?vue&type=style&index=2&id=641c133a&lang=scss"
script.render = render
script.__scopeId = "data-v-641c133a"

export default script