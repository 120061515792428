<template>
  <div
    class="pattern"
    :class="[
      `pattern_type_${direction}`,
      { pattern_position_bottom: position === 'bottom' },
      { pattern_position_left: position === 'left' }
    ]"
  >
    <svg
      :width="calcColumns"
      :height="calcRows"
      :viewBox="calcViewBox"
      fill="none"
    >
      <defs>
        <pattern
          :x="offsetX"
          :y="offsetY"
          :fill="fill"
          :id="`pattern_id_${uuid}`"
          :height="calcHeigthPattern"
          :width="calcWidthPattern"
          :patternTransform="patternTransform"
        >
          <path
            d="M187.274 150.005l-37.267-37.284v37.16L.124 0h37.5326L150.007 112.349V75.1885l74.799 74.8165h-37.267l-37.532 37.532v37.391l-75.0657 75.064 75.0657 75.065v.124l74.799 74.816h-37.267l-37.532 37.533v37.408l-75.0657 75.064H37.6566L150.007 712.334l112.332-112.332h-37.143l74.8-74.816v-.124l75.065-75.065h37.409l37.532-37.532 37.533 37.532h.124l112.35 112.349v-37.16l74.8 74.816-74.8 74.799v-37.532l37.285-37.267h-.018l-37.267-37.284v37.16L450.126 449.997h-37.532L299.996 562.594v.124l-37.267 37.284h37.143L150.007 749.866.124 600.002h37.2846L150.007 487.406v-37.409h37.267l-37.267-37.284v-.124L37.4086 299.992 150.007 187.396v-37.391h37.267z"
          />
          <path
            d="M150.007 37.6563L262.339 150.005h-37.267l-75.065 75.047v37.408l-37.533 37.532 37.533 37.533v-37.409 37.533l112.332 112.348h-37.285l-75.047 75.065v37.408l-37.533 37.532H75.1894l74.8176 74.799 74.799-74.799h-37.143l112.332-112.348v-.124l37.533-37.533h37.409l75.065-75.064 75.065 75.064h.124l74.818 74.817v-37.16l112.332 112.348-112.332 112.332v-37.143l-74.818 74.799h37.285l37.533 37.532V749.99h-37.285l37.285-37.267v37.143l149.865-149.864-149.865-149.881v37.161l-37.285-37.285h-.124L450.002 337.401 337.404 449.997h-37.409v.124L150.131 600.002h37.143l-37.267 37.267-37.285-37.267h37.285v-37.408l112.58-112.597h37.285L150.007 300.116v-37.532l112.58-112.579h37.285L150.007.124v37.1603L112.722 0H75.1894l74.8176 74.8165V37.6563z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M450.002 75.1884v37.1956l-37.532 37.621 37.532 37.532 37.533-37.532h37.196l-74.729-74.8166zm0 74.8166v-37.196l37.285 37.196h-37.285z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M337.404 150.005l112.598 112.597L562.6 150.023h37.196L450.002.124v37.2842L337.404 150.005zm37.533 0l75.065 75.047v.017l75.066-75.064h37.284L450.002 37.7448v37.1956l-75.065 75.0646z"
          />
          <path
            d="M787.406 150.005h-.124l-37.284-37.284v37.16L600.133 0h37.532l112.333 112.349V75.1885l74.817 74.8165h.124l75.065 75.047 75.066-75.047h37.28L900.004 37.6563v-.124L862.472 0h37.532v.124l149.886 149.881h-37.29L900.004 262.584v.018L787.406 150.005z"
          />
          <path
            d="M1012.34 600.002h-37.146l74.816 74.799v-37.266l37.53-37.533-37.53-37.532v-37.408l-75.064-75.065 75.044-75.064h.02v-37.408l37.52-37.533h37.28l-74.8-74.799-74.816 74.799h37.156L900.004 412.341v.124l-37.532 37.532h-37.409l-75.065 75.065-75.048-75.065h-.142l-74.799-74.816v37.16l-112.35-112.349 112.35-112.331v37.143l74.8-74.799h-37.267l-37.533-37.532V75.0645L524.944 0h-37.533l112.598 112.597v37.408h37.267l-37.267 37.267v-37.143L450.126 299.992l149.883 149.881v-37.16l37.267 37.284h.141l112.581 112.597 112.598-112.597h37.408v-.124l149.866-149.881h-37.14l37.28-37.266 37.27 37.266h-37.27v37.409h-.02L937.413 449.997l112.597 112.597v74.675l-37.28-37.267h37.14L900.004 450.121 750.122 600.002h-.124v37.409L637.4 749.99h-37.267l149.865 149.881v-37.284L862.596 749.99h.124l37.284-37.284v37.284h.124l149.882 149.881V862.71l37.27 37.285-37.27 37.284v-37.284h-37.41L900.004 787.398v-37.284L750.122 899.995h37.284l112.598 112.595v.13l37.285 37.28h37.533l-74.818-74.817v-.124l-75.065-75.064h-37.285l112.35-112.349v37.284l75.066 75.065h37.41l37.53 37.532v37.284l74.8-74.816-74.8-74.817v37.161L937.661 749.99h-.124l-37.533-37.515v-37.284l-74.817 74.799h-.124l-75.065 75.064v37.285L637.665 749.99h37.267l75.066-75.065v-37.408l37.532-37.515h.124l112.35-112.348 112.336 112.348z"
          />
          <path
            d="M1050.01 675.049l75.05-75.047-75.05-75.064V487.53l-37.53-37.533 37.53-37.532v-37.408l75.05-75.065h37.28l-112.33-112.331-112.349 112.331h37.143l-74.8 74.817v.124l-75.065 75.064H787.53l-37.532 37.533-37.533-37.533h-.124L600.009 337.649v37.16l-74.818-74.817 74.818-74.799v37.533l-37.285 37.266 37.285 37.285v-37.161l149.865 149.881h37.532l112.598-112.596v-.124l37.285-37.285h-37.161l149.882-149.863 149.87 149.863h-37.29l-112.58 112.597v74.817l112.58 112.596h.02l-112.6 112.597v37.267L900.128 600.002h37.161l-37.284-37.284-37.285 37.284h-.124L749.998 712.581v37.409h-37.285l37.285 37.284V749.99h.124l149.883-149.864v37.285L1012.58 749.99h.15l37.28 37.284v-37.16l149.87 149.881-149.87 149.885v-37.29L937.413 899.995h-37.409v-37.284l-37.284 37.284h37.284v.124L1049.89 1050h-37.54L900.004 937.651v-.124l-37.532-37.532h-37.285l74.817-74.817v37.285l37.533 37.532h37.409l75.064 75.064v37.281l112.33-112.345-112.33-112.349v37.16l-74.816-74.816h-.124l-75.065-75.047v-37.284L787.654 749.99h-.124l-37.532 37.532v37.284l-74.8-74.816h37.267l37.533-37.533v-37.408l75.065-75.047h.124l74.817-74.816 74.818 74.816h-37.161l112.349 112.332v-37.285z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M412.594 749.99L299.996 637.393v.018L187.398 749.99h-37.267l149.864 149.881.001.124h-37.409l-112.58-112.597v-37.284L.124 899.995l149.883 149.885v-37.16l37.267 37.28h37.532l-74.799-74.817v37.157L37.6566 899.995 150.007 787.646v37.284l75.065 75.065h37.391l37.533 37.532v37.284l74.817-74.816h-37.285l-37.533-37.533v-.123L187.663 749.99H224.948l75.048-75.047 75.065 75.047h.124l74.817 74.816v-37.16l112.35 112.349h.124l37.533 37.532v37.408L675.074 1050h37.515l-112.58-112.597v-37.408h.124l149.865 149.885v-37.16l37.284 37.28h37.533l-74.817-74.817v37.157L637.665 899.995h-.123l-37.533-37.532v-37.409l-75.066-75.064h-37.284l112.35-112.349v-37.515L450.126 749.99h37.285l112.598 112.597v37.408h-.142L450.002 750.114v37.16l-37.285-37.284h-.123zM299.996 899.995h37.284l-37.284 37.284v-37.284z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M375.203 600.002l74.799-74.816v37.39l37.533 37.533-37.533 37.532v37.196l-74.799-74.835zm74.799 37.196v-74.48l-37.284 37.284 37.284 37.196z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M450.002 712.599L562.6 600.02l-.009-.009-112.589-112.588v-37.302L300.137 600.002 450.02 749.866l-.018-37.267zm0-187.661v-37.284l-112.35 112.348 112.35 112.332v-37.285l75.066-75.047-75.066-75.064z"
          />
          <path
            d="M112.35 150.005L0 262.336v37.532l149.865-149.863h-37.267L0 37.4082v37.5322l75.0653 75.0646H112.35z"
          />
          <path
            d="M0 150.005v-37.532l37.5327 37.532h37.2669L0 224.804v-37.532l37.2847-37.267H0zM299.996 37.5323v37.2842L374.813 0H337.28l-37.284 37.2843V0h-37.533l37.533 37.5323zM1200 487.282v37.532l-74.8-74.817 74.8-74.816v37.532l-37.28 37.284h.01l37.27 37.285z"
          />
          <path
            d="M1050.14 449.997L1200 599.878v-37.532l-112.33-112.349L1200 337.649v-37.533l-149.86 149.881z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M225.196 299.992l74.8-74.799 74.817 74.799-74.817 74.817v-37.284l-37.533-37.533h-37.267zm37.533 0h37.267v37.285l37.284-37.285-37.284-37.266-37.267 37.266z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M299.995 150.129L150.131 299.992h37.284l112.581 112.597v37.284l149.882-149.881-149.883-149.863zm112.35 149.863L299.996 412.341v-37.284l-75.048-75.065h-37.285l112.332-112.331v-.017l112.35 112.348z"
          />
          <path
            d="M600.009 37.4082l112.58 112.5968h37.285L600.009 299.868v-37.532l112.332-112.331h-37.267l-75.065-75.0646V37.5323L562.476 0h37.533v37.4082z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M749.998 262.46l-37.533 37.532 37.533 37.533v37.284l74.817-74.817-74.817-74.799v37.267zm0 .266l37.285 37.266-37.285 37.285v-74.551z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M749.998 150.129L899.88 299.992 749.998 449.873v-37.284l-112.58-112.597 112.58-112.579v-37.284zm-75.066 149.863h.018l75.048-75.046v-37.285l112.35 112.331-112.35 112.349v-37.284l-75.066-75.065z"
          />
          <path
            d="M749.998 37.6563l112.35 112.3487h.124l37.533 37.532 37.532-37.532h37.285l-74.818-74.8165v-.124L824.939 0h-37.533l112.598 112.597v.124l37.285 37.284H899.88L749.998.124v37.1603L712.731 0h-37.533l74.8 74.7989V37.6563zM299.996 149.881L449.878 0h-37.532l-112.35 112.349V75.0645L224.93 0h-37.515l112.581 112.597v37.284zM1050.01 37.5323v37.2842L1124.81 0h-37.53l-37.27 37.2843V0h-37.53l37.53 37.5323z"
          />
          <path
            d="M1199.88 0l-149.87 149.881v-37.284L937.413 0h37.533l75.064 75.0645v37.2845L1162.34 0h37.54zM1087.42 150.005L1200 262.584v-37.532l-75.07-75.047L1200 74.9404V37.4082L1087.42 150.005z"
          />
          <path
            d="M1200 187.537l-37.53-37.532 37.53-37.532v75.064zM0 974.935v-37.532L112.598 1050H75.0653L0 974.935zM37.5327 1050L0 1012.47V1050h37.5327zM150.007 937.279l-37.285-37.284 37.285-37.284v37.284h37.408l112.581 112.595v37.29l149.882-149.885h-37.284L299.996 787.398v-.124l-37.267-37.284h37.391l149.882 149.881V862.71l37.285 37.285h.124l112.598 112.595V1050h37.533l-37.533-37.53v-37.411l-75.065-75.064h-.142l-74.8-74.817v37.161L337.652 749.99h-.124l-37.532-37.533-37.533 37.533h-37.267l74.8 74.816v.124l75.065 75.065h37.285l-112.35 112.345v-37.281l-75.048-75.064h-37.409l-37.532-37.532v-37.285l-74.8176 74.817 74.8176 74.816v-37.16L262.339 1050h37.533L150.007 900.119v37.16zM749.998 937.279v-37.16L899.88 1050h-37.532l-112.35-112.349v37.16l-74.8-74.816h-.124l-75.065-75.065v-37.532l112.58 112.597h.142l37.267 37.284z"
          />
          <path
            d="M450.002 1050H412.47l37.532-37.53v-37.287l74.8 74.817h-37.515l-37.285-37.28V1050zM1200 974.935v-37.532L1087.4 1050h37.53l75.07-75.065zM1200 1012.47V1050h-37.53l37.53-37.53z"
          />
          <path
            d="M450.002 937.403L337.404 1050h37.533l75.065-75.065v-37.284L562.352 1050h37.515L450.002 900.119v37.284zM1200 787.522V749.99h-37.27l37.27-37.267v-37.532l-74.8 74.799h37.27l37.53 37.532z"
          />
          <path
            d="M1050.14 749.99h37.26L1200 862.587v-37.533l-75.07-75.064h-37.26L1200 637.659v-37.533L1050.14 749.99zM74.7996 449.997h.0177L0 524.814v-37.532l37.2847-37.285L0 412.713v-37.532l74.7996 74.816z"
          />
          <path
            d="M149.865 449.997L0 300.116v37.533l112.35 112.348L0 562.346v37.532l149.883-149.881h-.018zM0 637.393L112.598 749.99 0 862.587v-37.533l75.0653-75.064L0 674.925v-37.532z"
          />
          <path d="M0 787.522l37.5327-37.532L0 712.457v75.065z" />
        </pattern>
      </defs>
      <rect
        :fill="`url(#pattern_id_${uuid})`"
        :width="calcColumns"
        :height="calcRows"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    fill: {
      default: "#86B318"
    },
    rows: {
      default: 7
    },
    columns: {
      default: 8
    },
    direction: {
      default: "vertical"
    },
    position: {
      default: "top"
    },
    offsetX: {
      default: 0
    },
    offsetY: {
      default: 0
    },
    patternTransform: {
      default: ""
    }
  },
  data() {
    return {
      numberRows: 7,
      numberColumns: 8,
      sizeCell: 150,
      uuid: this.uuid
    };
  },
  computed: {
    calcRows() {
      return this.sizeCell * this.rows;
    },
    calcColumns() {
      return this.sizeCell * this.columns;
    },
    calcViewBox() {
      return `0 0 ${this.calcColumns} ${this.calcRows}`;
    },
    calcHeigthPattern() {
      return this.numberRows / this.rows;
    },
    calcWidthPattern() {
      return this.numberColumns / this.columns;
    }
  }
};
</script>

<style lang="scss" scoped>
.pattern {
  position: absolute;
  // opacity: 0.3;
  // mix-blend-mode: multiply;
  pointer-events: none;
  svg {
    display: block;
  }
  &_type {
    &_multiply {
      opacity: 0.3;
      mix-blend-mode: multiply;
    }
    &_vertical {
      top: 0;
      right: 0;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      svg {
        height: 100%;
        width: auto;
      }
    }
    &_horizontal {
      top: 0;
      left: 0;
      width: 100%;
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
  &_position {
    &_bottom {
      top: auto;
      bottom: 0;
    }
    &_left {
      justify-content: flex-start;
    }
  }
  &_place {
    &_picture-xs {
      top: 120px;
    }
  }
}
</style>
