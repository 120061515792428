<template>
  <div class="sidebar">
    <div class="sidebar__inner">
      <div class="sidebar__logo">
        <a
          class="logo-1 button_logo_5ka"
          href="https://5ka.ru"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="@/assets/img/logo.svg"
            width="60"
            height="60"
            alt="Пятёрочка"
          />
        </a>
        <a
          class="logo-2 button_logo_eco_5ka"
          href="https://eco.5ka.ru"
          target="_blank"
          rel="noopener noreferrer"
        >
          <picture>
            <source
              media="(max-width: 1023px)"
              :srcset="`${require('@/assets/img/logo2_m.svg')}`"
            />
            <source
              media="(min-width: 1024px)"
              :srcset="`${require('@/assets/img/logo2.svg')}`"
            />
            <img
              :src="`${require('@/assets/img/logo2_m.svg')}`"
              width="63"
              height="58"
              alt="Маленький шаг, большая польза"
            />
          </picture>
        </a>
      </div>
      <transition name="side">
        <div v-show="show" class="sidebar__nav">
          <Nav @close="onClose" />
        </div>
      </transition>
      <div class="sidebar__foot">
        <button
          type="button"
          @click="show = !show"
          class="sidebar__button"
          :class="{ sidebar__button_state_active: show }"
          aria-label="Меню"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import { mapMutations } from "vuex";

export default {
  name: "Sidebar",
  components: {
    Nav
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    ...mapMutations(["setModalShow"]),
    onClose() {
      this.show = false;
    }
  }
};
</script>

<style scoped lang="scss">
// мобилка
@media (max-width: 1024px - 1) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 54px;
    background: #9aca3c;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.1);
    z-index: 100;
    // &__inner {
    // }
    &__logo {
      display: flex;
      align-items: center;
      @include gap(9px);
      margin-left: 15px;
      height: 54px;
      .logo-1 {
        img {
          width: 40px;
          height: 40px;
        }
      }
      .logo-2 {
        img {
          width: 41px;
          height: 38px;
        }
      }
      img {
        display: block;
      }
    }
    &__nav {
      position: absolute;
      top: 54px;
      left: 0;
      height: calc(100vh - 54px);
      width: 100%;
      background-color: #fff;
      padding: 15px;
      box-sizing: border-box;
      overflow: auto;
    }
    &__foot {
      position: absolute;
      top: 0px;
      right: 0px;
    }
    &__button {
      @include reset-button;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 54px;
      width: 54px;
      span {
        width: 24px;
        height: 3px;
        background: #ffffff;
        border-radius: 10px;
        display: block;
        margin: 2px 0;
      }
      &_state_active {
        span {
          &:nth-child(1) {
            width: 14px;
            position: absolute;
            transform: translateX(-50%) rotate(45deg);
            left: 50%;
          }
          &:nth-child(2) {
            width: 14px;
            position: absolute;
            transform: translateX(-50%) rotate(-45deg);
            left: 50%;
          }
          &:nth-child(3) {
            position: absolute;
            width: 24px;
            height: 24px;
            background: none;
            border: 2px solid #fff;
            border-radius: 50%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
// десктоп
@media (min-width: 1024px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    // width: 272px;
    height: 100%;
    background-color: #fff;
    z-index: 100;
    box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.08);
    &__inner {
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      // height: 100%;

      display: grid;
      height: 100%;
      // grid-auto-columns: 1fr auto 1fr;
      grid-auto-rows: 1fr auto 1.5fr;
      gap: 20px;
      @include sidebarMini {
        @media (max-height: 750px) {
          gap: 10px;
        }
      }
    }
    &__logo {
      margin-top: 40px;
      margin-left: 32px;
      a {
        display: inline-block;
        margin-right: 20px;
      }
      @include sidebarMini {
        margin-left: 18px;
        display: flex;
        flex-direction: column;
        .logo-1 {
          margin-bottom: 5px;
        }
        a {
          margin-right: 0;
        }
        @media (max-height: 750px) {
          margin-left: 18px;
          margin-top: 3vh;
          a {
            img {
              height: 50px;
            }
            margin-bottom: 5px;
          }
        }
      }
      img {
        display: block;
      }
    }
    &__nav {
      margin-left: 20px;
      display: block !important;
      @media (max-height: 650px) {
        margin-left: 23px;
      }
    }
    // &__foot {
    // height: 100px;
    // }
    &__button {
      display: none;
    }
  }
}
</style>
<style lang="scss">
// мобилка
@media (max-width: 1024px - 1) {
}

// десктоп
@media (min-width: 1024px) {
  .sidebar {
    &:hover {
      .nav {
        width: 232px;
        &__text {
          visibility: visible;
        }
      }
    }
    &__nav {
      .nav__list {
        @include sidebarMini {
          @media (max-height: 750px) {
            .nav__item + .nav__item {
              margin-top: 3px;
            }
          }
        }
      }
    }
  }
}

.side-enter-active,
.side-leave-active {
  transition: opacity 0.5s ease;
  .nav__item {
    transition: 0.5s;
  }
}
.side-enter-active {
  .nav__item {
    @for $i from 0 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: #{$i}00ms;
      }
    }
  }
}

.side-enter-from,
.side-leave-to {
  opacity: 0;
  .nav__item {
    transform: translateX(-20px);
    opacity: 0;
  }
}
</style>
