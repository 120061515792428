<template>
  <Landing />
  <Sidebar />
</template>

<script>
import Landing from "@/components/Landing.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: {
    Landing,
    Sidebar
  }
};
</script>

<style lang="scss" scoped></style>
