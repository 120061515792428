<template>
  <a
    v-if="tag === 'a'"
    target="_blank"
    rel="noopener noreferrer"
    :href="link"
    class="button"
  >
    <span class="button__text">{{ msg }}</span>
    <span class="button__icon"></span>
  </a>
  <router-link v-else-if="tag === 'router-link'" :to="link" class="button">
    <span class="button__text">{{ msg }}</span>
    <span class="button__icon"></span>
  </router-link>
  <button type="button" v-else class="button">
    <span class="button__text">{{ msg }}</span>
    <span class="button__icon"></span>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    tag: String,
    link: [String, Object],
    msg: String
  }
};
</script>

<style scoped lang="scss">
$button-color-green: #00923a;
$button-color-white: #ffffff;
$button-color-orange: #ffad26;

.button {
  @include reset-button;
  box-sizing: border-box;
  text-decoration: none;
  // display: flex;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  @include gap(11px);
  background-color: $button-color-green;
  border-radius: 40px;
  min-height: 55px;
  font-family: inherit;
  color: #fff;
  padding: 10px 30px;
  transition: 0.2s;
  &:hover {
    background-color: lighten($button-color-green, 5%);
  }
  &:active {
    background-color: darken($button-color-green, 2%);
  }
  width: 100%;
  @include md {
    width: auto;
    min-width: 210px;
  }
  @include xl {
    min-width: 247px;
  }
  &_size {
    &_small {
      min-height: 44px;
      .button {
        &__text {
          font-size: 16px;
        }
        &__icon {
          border-width: 5.5px 0 5.5px 8px;
        }
      }
    }
  }
  &_color {
    &_white {
      background-color: $button-color-white;
      color: #333333;
      .button {
        &__icon {
          border-color: transparent transparent transparent #333333;
        }
      }
      &:hover {
        background-color: darken($button-color-white, 5%);
      }
      &:active {
        background-color: darken($button-color-white, 10%);
      }
    }
    &_orange {
      background-color: $button-color-orange;
      &:hover {
        background-color: lighten($button-color-orange, 5%);
      }
      &:active {
        background-color: darken($button-color-orange, 5%);
      }
    }
  }
  &_type_modal {
    padding: 10px 20px;
    @include lg {
      padding: 10px 30px;
    }
    .button {
      &__text {
        font-size: 14px;
        @include lg {
          font-size: 16px;
        }
      }
    }
  }
  &__text {
    font-size: 16px;
    letter-spacing: 0.05em;
    position: relative;
    top: 1px;
    @include xl {
      font-size: 18px;
    }
    text-transform: uppercase;
  }
  &__icon {
    position: relative;
    top: 1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 8px;
    @include xl {
      border-width: 7px 0 7px 10px;
    }
    border-color: transparent transparent transparent #fff;
  }
}
</style>
