<template>
  <div class="page">
    <router-view />
  </div>
  <Footer />
  <ModalGroup />
</template>

<script>
import { mapActions } from "vuex";

import Footer from "./components/Footer.vue";
import ModalGroup from "./components/ModalGroup.vue";

import "swiper/swiper.scss";

export default {
  name: "App",
  components: {
    Footer,
    ModalGroup
  },
  methods: {
    ...mapActions(["fetchVideo", "fetchPartner", "fetchAward"])
  },
  created() {
    this.fetchVideo();
    this.fetchPartner();
    this.fetchAward();

    window.addEventListener("load", () => {
      window.dispatchEvent(new Event("resize"));
    });
  }
};
</script>

<style lang="scss">
body {
  margin: 0;
}

// фикс для аналитики
button *,
a * {
  pointer-events: none;
}

// полифил для псевдо-селекта :focus-visible
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

// swiper
.swiper {
  &-nav {
    @include reset-button;
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 100;
    cursor: pointer;
    opacity: 0.8;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='27' height='41' viewBox='0 0 27 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.0002 20.5L20.8254 41l5.2063-5.125L10.4128 20.5 26.0317 5.125 20.8254 0 .0002 20.5z' fill='%23242424'/%3E%3C/svg%3E");
      content: "";
      transition: 0.15s;
    }
    @media (max-width: 899px) {
      bottom: 0;
      &:before {
        background-size: 24px;
      }
    }
    @media (min-width: 900px) {
      top: 50%;
      margin-top: -25px;
    }
    &_theme {
      &_white {
        &:before {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='27' height='41' viewBox='0 0 27 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.0002 20.5L20.8254 41l5.2063-5.125L10.4128 20.5 26.0317 5.125 20.8254 0 .0002 20.5z' fill='%23fff'/%3E%3C/svg%3E");
        }
      }
    }
    &:hover {
      opacity: 1;
    }
    &-prev {
      @media (max-width: 899px) {
        right: 50px;
      }
      @media (min-width: 900px) {
        left: -70px;
      }
    }
    &-next {
      &::before {
        transform: rotate(180deg);
      }
      @media (max-width: 899px) {
        right: -10px;
      }
      @media (min-width: 900px) {
        right: -70px;
      }
    }
    &.swiper-button-disabled {
      &::before {
        opacity: 0.3 !important;
      }
      cursor: default;
      // display: none;
      // & + .swiper-container {
      //   padding-bottom: 0;
      //   & .swiper-pagination {
      //     display: none;
      //   }
      // }
    }
  }
  &-container {
    padding-bottom: 64px;
    width: calc(100% + 30px);
    margin-top: -15px;
    padding-top: 15px;
    margin-left: -15px;
    &_theme {
      &_white {
        .swiper-pagination-bullet {
          border-color: #fff;
          &:hover {
            background-color: #fff;
          }
          &-active {
            background-color: #fff;
          }
        }
      }
    }
  }
  &-pagination {
    position: absolute;
    bottom: 0;
    width: 100%;
    @media (max-width: 899px) {
      left: 5px;
      bottom: 8px;
    }
    @media (min-width: 900px) {
      text-align: center;
    }
    &-bullet {
      display: inline-block;
      border-radius: 50%;
      margin: 0 8px;
      width: 16px;
      height: 16px;
      opacity: 0.5;
      border: 2px solid #333333;
      background: none;
      transition: 0.15s;
      cursor: pointer;
      @media (max-width: 899px) {
        margin: 0 6px;
        width: 12px;
        height: 12px;
      }
      &:hover {
        background-color: #333333;
      }
      &-active {
        opacity: 1;
        background-color: #333333;
        cursor: default;
      }
    }
  }
}

#app {
  font-family: "5ka Sans Design", sans-serif;
  color: #333;
  font-size: 18px;
  line-height: 160%;
}

.h2 {
  font-weight: 900;
  line-height: 1.2;
  margin: 18px 0;
  font-size: 30px;
  @include md {
    margin: 24px 0;
    font-size: 48px;
  }
  @include lg {
    font-size: 64px;
  }
  @include xl {
    font-size: 64px;
  }
}

.h3 {
  font-weight: 900;
  font-size: 30px;
  line-height: 1.2;
  margin: 24px 0;
  word-break: break-word;
  @media (min-width: 420px) {
    word-break: initial;
  }
  @include md {
    font-size: 36px;
  }
  @include lg {
    font-size: 52px;
  }
  @include xl {
    font-size: 52px;
  }
  &_color_green,
  a {
    color: #00923a;
  }
}

.h4 {
  font-weight: 900;
  line-height: 146%;
  // word-break: break-word;
  &_color_green,
  strong {
    color: #00923a;
  }
  a {
    color: inherit;
  }
  font-size: 18px;
  @include md {
    // font-size: 20px;
  }
  @include md-modal {
    font-size: 20px;
  }
  @include lg {
    font-size: 24px;
  }
}

a:hover {
  text-decoration: none;
}

p {
  margin: 24px 0;
}

.pos-relative {
  position: relative;
}
.color-inherit {
  color: inherit !important;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}
.mt-8 {
  margin-top: 8px;
}
.mt-36 {
  margin-top: 36px;
}
.mt-52 {
  margin-top: 52px;
}
.mb-0 {
  margin-bottom: 0;
}
</style>
<style lang="scss" src="@/styles/bootstrap-grid.scss"></style>
<style lang="scss" src="@/styles/effects.scss"></style>
